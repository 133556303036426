import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { PRPVSaleEventFull, SaleEventDetail, UserNextSaleEventBookingItem } from 'src/core-modules/sdk/api';
import { PrivateInviteFriendsModalComponent } from '../../modals/private-invite-friends-modal/private-invite-friends-modal.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { InviteFriendsModalComponent } from '../../modals/invite-friends-modal/invite-friends-modal.component';
import { PurpleTranslationPipe } from 'purple-lib';
import { pageTitle } from 'src/app/components/purple-sales-page-title/purple-sales-page-title.component';
import { PrivateInviteFriendsDrawerComponent } from '../../drawers/private-invite-friends-drawer/private-invite-friends-drawer.component';
import { InviteFriendsDrawerComponent } from '../../drawers/invite-friends-drawer/invite-friends-drawer.component';

@Component({
  selector: 'app-advanced-sale-event-detail-banner',
  templateUrl: './advanced-sale-event-detail-banner.component.html',
  styleUrls: ['./advanced-sale-event-detail-banner.component.scss']
})
export class AdvancedSaleEventDetailBannerComponent implements OnInit {

  constructor(public datePipe: DatePipe, private tranPipe: PurpleTranslationPipe, private modalSvc: NzModalService, private drawerSvc: NzDrawerService) { }
  @Input() saleEventDetail!: SaleEventDetail;

  //saleEventDetailMobileText: string = "";
  saleEventDetailText: string = "";
  showMoreButtonText: string = "";
  showMoreExpanded: boolean = false;
  showMoreTxt: string = "";
  hideMoreTxt: string = "";
  showMoreEnable: boolean = true;
  saleEventTitle: pageTitle = {
    allRounded: 'allround',
    title: "",
    titleTranslationKey: '',
    subtitle: undefined,
    textAlign: "center"
  } 

  ngOnInit() {
    this.saleEventTitle.title = this.saleEventDetail.saleEvent.saleEventName;
    this.showMoreEnable = this.saleEventDetail.saleEvent.saleEventDetailDescription!.length > 30;
    //this.saleEventDetailMobileText = this.saleEventDetail.saleEvent.saleEventDetailDescription!.split(' ').slice(0, 30).join(" ").replace("\n", "<br/>");
    this.saleEventDetailText = this.saleEventDetail.saleEvent.saleEventDetailDescription!.replace("\n", "<br/>");
    this.showMoreTxt = this.tranPipe.transform('show_more', 'Mostra di più', []);
    this.showMoreButtonText = this.showMoreTxt;
    this.hideMoreTxt = this.tranPipe.transform('hide_more', 'Mostra meno', []);
  }

  /* showMore() {
    if (!this.showMoreExpanded) {
      this.showMoreExpanded = true;
      this.showMoreButtonText = this.hideMoreTxt;
      this.saleEventDetailMobileText = this.saleEventDetail.saleEvent.saleEventDetailDescription!.replace("\n", "<br/>");
    } else {
      this.showMoreExpanded = false;
      this.showMoreButtonText = this.showMoreTxt;
      this.saleEventDetailMobileText = this.saleEventDetail.saleEvent.saleEventDetailDescription!.split(' ').slice(0, 30).join(" ").replace("\n", "<br/>");
    }
  } */

  checkCanInvite(): boolean {
    if (this.saleEventDetail!.saleEvent.isPrivateInviteCounter) {
      return this.saleEventDetail!.canInvite;
    } else {
      //return ((this.saleEventDetail!.userSlotBooked ?? undefined) != undefined) && this.saleEventDetail!.canInvite;
      return this.saleEventDetail!.canInvite;
    }
  }

  showInviteFriendsModal() {
    if (this.saleEventDetail.saleEvent.isPrivateInviteCounter) {
      this.modalSvc.create<PrivateInviteFriendsModalComponent, { saleEventId: string, saleEventName: string, saleEventPhone: string, saleEventEmail: string }>({
        nzContent: PrivateInviteFriendsModalComponent,
        nzTitle: undefined,
        nzWidth: '1000px',
        nzCloseIcon: undefined,
        nzClosable: false,
        nzCentered: true,
        nzClassName: "private-invite-friends-modal",
        nzData: {
          saleEventId: this.saleEventDetail.saleEvent.saleEventId,
          saleEventEmail: this.saleEventDetail.saleEvent.saleEventEmail,
          saleEventPhone: this.saleEventDetail.saleEvent.saleEventPhone,
          saleEventName: this.saleEventDetail.saleEvent.saleEventName
        },
        nzFooter: null,
        nzMaskClosable: false,
      });
    } else {
      //if (this.saleEventDetail.userSlotBooked != undefined) {
        this.modalSvc.create<InviteFriendsModalComponent, { book?: UserNextSaleEventBookingItem | undefined, saleEvent: PRPVSaleEventFull }>({
          nzContent: InviteFriendsModalComponent,
          nzTitle: undefined,
          nzWidth: '1000px',
          nzCloseIcon: undefined,
          nzClosable: false,
          nzCentered: true,
          nzClassName: "invite-friends-modal",
          nzData: {
            book: this.saleEventDetail.userSlotBooked != undefined ? {
              address: this.saleEventDetail.saleEvent.addressFull!,
              addressExternalId: this.saleEventDetail.saleEvent.addressExternalId!,
              addressLat: this.saleEventDetail.saleEvent.addressLat!,
              addressLon: this.saleEventDetail.saleEvent.addressLon!,
              isPrivateInviteCounter: this.saleEventDetail.saleEvent.isPrivateInviteCounter,
              saleEventCode: this.saleEventDetail.saleEvent.saleEventCode,
              saleEventEmail: this.saleEventDetail.saleEvent.saleEventEmail,
              saleEventId: this.saleEventDetail.saleEvent.saleEventId,
              saleEventName: this.saleEventDetail.saleEvent.saleEventName,
              saleEventPhone: this.saleEventDetail.saleEvent.saleEventPhone,
              userCanInvite: this.saleEventDetail.canInvite,
              userCanDelete: true, //TODO
              slot: {
                bookingId: this.saleEventDetail.userSlotBooked!.saleEventSlotBookingId,
                requireAssistance: false,
                roleId: this.saleEventDetail.userSlotBooked!.roleId,
                roleName: this.saleEventDetail.userSlotBooked!.roleName,
                slotEndDate: this.saleEventDetail.userSlotBooked!.slotEndDate,
                slotStartDate: this.saleEventDetail.userSlotBooked!.slotStartDate,
                slotGmtInfo: this.saleEventDetail.userSlotBooked!.slotGmtInfo,
              }
            }: undefined,
            saleEvent: this.saleEventDetail.saleEvent
          },
          nzFooter: null,
          nzMaskClosable: false,
        });
      //}

    }
  }

  showInviteFriendsDrawer() {
    if (this.saleEventDetail!.saleEvent.isPrivateInviteCounter) {
      this.drawerSvc.create<PrivateInviteFriendsDrawerComponent, { value: string }, string>({
        nzTitle: undefined,
        nzContent: PrivateInviteFriendsDrawerComponent,
        nzClosable: false,
        nzWrapClassName: "private-invite-friends",
        nzFooter: undefined,
        nzPlacement: 'bottom',
        nzContentParams: {
          saleEventId: this.saleEventDetail?.saleEvent.saleEventId
        }
      });
    } else {
      //if ((this.saleEventDetail!.userSlotBooked ?? undefined) != undefined) {
        this.drawerSvc.create<InviteFriendsDrawerComponent, { value: string }, string>({
          nzTitle: undefined,
          nzContent: InviteFriendsDrawerComponent,
          nzClosable: false,
          nzWrapClassName: "invite-friends",
          nzFooter: undefined,
          nzPlacement: 'bottom',
          nzContentParams: {
            book: this.saleEventDetail.userSlotBooked != undefined ? {
              address: this.saleEventDetail!.saleEvent.addressFull!,
              addressExternalId: this.saleEventDetail!.saleEvent.addressExternalId!,
              addressLat: this.saleEventDetail!.saleEvent.addressLat!,
              addressLon: this.saleEventDetail!.saleEvent.addressLon!,
              isPrivateInviteCounter: this.saleEventDetail!.saleEvent.isPrivateInviteCounter,
              saleEventCode: this.saleEventDetail!.saleEvent.saleEventCode,
              saleEventEmail: this.saleEventDetail!.saleEvent.saleEventEmail,
              saleEventId: this.saleEventDetail!.saleEvent.saleEventId,
              saleEventName: this.saleEventDetail!.saleEvent.saleEventName,
              saleEventPhone: this.saleEventDetail!.saleEvent.saleEventPhone,
              userCanInvite: this.saleEventDetail!.canInvite,
              userCanDelete: true, //TODO
              slot: {
                bookingId: this.saleEventDetail!.userSlotBooked!.saleEventSlotBookingId,
                requireAssistance: false,
                roleId: this.saleEventDetail!.userSlotBooked!.roleId,
                roleName: this.saleEventDetail!.userSlotBooked!.roleName,
                slotEndDate: this.saleEventDetail!.userSlotBooked!.slotEndDate,
                slotStartDate: this.saleEventDetail!.userSlotBooked!.slotStartDate,
                slotGmtInfo: this.saleEventDetail!.userSlotBooked!.slotGmtInfo,
              }
            }: undefined
          }
        });
      //}
    }
  }
}
