import { Component, OnInit } from '@angular/core';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';

@Component({
  selector: 'app-change-language-drawer',
  templateUrl: './change-language-drawer.component.html',
  styleUrls: ['./change-language-drawer.component.scss']
})
export class ChangeLanguageDrawerComponent implements OnInit {

  constructor(public tsvc: AppTranslationService) { }

  ngOnInit() {
  }

}
