import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountNeedVerificationBannerComponent } from './account-need-verification-banner/account-need-verification-banner.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PurplePipeModule, PurpleGeneralDirectiveModule, PurpleEditTextModule } from 'purple-lib';
import { PurpleSalesComponentsModule } from 'src/app/components/purple-sales-components.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PurplePipeModule,
    PurpleGeneralDirectiveModule,
    PurpleEditTextModule,
    PurpleSalesComponentsModule
  ],
  declarations: [AccountNeedVerificationBannerComponent],
  exports: [AccountNeedVerificationBannerComponent]
})
export class PurpleSalesGeneralComponentsModule { }
