import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Error404Component } from './404/404.component';
import { RouterModule } from '@angular/router';
import { PurplePipeModule, PurpleSafeHtmlPipe, PurpleTranslationPipe } from 'purple-lib';

@NgModule({
  imports: [
    CommonModule,
    PurplePipeModule,
    RouterModule
  ],
  providers:[PurpleTranslationPipe, PurpleSafeHtmlPipe],
  declarations: [Error404Component],
  exports: [Error404Component]
})
export class ErrorModule { }
