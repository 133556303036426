import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder, AbstractControl, ValidationErrors } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Guid, PurpleEditService, PurpleTranslationPipe, purpleFormFieldsGroup } from 'purple-lib';
import { Subscription } from 'rxjs';
import { SaleEventService } from 'src/app/sale-event-service.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { PRPVSaleEventFull } from 'src/core-modules/sdk/api';
import { AuthenticationService } from 'src/core-modules/authentication/authentication.service';
import { pageTitle } from 'src/app/components/purple-sales-page-title/purple-sales-page-title.component';
import { PurpleSalesNotificationService } from 'src/app/components/purple-sales-notification.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['../authentication.scss','./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  validateForm: FormGroup = this.fb.group({});
  purpleFormFieldGroups: purpleFormFieldsGroup[] | undefined;
  seId: string = "";
  isLoading = false
  resetId: string | undefined;
  subs: Subscription = new Subscription();

  pageTitle: pageTitle = {
    allRounded: 'allround',
    title: this.tranPipe.transform("reset_password_title", "Reset Password", []),
    titleTranslationKey: 'reset_password_title',
    subtitle:
    {
      subtitle: this.tranPipe.transform("reset_password_subtitle", "Reimposta Password"),
      subtitleTranslationKey: 'reset_password_subtitle'
    },
    textAlign: 'left'
  }

  constructor(private fb: FormBuilder, private authSvc: AuthenticationService, private router: Router, private psNotSvc: PurpleSalesNotificationService,
    private tsvc: AppTranslationService, private route: ActivatedRoute, public seSvc: SaleEventService, private modal: NzModalService, private tranPipe: PurpleTranslationPipe) { }
  
  async ngOnInit(): Promise<void> {
    this.subs.add(this.seSvc.currentSaleEvent.subscribe((saleEvent: PRPVSaleEventFull | undefined) => {
      this.seId = saleEvent?.saleEventId ?? Guid.empty();
    }));

    this.route.queryParams.subscribe(async (params: Params) => {
      this.seSvc.getSaleEventParameters(params);
      this.resetId = params['resetId'];

      this.purpleFormFieldGroups = [
        {
          fieldGroupNumber: 1,
          fielGroupBootstrapColumn: 12,
          fieldGroupPaddingLeft: false,
          fieldGroupPaddingRight: false,
          formFieldFormGroup: this.validateForm,
          formFields: [
            {
              fieldType: 'input-text',
              fieldControlName: 'email',
              fieldBootstrapColumn: 12,
              fieldName: this.tranPipe.transform('edit_user_email', 'Email', []),
              fieldPlaceholder: this.tranPipe.transform('edit_user_email_placeholder', 'Indirizzo Email', []),
              fieldValue: params['email'],
              fieldIsRequired: true,
              fieldIsDisabled: true,
              fieldCustomValidators: [Validators.email],
              fieldPaddingLeft: false,
              fieldPaddingRight: false
            },
            {
              fieldType: 'input-text',
              fieldControlName: 'password',
              fieldBootstrapColumn: 12,
              fieldName: this.tranPipe.transform('register_page_form_password', 'Password', []),
              fieldPlaceholder: this.tranPipe.transform('register_page_form_password_placeholder', 'Password', []),
              fieldValue: undefined,
              fieldIsRequired: true,
              fieldPaddingLeft: false,
              fieldPaddingRight: false,
              fieldOptions: [{ id: 'isPassword', value: true }],
              fieldCustomValidators: [this.validatePassword],
              fieldCustomValidatorErrors: [{ errorId: "format", text: this.tranPipe.transform("register_invalid_password_validator_error_text", "La password deve contenere almeno 8 caratteri, una lettera maiuscola e un carattere speciale (,;.!@#$%^&*)") }]
            },
            {
              fieldType: 'input-text',
              fieldControlName: 'checkPassword',
              fieldBootstrapColumn: 12,
              fieldName: this.tranPipe.transform('register_page_form_check_password', 'Conferma password', []),
              fieldPlaceholder: this.tranPipe.transform('register_page_form_check_password_placeholder', 'Conferma password', []),
              fieldValue: undefined,
              fieldIsRequired: true,
              fieldPaddingLeft: false,
              fieldPaddingRight: false,
              fieldCustomValidators: [this.confirmationValidator],
              fieldCustomValidatorErrors: [{ errorId: "match", text: this.tranPipe.transform("invalid_email_match_validator_error_text", "Le password inserite non corrispondono") }],
              fieldOptions: [{ id: 'isPassword', value: true }]
            }
          ]
        }
      ]

      var checkLinkResp: { status: boolean, message: string } = {
        message: "",
        status: false
      }

      if (this.resetId != undefined) {
        checkLinkResp = await this.authSvc.checkResetPasswordLinkUrl(this.resetId);
      }

      if (checkLinkResp == undefined || !checkLinkResp.status) {
        this.psNotSvc.showMotSimpleNotification(
          this.tranPipe.transform("reset_password_check_reset_id_title_ko", "Ops! qualcosa è andato storto"),
          checkLinkResp?.message??this.tranPipe.transform("reset_password_check_reset_id_subtitle_ko", "Link di reset password non valido"),
          this.tranPipe.transform("reset_password_check_reset_id_modal_button", "Conferma"),
          async () => {
            await this.router.navigate([this.tsvc.currentLanguage.value, 'forgot-password']);
          },
          'modal',
          true,
          undefined,
          undefined,
          false,
          false,
          false,
          false
        )
      }
    });

  }

  async resetPassword(): Promise<void> {
    for (const i in this.validateForm.controls) {
      if (this.validateForm.controls.hasOwnProperty(i)) {
        this.validateForm.controls[i].markAsDirty();
        this.validateForm.controls[i].updateValueAndValidity();
      }
    }

    if (this.validateForm.valid) {
      const res = await this.authSvc.resetPassword(this.validateForm.controls["email"].value, this.resetId!, this.validateForm.controls['password'].value);

      this.psNotSvc.showMotSimpleNotification(
        res.status ? this.tranPipe.transform('reset_password_ok_title', 'Password Reimpostata', []) :
          this.tranPipe.transform('reset_password_ko_title', 'Ops! qualcosa è andato storto', []),
        res.status ? this.tranPipe.transform('reset_password_ok_subtitle', 'La nuova password è stata impostata correttamente', []) :
          res.message,
        this.tranPipe.transform("request_reset_password_modal_button", "Conferma"),
        async () => {
          if (res.status) {
            await this.router.navigate([this.tsvc.currentLanguage.value, 'login']);
          } else {
            await this.router.navigate([this.tsvc.currentLanguage.value, 'forgot-password']);
          }
        },
        'modal',
        true,
        undefined,
        undefined,
        false,
        false,
        false,
        false
      )
    }
  }
  
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  validatePassword = (control: AbstractControl): ValidationErrors | null => {
    const re = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    const res = re.test(String(control.value));
    if (!res) {
      return { format: true }
    }
    return {}
  }

  confirmationValidator = (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return { required: true };
    } else if (control.value !== this.validateForm.controls['password'].value) {
      return { match: true };
    }
    return {};
  };
  
}
