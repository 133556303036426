import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/core-modules/authentication/authentication.service';

@Component({
  selector: 'app-qr-code-modal',
  templateUrl: './qr-code-modal.component.html',
  styleUrls: ['./qr-code-modal.component.scss']
})
export class QrCodeModalComponent implements OnInit {

  constructor(public authSvc:AuthenticationService) { }

  ngOnInit() {
  }

}
