import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators, FormGroup, AbstractControl, ValidationErrors, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Guid, PurpleEditService, PurpleTranslationPipe, purpleFormFieldsGroup } from 'purple-lib';
import { Subscription, lastValueFrom } from 'rxjs';
import { PolicyAndUserAttributesService } from 'src/app/policy-and-user-attributes-service';
import { SaleEventService } from 'src/app/sale-event-service.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { PolicyItem, FeedbackPolicy, BookingService, PRPVSaleEventFull, PurpleApiResponseStatus } from 'src/core-modules/sdk/api';
import { AuthenticationService } from 'src/core-modules/authentication/authentication.service';
import { environment } from 'src/environments/default/environment';
import { pageTitle } from 'src/app/components/purple-sales-page-title/purple-sales-page-title.component';

@Component({
  selector: 'app-private-register',
  templateUrl: './private-register.component.html',
  styleUrls: ['../authentication.scss', './private-register.component.scss']
})
export class PrivateRegisterComponent implements OnInit {
  validateForm: FormGroup = this.fb.group({});
  purpleFormFieldGroups: purpleFormFieldsGroup[] | undefined;
  seId: string = "";
  isLoading = false

  subs: Subscription = new Subscription();
  policies: PolicyItem[] = [];
  registerBookingPolicies: FeedbackPolicy[] = [];
  companyName = environment.COMPANY_NAME ?? "PurpleBooking";

  saleEventCode: string = "";
  referralId: string = "";
  registrationId: string = "";
  hasBooking: boolean = false;

  pageTitle: pageTitle = {
    allRounded: 'allround',
    title: this.tranPipe.transform("register_light_title_new", "Benvenuto in {0}!", [this.companyName]),
    titleTranslationKey: 'register_light_title_new',
    subtitle:
    {
      subtitle: this.tranPipe.transform("register_light_if_not_has_account", "Imposta la tua password"),
      subtitleTranslationKey: 'register_light_if_not_has_account'
    },
    textAlign: 'left'
  }

  constructor(private fb: FormBuilder, private authSvc: AuthenticationService, private bookSvc: BookingService, private router: Router, private editSvc: PurpleEditService, private breakpointObserver: BreakpointObserver,
    public tsvc: AppTranslationService, private route: ActivatedRoute, public seSvc: SaleEventService, private tranPipe: PurpleTranslationPipe,
    public policySvc: PolicyAndUserAttributesService, private modal: NzModalService) { }

  async privateRegister(): Promise<void> {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    if (this.validateForm.valid) {
      this.policies.forEach(policy => {
        this.registerBookingPolicies.push({
          policyId: policy.policyId,
          policyCheck: this.validateForm.controls[policy.policyId].value
        })
      });

      const res = await lastValueFrom(this.bookSvc.confirmPrivateRegistration(this.tsvc.currentLanguage.value, {
        clientHost: window.location.hostname,
        email: this.validateForm.controls["email"].value,
        newPassword: this.validateForm.controls["password"].value,
        resetId: this.registrationId,
        firstName: this.validateForm.controls["firstname"].value,
        lastName: this.validateForm.controls["lastname"].value,
        referralId: this.referralId
      }));

      if (res.status.toLocaleLowerCase() == PurpleApiResponseStatus.Success) {
        this.authSvc.login(this.validateForm.controls["email"].value, this.validateForm.controls["password"].value, this.tsvc.currentLanguage.value).then(
          async () => {
            await this.router.navigate([this.tsvc.currentLanguage.value, 'event', 'detail', this.saleEventCode], { queryParams: { returnUrl: "/" + this.tsvc.currentLanguage.value + "/event/detail/" + this.saleEventCode } });
          }
        )
      } else {
        this.modal.create({
          nzTitle: this.tranPipe.transform("modal_reset_password_error", "Ops! qualcosa è andato storto"),
          nzContent: res.message,
          nzWidth: '600px',
          nzClassName: 'ps-modal footer-center',
          nzCancelDisabled: true,
          nzFooter: [
            {
              label: this.tranPipe.transform("modal_reset_password_button", "Conferma"),
              type: "primary",
              onClick: async () => {
                await this.router.navigate([this.tsvc.currentLanguage.value, 'login']);
                this.modal.closeAll();
              }
            }
          ]
        });
      }

    }
  }


  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  async ngOnInit(): Promise<void> {
    this.subs.add(this.seSvc.currentSaleEvent.subscribe((saleEvent: PRPVSaleEventFull | undefined) => {
      this.seId = saleEvent?.saleEventId ?? Guid.empty();
    }));

    this.route.queryParams.subscribe(async params => {
      this.seSvc.getSaleEventParameters(params);
      this.registrationId = params['registerId'];

      if (this.authSvc.isLoggedIn) {
        this.authSvc.logout();
      }

      this.purpleFormFieldGroups = [
        {
          fieldGroupNumber: 1,
          fielGroupBootstrapColumn: 12,
          fieldGroupPaddingLeft: false,
          fieldGroupPaddingRight: false,
          formFieldFormGroup: this.validateForm,
          formFields: [
            {
              fieldType: 'input-text',
              fieldControlName: 'firstname',
              fieldBootstrapColumn: 12,
              fieldName: this.tranPipe.transform('edit_user_name', 'Nome', []),
              fieldPlaceholder: this.tranPipe.transform('edit_user_name_placeholder', 'Nome utente', []),
              fieldValue: params['firstName'],
              fieldIsRequired: true,
              fieldIsDisabled: true,
              fieldPaddingLeft: false,
              fieldPaddingRight: false
            },
            {
              fieldType: 'input-text',
              fieldControlName: 'lastname',
              fieldBootstrapColumn: 12,
              fieldName: this.tranPipe.transform('edit_user_lastname', 'Cognome', []),
              fieldPlaceholder: this.tranPipe.transform('edit_user_lastname_placeholder', 'Cognome utente', []),
              fieldValue: params['lastName'],
              fieldIsRequired: true,
              fieldIsDisabled: true,
              fieldPaddingLeft: false,
              fieldPaddingRight: false
            },
            {
              fieldType: 'input-text',
              fieldControlName: 'email',
              fieldBootstrapColumn: 12,
              fieldName: this.tranPipe.transform('edit_user_email', 'Email', []),
              fieldPlaceholder: this.tranPipe.transform('edit_user_email_placeholder', 'Indirizzo Email', []),
              fieldValue: params['email'],
              fieldIsRequired: true,
              fieldIsDisabled: true,
              fieldCustomValidators: [Validators.email],
              fieldPaddingLeft: false,
              fieldPaddingRight: false
            },
            {
              fieldType: 'input-text',
              fieldControlName: 'password',
              fieldBootstrapColumn: 12,
              fieldName: this.tranPipe.transform('register_page_form_password', 'Password', []),
              fieldPlaceholder: this.tranPipe.transform('register_page_form_password_placeholder', 'Password', []),
              fieldValue: undefined,
              fieldIsRequired: true,
              fieldPaddingLeft: false,
              fieldPaddingRight: false,
              fieldOptions: [{ id: 'isPassword', value: true }],
              fieldCustomValidators: [this.validatePassword],
              fieldCustomValidatorErrors: [{ errorId: "format", text: this.tranPipe.transform("register_invalid_password_validator_error_text", "La password deve contenere almeno 8 caratteri, una lettera maiuscola e un carattere speciale (,;.!@#$%^&*)") }]
            },
            {
              fieldType: 'input-text',
              fieldControlName: 'checkPassword',
              fieldBootstrapColumn: 12,
              fieldName: this.tranPipe.transform('register_page_form_check_password', 'Conferma password', []),
              fieldPlaceholder: this.tranPipe.transform('register_page_form_check_password_placeholder', 'Conferma password', []),
              fieldValue: undefined,
              fieldIsRequired: true,
              fieldPaddingLeft: false,
              fieldPaddingRight: false,
              fieldCustomValidators: [this.confirmationValidator],
              fieldCustomValidatorErrors: [{ errorId: "match", text: this.tranPipe.transform("register_invalid_password_match_validator_error_text", "Le password inserite non corrispondono") }],
              fieldOptions: [{ id: 'isPassword', value: true }]
            }
          ]
        }
      ]

      this.saleEventCode = params['saleEventCode'];
      this.referralId = params['referralId'];
      this.hasBooking = params['hasBooking'] == "1";
    });

    this.subs.add(this.policySvc.publicPolicies.subscribe(s => {
      this.policies = (s.filter(f => f.pageName?.strEq('register-light')))[0]?.policies ?? [];

      this.policies.forEach(policy => {
        this.validateForm.addControl(policy.policyId, new UntypedFormControl(false, policy.isMandatoryPolicy ? Validators.requiredTrue : null));
      });

    }));
  }

  validatePassword = (control: AbstractControl): ValidationErrors | null => {
    const re = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    const res = re.test(String(control.value));
    if (!res) {
      return { format: true }
    }
    return {}
  }

  confirmationValidator = (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return { required: true };
    } else if (control.value !== this.validateForm.controls['password'].value) {
      return { match: true };
    }
    return {};
  };
}
