import { Component, OnInit } from '@angular/core';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { CheckUserVerifiedResponse, UsersService } from 'src/core-modules/sdk/api';
import { NzModalService, NzModalRef } from 'ng-zorro-antd/modal';
import { PurpleApiMakeRequestResponse, PurpleApiProxyService, PurpleTranslationPipe } from 'purple-lib';
import { VerifyAccountEmailModalComponent } from '../verify-account-email-modal/verify-account-email-modal.component';
import { EditPhoneNumberTranslations, VerifyAccountPhoneModalComponent } from '../verify-account-phone-modal/verify-account-phone-modal.component';
import { PurpleSalesNotificationService } from 'src/app/components/purple-sales-notification.service';
import { BreakpointsService } from 'src/core-modules/breakpoints/breakpoints.service';

@Component({
  selector: 'app-verify-account-modal',
  templateUrl: './verify-account-modal.component.html',
  styleUrls: ['../verify-account-modals.scss', './verify-account-modal.component.scss']
})
export class VerifyAccountModalComponent implements OnInit {

  constructor(private userSvc: UsersService, private tsvc: AppTranslationService, private modalRef: NzModalRef,
    private psNotSvc: PurpleSalesNotificationService, private tranPipe: PurpleTranslationPipe, private modal: NzModalService,
    private apiProxySvc: PurpleApiProxyService, public breakSvc: BreakpointsService) { }

  userStatus: CheckUserVerifiedResponse | undefined = undefined;
  isLoading: boolean = true;

  async ngOnInit() {
    await this.checkVerify();
  }

  async checkVerify() {
    await this.apiProxySvc.makeRequestErrorFunction<CheckUserVerifiedResponse>(() => this.userSvc.checkUserVerified(this.tsvc.currentLanguage.value),
      false, "verify-account-loader", 50000, (res: PurpleApiMakeRequestResponse<CheckUserVerifiedResponse>) => {

        this.psNotSvc.showMotSimpleNotification(
          this.tranPipe.transform('verify_account_check_user_error_title', 'Errore', []),
          this.tranPipe.transform('verify_account_check_user_error_subtitle', 'Verifica fallita', []),
          this.tranPipe.transform('request_change_phone_error_button', 'Riprova', []),
          async () => { await this.checkVerify() }, 'modal', true, undefined, undefined, true, false, true, false)

        this.modalRef.close();
      }, (res: PurpleApiMakeRequestResponse<CheckUserVerifiedResponse>) => {
        this.userStatus = res.data!
        this.isLoading = false;
      })
  }

  openVerify(type: 'email' | 'phone', isEdit: 'edit' | 'verify') {
    switch (type) {
      case 'email':
        this.modalRef.close();
        this.modal.create<VerifyAccountEmailModalComponent>({
          nzTitle: this.breakSvc.breakPointDevice$.value != 'desktop' ?
            this.tranPipe.transform(isEdit + "_account_email_modal_mobile_title", "Verifica email", []) :
            this.tranPipe.transform(isEdit + "_account_email_modal_title", "Verifica indirizzo email", []),
          nzContent: VerifyAccountEmailModalComponent,
          nzData: {
            title: {
              key: "verify_email_account_title",
              value: this.tranPipe.transform('verify_email_account_title', 'Seleziona l\'indirizzo email corretto', [])
            },
            subtitle: {
              key: "verify_email_account_subtitle",
              value: this.tranPipe.transform('verify_email_account_subtitle', 'Clicca il bottone sottostante e segui le istruzioni per verificare la tua email.', [])
            },
            button: {
              key: "verify_email_account_button",
              value: this.tranPipe.transform('verify_email_account_button', 'Invia email di verifica', [])
            }
          },
          nzWidth: '850px',
          nzClassName: 'purple-sales-modal',
          nzFooter: null,
          nzCentered: false,
          nzMaskClosable: false,
          nzClosable: true
        });
        break;
      case 'phone':
        this.modalRef.close();
        this.modal.create<VerifyAccountPhoneModalComponent, EditPhoneNumberTranslations>({
          nzTitle: this.breakSvc.breakPointDevice$.value != 'desktop' ?
            this.tranPipe.transform("verify_account_phone_mobile_modal_title", "Verifica telefono", []) :
            this.tranPipe.transform("verify_account_phone_modal_title", "Verifica numero di telefono", []),
          nzContent: VerifyAccountPhoneModalComponent,
          nzData: {
            title: {
              key: "verify_account_phone_title_modal",
              value: this.tranPipe.transform('verify_account_phone_title_modal', 'Inserisci il numero di telefono corretto', [])
            },
            subtitle: {
              key: "verify_account_phone_subtitle_modal",
              value: this.tranPipe.transform('verify_account_phone_subtitle_modal', 'Clicca sul bottone sottostante per ricevere il codice di verifica sms sul numero specificato', [])
            },
            sendButton: {
              key: "verify_account_phone_send_button",
              value: this.tranPipe.transform('verify_account_phone_send_button', 'Invia sms codice', [])
            },
            insertCodeTitle: {
              key: "verify_account_phone_insert_code_title",
              value: this.tranPipe.transform('verify_account_phone_insert_code_title', 'Inserisci il codice di verifica a 6 cifre', [])
            },
            insertCodeSubtitle: {
              key: "verify_account_phone_insert_code_subtitle",
              value: this.tranPipe.transform('verify_account_phone_insert_code_subtitle', 'Il codice è stato inviato al numero {0}.', [])
            },
            editNumberButton: {
              key: "verify_account_phone_reinsert_number_button",
              value: this.tranPipe.transform('verify_account_phone_reinsert_number_button', 'Modifica numero', [])
            },
            resendCodeButton: {
              key: "verify_account_phone_resend_code_button",
              value: this.tranPipe.transform('verify_account_phone_resend_code_button', 'Reinvia codice', [])
            },
            insertCodeButton: {
              key: "verify_account_phone_insert_code_button",
              value: this.tranPipe.transform('verify_account_phone_insert_code_button', 'Verifica numero', [])
            }
          },
          nzWidth: '850px',
          nzClassName: 'purple-sales-modal',
          nzFooter: null,
          nzCentered: false,
          nzMaskClosable: false,
          nzClosable: true
        });
        break;

      default:
        break;
    }

  }

}
