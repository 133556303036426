import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountryComponent } from './country.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NZ_ICONS, NzIconModule } from 'ng-zorro-antd/icon';
import { ArrowRightOutline } from '@ant-design/icons-angular/icons';
import { NzButtonModule } from 'ng-zorro-antd/button';

const icons = [ArrowRightOutline];
  

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    BrowserModule,
    NzIconModule,
    NzButtonModule
  ],
  providers:[
    { provide: NZ_ICONS, useValue: icons }
  ],
  declarations: [CountryComponent],
  bootstrap: [CountryComponent]
})
export class CountryModule { }
