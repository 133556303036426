import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Guid, PurpleApiMakeRequestResponse, PurpleApiProxyService, PurpleTranslationPipe } from 'purple-lib';
import { Subject, takeUntil } from 'rxjs';
import { PurpleSalesNotificationService } from 'src/app/components/purple-sales-notification.service';
import { pageTitle } from 'src/app/components/purple-sales-page-title/purple-sales-page-title.component';
import { LayoutService } from 'src/app/layout/layout.service';
import { AuthenticationService } from 'src/core-modules/authentication/authentication.service';
import { BreakpointsService } from 'src/core-modules/breakpoints/breakpoints.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BookingService, MembershipItem, MembershipItemVariant, MembershipManagerService } from 'src/core-modules/sdk/api';

@Component({
  selector: 'app-private-membership-detail',
  templateUrl: './private-membership-detail.component.html',
  styleUrls: ['./private-membership-detail.component.scss']
})
export class PrivateMembershipDetailComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router, private memberSvc: MembershipManagerService, private tsvc: AppTranslationService, 
    private bookSvc: BookingService, private tranPipe: PurpleTranslationPipe, private apiProxySvc: PurpleApiProxyService, private breakSvc: BreakpointsService,
    private psNotSvc: PurpleSalesNotificationService, public layoutSvc: LayoutService, private authSvc: AuthenticationService) { }

  pageTitle: pageTitle = {
    allRounded: 'allround',
    title: this.tranPipe.transform('membership_detail_title', 'Scopri le nostre membership', []),
    titleTranslationKey: 'membership_detail_title',
    subtitle:
    {
      subtitle: this.tranPipe.transform('membership_detail_subtitle', 'ABBONATI PER RICEVERE VANTAGGI ESCLUSIVI', []),
      subtitleTranslationKey: 'membership_detail_subtitle'
    }
  }

  membership: MembershipItem | undefined;
  sub: Subject<void> = new Subject();

  ngOnDestroy(): void {
    this.sub.next();
    this.sub.complete();
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const membId = params['membership'];

      if (!membId) {
        this.psNotSvc.showMotSimpleNotification(
          this.tranPipe.transform('membership_detail_error_title', 'Qualcosa è andato storto', []),
          this.tranPipe.transform('membership_detail_error_subtitle', 'Impossibile recuperare la membership', []),
          this.tranPipe.transform('membership_detail_error_button', 'Torna alla lista', []),
          async () => {

            this.router.navigate([this.tsvc.currentLanguage.value, 'event', 'membership'])
          }, 'modal', true, undefined, undefined, false, false, false, false)
      } else {
        this.getMembershipDetail(membId)
      }
    });


    this.breakSvc.breakPointDevice$.pipe(takeUntil(this.sub)).subscribe((device: "mobile" | "tablet" | "desktop" | undefined)=>{
      if(device == 'mobile'){
        this.pageTitle.textAlign = "center";
      }else{
        this.pageTitle.textAlign = "left"
      }

      console.log("BREAK: ", this.pageTitle)
    })
  }


  async getMembershipDetail(membId: string) {
    await this.apiProxySvc.makeRequestErrorFunction<MembershipItem[]>(() => this.memberSvc.getMemberships(this.tsvc.currentLanguage.value, {
      saleEventId: Guid.empty()
    }), true, "general", 500, (res: PurpleApiMakeRequestResponse<MembershipItem[]>) => {
      this.psNotSvc.showMotSimpleNotification(
        this.tranPipe.transform('membership_error_title', 'Qualcosa è andato storto', []),
        this.tranPipe.transform('membership_error_subtitle', 'Impossibile recuperare le memberships', []),
        this.tranPipe.transform('membership_error_button', 'Riprova', []),
        async () => { await this.getMembershipDetail(membId) }, 'modal', true, undefined, undefined, false, false, false, false)
    }, async (res: PurpleApiMakeRequestResponse<MembershipItem[]>) => {
      this.membership = res.data!.find(f => f.membershipName?.strEq(membId));

      this.pageTitle = {
        allRounded: 'allround',
        title: this.tranPipe.transform(this.membership?.membershipName + '_membership_detail_title', 'Diventa un membro {0}', [this.membership?.membershipName ?? ""]),
        titleTranslationKey: this.membership?.membershipName + '_membership_detail_title',
        subtitle:
        {
          subtitle: this.tranPipe.transform(this.membership?.membershipName + 'membership_detail_subtitle', 'TI ASPETTANO TANTI VANTAGGI ESCLUSIVI: SALTA LA FILA, ACCEDI IN ESCLUSIVA IL PRIMO GIORNO DI VENDITA, RICEVI GLI INVITI SU WHATSAPP E TANTO ALTRO!', []),
          subtitleTranslationKey: this.membership?.membershipName + 'membership_detail_subtitle'
        },
        textAlign: this.breakSvc.breakPointDevice$.value == 'mobile'? 'center' : 'left'
      }
    });
  }


  getPricesText(member: MembershipItem) {
    return member.membershipVariants?.flatMap(f => f.variantPrice + "€").join("/")
  }

  getPriceLabelText(variant: MembershipItemVariant) {
    console.log(variant.variantName)
    switch (variant.variantName?.toUpperCase()) {
      case "MONTH":
        break;
      case "YEAR":

        break;

      default:
        break;
    }

  }

  whiteProtection(color: string | undefined | null, defaultReplaceColor:string = "#000"): string{
    if(!color){
      return defaultReplaceColor
    }

    if(color.strEq("#fff") || color.strEq("#ffffff") || color.strEq("rgb(255,255,255)")){
      return defaultReplaceColor
    }

    return color;
  }

  async goToPayment(variantId: string | null | undefined, variantPrice: number){

    if(this.membership!.membershipName!.toLowerCase().includes("elite")){
      await this.apiProxySvc.makeRequestErrorFunction<boolean>(() => this.bookSvc.sendContactFormMessage(this.tsvc.currentLanguage.value, {
        saleEvent: Guid.empty(),
        email: this.authSvc.currentUser!.user!.email,
        fullName: this.authSvc.currentUser!.user!.firstName + " " + this.authSvc.currentUser!.user!.lastName,
        phone: this.authSvc.currentUser!.user!.phone,
        phonePrefix: this.authSvc.currentUser!.user!.phonePrefix,
        message: this.tranPipe.transform('send_contact_membership_message',
        'Chiedo di sottoscrivere l\'abbonamento {0}', [this.membership!.membershipName!]),
        clientHost: window.location.hostname
      }), false, "internal-loader", 500, (res: PurpleApiMakeRequestResponse<boolean>) => {
        this.psNotSvc.showMotSimpleNotification(
          this.tranPipe.transform("send_contact_membership_message_ko", "Ops! qualcosa è andato storto"),
          res.message ?? this.tranPipe.transform("send_contact_membership_message_subtitle_ko", "Impossibile inviare il messaggio"),
          this.tranPipe.transform("send_contact_membership_message_ko_button", "Riprova"),
          async () => {
            await this.goToPayment(variantId, variantPrice);
          },
          'modal',
          true,
          undefined,
          undefined,
          true,
          true,
          true,
          true
        )
      }, (res: PurpleApiMakeRequestResponse<boolean>) => {
        this.psNotSvc.showMotSimpleNotification(
          this.tranPipe.transform("send_contact_membership_message_ok", "Messaggio inviato"),
          this.tranPipe.transform("send_contact_membership_message_subtitle_ok", "La tua richiesta è stata inviata correttamente, ti risponderemo al più presto!"),
          this.tranPipe.transform("send_contact_membership_message_button_ok", "Ok"),
          undefined,
          'modal',
          true,
          undefined,
          undefined,
          false,
          false,
          false,
          false
        )
      })
    }else{
      if(variantId && variantPrice != 0){
        this.router.navigate([this.tsvc.currentLanguage.value, 'event', 'membership-payment'], {
          queryParams: {
            variantId: variantId
          }
        })
      }
    }
  }

  getContentId(){
    return this.membership!.membershipName! + "-" + this.layoutSvc.lightOrDark(this.membership!.hexColor)
  }

  async navigateToMember(){
    await this.router.navigate([this.tsvc.currentLanguage.value, 'event', 'membership'])
  }
}
