/**
 * Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { ApiUploadCompeteMediaPurpleApiResponse } from '../model/models';
import { BooleanPurpleApiResponse } from '../model/models';
import { CheckIfContentMediasExistUploadRequest } from '../model/models';
import { CheckIfMediaExistUploadRequest } from '../model/models';
import { ContentMediaFileListPurpleApiResponse } from '../model/models';
import { DeleteMediaContentMediaApiRequest } from '../model/models';
import { DeleteMediaContentMediaByIdApiRequest } from '../model/models';
import { GetAllImageMediasRequest } from '../model/models';
import { GetAllImageMediasResponsePurpleApiResponse } from '../model/models';
import { GetMediaInfoRequest } from '../model/models';
import { MediaFileListPurpleApiResponse } from '../model/models';
import { MediaInfoPurpleApiResponse } from '../model/models';
import { OutputSupportedExtensions } from '../model/models';
import { UploadImageOptionsPurpleApiResponse } from '../model/models';
import { UploadMediaType } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class MediaManagerService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param culture 
     * @param checkIfContentMediasExistUploadRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkIfContentMediasExistUpload(culture: string, checkIfContentMediasExistUploadRequest?: CheckIfContentMediasExistUploadRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ContentMediaFileListPurpleApiResponse>;
    public checkIfContentMediasExistUpload(culture: string, checkIfContentMediasExistUploadRequest?: CheckIfContentMediasExistUploadRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ContentMediaFileListPurpleApiResponse>>;
    public checkIfContentMediasExistUpload(culture: string, checkIfContentMediasExistUploadRequest?: CheckIfContentMediasExistUploadRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ContentMediaFileListPurpleApiResponse>>;
    public checkIfContentMediasExistUpload(culture: string, checkIfContentMediasExistUploadRequest?: CheckIfContentMediasExistUploadRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling CheckIfContentMediasExistUpload.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ContentMediaFileListPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/MediaManager/CheckIfContentMediasExistUpload`,
            checkIfContentMediasExistUploadRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param checkIfMediaExistUploadRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkIfMediaExistUpload(culture: string, checkIfMediaExistUploadRequest?: CheckIfMediaExistUploadRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<MediaFileListPurpleApiResponse>;
    public checkIfMediaExistUpload(culture: string, checkIfMediaExistUploadRequest?: CheckIfMediaExistUploadRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<MediaFileListPurpleApiResponse>>;
    public checkIfMediaExistUpload(culture: string, checkIfMediaExistUploadRequest?: CheckIfMediaExistUploadRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<MediaFileListPurpleApiResponse>>;
    public checkIfMediaExistUpload(culture: string, checkIfMediaExistUploadRequest?: CheckIfMediaExistUploadRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling CheckIfMediaExistUpload.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<MediaFileListPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/MediaManager/CheckIfMediaExistUpload`,
            checkIfMediaExistUploadRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param deleteMediaContentMediaByIdApiRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteMediaContentMediaById(culture: string, deleteMediaContentMediaByIdApiRequest?: DeleteMediaContentMediaByIdApiRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public deleteMediaContentMediaById(culture: string, deleteMediaContentMediaByIdApiRequest?: DeleteMediaContentMediaByIdApiRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public deleteMediaContentMediaById(culture: string, deleteMediaContentMediaByIdApiRequest?: DeleteMediaContentMediaByIdApiRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public deleteMediaContentMediaById(culture: string, deleteMediaContentMediaByIdApiRequest?: DeleteMediaContentMediaByIdApiRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling DeleteMediaContentMediaById.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/MediaManager/DeleteMediaContentMediaById`,
            deleteMediaContentMediaByIdApiRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param deleteMediaContentMediaApiRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteMediaContentMedia(culture: string, deleteMediaContentMediaApiRequest?: DeleteMediaContentMediaApiRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public deleteMediaContentMedia(culture: string, deleteMediaContentMediaApiRequest?: DeleteMediaContentMediaApiRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public deleteMediaContentMedia(culture: string, deleteMediaContentMediaApiRequest?: DeleteMediaContentMediaApiRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public deleteMediaContentMedia(culture: string, deleteMediaContentMediaApiRequest?: DeleteMediaContentMediaApiRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling DeleteMediaContentMedia.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/MediaManager/DeleteMediaContentMedia`,
            deleteMediaContentMediaApiRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param getAllImageMediasRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllImageMedias(culture: string, getAllImageMediasRequest?: GetAllImageMediasRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GetAllImageMediasResponsePurpleApiResponse>;
    public getAllImageMedias(culture: string, getAllImageMediasRequest?: GetAllImageMediasRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GetAllImageMediasResponsePurpleApiResponse>>;
    public getAllImageMedias(culture: string, getAllImageMediasRequest?: GetAllImageMediasRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GetAllImageMediasResponsePurpleApiResponse>>;
    public getAllImageMedias(culture: string, getAllImageMediasRequest?: GetAllImageMediasRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetAllImageMedias.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<GetAllImageMediasResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/MediaManager/GetAllImageMedias`,
            getAllImageMediasRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param mediaId 
     * @param culture 
     * @param resizeImageIsThumb 
     * @param resizeImagePxWidth 
     * @param resizeImagePxHeight 
     * @param resizeImageIgnoreAspectRatio 
     * @param resizeImageShrinkOnlyLargerImage 
     * @param resizeImageEnlargeOnlySmallerImage 
     * @param resizeImageFillArea 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMediaById(mediaId: string, culture: string, resizeImageIsThumb?: boolean, resizeImagePxWidth?: number, resizeImagePxHeight?: number, resizeImageIgnoreAspectRatio?: boolean, resizeImageShrinkOnlyLargerImage?: boolean, resizeImageEnlargeOnlySmallerImage?: boolean, resizeImageFillArea?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public getMediaById(mediaId: string, culture: string, resizeImageIsThumb?: boolean, resizeImagePxWidth?: number, resizeImagePxHeight?: number, resizeImageIgnoreAspectRatio?: boolean, resizeImageShrinkOnlyLargerImage?: boolean, resizeImageEnlargeOnlySmallerImage?: boolean, resizeImageFillArea?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public getMediaById(mediaId: string, culture: string, resizeImageIsThumb?: boolean, resizeImagePxWidth?: number, resizeImagePxHeight?: number, resizeImageIgnoreAspectRatio?: boolean, resizeImageShrinkOnlyLargerImage?: boolean, resizeImageEnlargeOnlySmallerImage?: boolean, resizeImageFillArea?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public getMediaById(mediaId: string, culture: string, resizeImageIsThumb?: boolean, resizeImagePxWidth?: number, resizeImagePxHeight?: number, resizeImageIgnoreAspectRatio?: boolean, resizeImageShrinkOnlyLargerImage?: boolean, resizeImageEnlargeOnlySmallerImage?: boolean, resizeImageFillArea?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (mediaId === null || mediaId === undefined) {
            throw new Error('Required parameter mediaId was null or undefined when calling GetMediaById.');
        }
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetMediaById.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (mediaId !== undefined && mediaId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>mediaId, 'MediaId');
        }
        if (resizeImageIsThumb !== undefined && resizeImageIsThumb !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>resizeImageIsThumb, 'ResizeImage.IsThumb');
        }
        if (resizeImagePxWidth !== undefined && resizeImagePxWidth !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>resizeImagePxWidth, 'ResizeImage.PxWidth');
        }
        if (resizeImagePxHeight !== undefined && resizeImagePxHeight !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>resizeImagePxHeight, 'ResizeImage.PxHeight');
        }
        if (resizeImageIgnoreAspectRatio !== undefined && resizeImageIgnoreAspectRatio !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>resizeImageIgnoreAspectRatio, 'ResizeImage.IgnoreAspectRatio');
        }
        if (resizeImageShrinkOnlyLargerImage !== undefined && resizeImageShrinkOnlyLargerImage !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>resizeImageShrinkOnlyLargerImage, 'ResizeImage.ShrinkOnlyLargerImage');
        }
        if (resizeImageEnlargeOnlySmallerImage !== undefined && resizeImageEnlargeOnlySmallerImage !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>resizeImageEnlargeOnlySmallerImage, 'ResizeImage.EnlargeOnlySmallerImage');
        }
        if (resizeImageFillArea !== undefined && resizeImageFillArea !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>resizeImageFillArea, 'ResizeImage.FillArea');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<any>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/MediaManager/GetMediaById`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param contentId 
     * @param contentTypeId 
     * @param culture 
     * @param contentMediaIndex 
     * @param resizeImageIsThumb 
     * @param resizeImagePxWidth 
     * @param resizeImagePxHeight 
     * @param resizeImageIgnoreAspectRatio 
     * @param resizeImageShrinkOnlyLargerImage 
     * @param resizeImageEnlargeOnlySmallerImage 
     * @param resizeImageFillArea 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMedia(contentId: string, contentTypeId: string, culture: string, contentMediaIndex?: number, resizeImageIsThumb?: boolean, resizeImagePxWidth?: number, resizeImagePxHeight?: number, resizeImageIgnoreAspectRatio?: boolean, resizeImageShrinkOnlyLargerImage?: boolean, resizeImageEnlargeOnlySmallerImage?: boolean, resizeImageFillArea?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public getMedia(contentId: string, contentTypeId: string, culture: string, contentMediaIndex?: number, resizeImageIsThumb?: boolean, resizeImagePxWidth?: number, resizeImagePxHeight?: number, resizeImageIgnoreAspectRatio?: boolean, resizeImageShrinkOnlyLargerImage?: boolean, resizeImageEnlargeOnlySmallerImage?: boolean, resizeImageFillArea?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public getMedia(contentId: string, contentTypeId: string, culture: string, contentMediaIndex?: number, resizeImageIsThumb?: boolean, resizeImagePxWidth?: number, resizeImagePxHeight?: number, resizeImageIgnoreAspectRatio?: boolean, resizeImageShrinkOnlyLargerImage?: boolean, resizeImageEnlargeOnlySmallerImage?: boolean, resizeImageFillArea?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public getMedia(contentId: string, contentTypeId: string, culture: string, contentMediaIndex?: number, resizeImageIsThumb?: boolean, resizeImagePxWidth?: number, resizeImagePxHeight?: number, resizeImageIgnoreAspectRatio?: boolean, resizeImageShrinkOnlyLargerImage?: boolean, resizeImageEnlargeOnlySmallerImage?: boolean, resizeImageFillArea?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (contentId === null || contentId === undefined) {
            throw new Error('Required parameter contentId was null or undefined when calling GetMedia.');
        }
        if (contentTypeId === null || contentTypeId === undefined) {
            throw new Error('Required parameter contentTypeId was null or undefined when calling GetMedia.');
        }
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetMedia.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (contentId !== undefined && contentId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>contentId, 'ContentId');
        }
        if (contentTypeId !== undefined && contentTypeId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>contentTypeId, 'ContentTypeId');
        }
        if (contentMediaIndex !== undefined && contentMediaIndex !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>contentMediaIndex, 'ContentMediaIndex');
        }
        if (resizeImageIsThumb !== undefined && resizeImageIsThumb !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>resizeImageIsThumb, 'ResizeImage.IsThumb');
        }
        if (resizeImagePxWidth !== undefined && resizeImagePxWidth !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>resizeImagePxWidth, 'ResizeImage.PxWidth');
        }
        if (resizeImagePxHeight !== undefined && resizeImagePxHeight !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>resizeImagePxHeight, 'ResizeImage.PxHeight');
        }
        if (resizeImageIgnoreAspectRatio !== undefined && resizeImageIgnoreAspectRatio !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>resizeImageIgnoreAspectRatio, 'ResizeImage.IgnoreAspectRatio');
        }
        if (resizeImageShrinkOnlyLargerImage !== undefined && resizeImageShrinkOnlyLargerImage !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>resizeImageShrinkOnlyLargerImage, 'ResizeImage.ShrinkOnlyLargerImage');
        }
        if (resizeImageEnlargeOnlySmallerImage !== undefined && resizeImageEnlargeOnlySmallerImage !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>resizeImageEnlargeOnlySmallerImage, 'ResizeImage.EnlargeOnlySmallerImage');
        }
        if (resizeImageFillArea !== undefined && resizeImageFillArea !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>resizeImageFillArea, 'ResizeImage.FillArea');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<any>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/MediaManager/GetMedia`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param getMediaInfoRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMediaInfo(culture: string, getMediaInfoRequest?: GetMediaInfoRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<MediaInfoPurpleApiResponse>;
    public getMediaInfo(culture: string, getMediaInfoRequest?: GetMediaInfoRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<MediaInfoPurpleApiResponse>>;
    public getMediaInfo(culture: string, getMediaInfoRequest?: GetMediaInfoRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<MediaInfoPurpleApiResponse>>;
    public getMediaInfo(culture: string, getMediaInfoRequest?: GetMediaInfoRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetMediaInfo.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<MediaInfoPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/MediaManager/GetMediaInfo`,
            getMediaInfoRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public toGen(culture: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<UploadImageOptionsPurpleApiResponse>;
    public toGen(culture: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<UploadImageOptionsPurpleApiResponse>>;
    public toGen(culture: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<UploadImageOptionsPurpleApiResponse>>;
    public toGen(culture: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling ToGen.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<UploadImageOptionsPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/MediaManager/ToGen`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param mediaFile 
     * @param uploadMediaType 
     * @param contentTypeId 
     * @param contentId 
     * @param contentMediaIndex 
     * @param overwriteContentMedia 
     * @param uploadImageOptionsUseDefaultValues 
     * @param name 
     * @param description 
     * @param overrideCrc 
     * @param uploadImageOptionsProcessImageSettingsResizeImageOptionsPxWidth 
     * @param uploadImageOptionsProcessImageSettingsResizeImageOptionsPxHeight 
     * @param uploadImageOptionsProcessImageSettingsResizeImageOptionsIgnoreAspectRatio 
     * @param uploadImageOptionsProcessImageSettingsResizeImageOptionsShrinkOnlyLargerImage 
     * @param uploadImageOptionsProcessImageSettingsResizeImageOptionsEnlargeOnlySmallerImage 
     * @param uploadImageOptionsProcessImageSettingsResizeImageOptionsFillArea 
     * @param uploadImageOptionsProcessImageSettingsConvertImageOptionsToExtension 
     * @param uploadImageOptionsProcessImageSettingsCompressImageOptionsQuality 
     * @param uploadImageOptionsProcessImageSettingsCompressImageOptionsLossLess 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadComplete(culture: string, mediaFile: Blob, uploadMediaType: UploadMediaType, contentTypeId: string, contentId: string, contentMediaIndex: number, overwriteContentMedia: boolean, uploadImageOptionsUseDefaultValues: boolean, name?: string, description?: string, overrideCrc?: string, uploadImageOptionsProcessImageSettingsResizeImageOptionsPxWidth?: number, uploadImageOptionsProcessImageSettingsResizeImageOptionsPxHeight?: number, uploadImageOptionsProcessImageSettingsResizeImageOptionsIgnoreAspectRatio?: boolean, uploadImageOptionsProcessImageSettingsResizeImageOptionsShrinkOnlyLargerImage?: boolean, uploadImageOptionsProcessImageSettingsResizeImageOptionsEnlargeOnlySmallerImage?: boolean, uploadImageOptionsProcessImageSettingsResizeImageOptionsFillArea?: boolean, uploadImageOptionsProcessImageSettingsConvertImageOptionsToExtension?: OutputSupportedExtensions, uploadImageOptionsProcessImageSettingsCompressImageOptionsQuality?: number, uploadImageOptionsProcessImageSettingsCompressImageOptionsLossLess?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ApiUploadCompeteMediaPurpleApiResponse>;
    public uploadComplete(culture: string, mediaFile: Blob, uploadMediaType: UploadMediaType, contentTypeId: string, contentId: string, contentMediaIndex: number, overwriteContentMedia: boolean, uploadImageOptionsUseDefaultValues: boolean, name?: string, description?: string, overrideCrc?: string, uploadImageOptionsProcessImageSettingsResizeImageOptionsPxWidth?: number, uploadImageOptionsProcessImageSettingsResizeImageOptionsPxHeight?: number, uploadImageOptionsProcessImageSettingsResizeImageOptionsIgnoreAspectRatio?: boolean, uploadImageOptionsProcessImageSettingsResizeImageOptionsShrinkOnlyLargerImage?: boolean, uploadImageOptionsProcessImageSettingsResizeImageOptionsEnlargeOnlySmallerImage?: boolean, uploadImageOptionsProcessImageSettingsResizeImageOptionsFillArea?: boolean, uploadImageOptionsProcessImageSettingsConvertImageOptionsToExtension?: OutputSupportedExtensions, uploadImageOptionsProcessImageSettingsCompressImageOptionsQuality?: number, uploadImageOptionsProcessImageSettingsCompressImageOptionsLossLess?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ApiUploadCompeteMediaPurpleApiResponse>>;
    public uploadComplete(culture: string, mediaFile: Blob, uploadMediaType: UploadMediaType, contentTypeId: string, contentId: string, contentMediaIndex: number, overwriteContentMedia: boolean, uploadImageOptionsUseDefaultValues: boolean, name?: string, description?: string, overrideCrc?: string, uploadImageOptionsProcessImageSettingsResizeImageOptionsPxWidth?: number, uploadImageOptionsProcessImageSettingsResizeImageOptionsPxHeight?: number, uploadImageOptionsProcessImageSettingsResizeImageOptionsIgnoreAspectRatio?: boolean, uploadImageOptionsProcessImageSettingsResizeImageOptionsShrinkOnlyLargerImage?: boolean, uploadImageOptionsProcessImageSettingsResizeImageOptionsEnlargeOnlySmallerImage?: boolean, uploadImageOptionsProcessImageSettingsResizeImageOptionsFillArea?: boolean, uploadImageOptionsProcessImageSettingsConvertImageOptionsToExtension?: OutputSupportedExtensions, uploadImageOptionsProcessImageSettingsCompressImageOptionsQuality?: number, uploadImageOptionsProcessImageSettingsCompressImageOptionsLossLess?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ApiUploadCompeteMediaPurpleApiResponse>>;
    public uploadComplete(culture: string, mediaFile: Blob, uploadMediaType: UploadMediaType, contentTypeId: string, contentId: string, contentMediaIndex: number, overwriteContentMedia: boolean, uploadImageOptionsUseDefaultValues: boolean, name?: string, description?: string, overrideCrc?: string, uploadImageOptionsProcessImageSettingsResizeImageOptionsPxWidth?: number, uploadImageOptionsProcessImageSettingsResizeImageOptionsPxHeight?: number, uploadImageOptionsProcessImageSettingsResizeImageOptionsIgnoreAspectRatio?: boolean, uploadImageOptionsProcessImageSettingsResizeImageOptionsShrinkOnlyLargerImage?: boolean, uploadImageOptionsProcessImageSettingsResizeImageOptionsEnlargeOnlySmallerImage?: boolean, uploadImageOptionsProcessImageSettingsResizeImageOptionsFillArea?: boolean, uploadImageOptionsProcessImageSettingsConvertImageOptionsToExtension?: OutputSupportedExtensions, uploadImageOptionsProcessImageSettingsCompressImageOptionsQuality?: number, uploadImageOptionsProcessImageSettingsCompressImageOptionsLossLess?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling UploadComplete.');
        }
        if (mediaFile === null || mediaFile === undefined) {
            throw new Error('Required parameter mediaFile was null or undefined when calling UploadComplete.');
        }
        if (uploadMediaType === null || uploadMediaType === undefined) {
            throw new Error('Required parameter uploadMediaType was null or undefined when calling UploadComplete.');
        }
        if (contentTypeId === null || contentTypeId === undefined) {
            throw new Error('Required parameter contentTypeId was null or undefined when calling UploadComplete.');
        }
        if (contentId === null || contentId === undefined) {
            throw new Error('Required parameter contentId was null or undefined when calling UploadComplete.');
        }
        if (contentMediaIndex === null || contentMediaIndex === undefined) {
            throw new Error('Required parameter contentMediaIndex was null or undefined when calling UploadComplete.');
        }
        if (overwriteContentMedia === null || overwriteContentMedia === undefined) {
            throw new Error('Required parameter overwriteContentMedia was null or undefined when calling UploadComplete.');
        }
        if (uploadImageOptionsUseDefaultValues === null || uploadImageOptionsUseDefaultValues === undefined) {
            throw new Error('Required parameter uploadImageOptionsUseDefaultValues was null or undefined when calling UploadComplete.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: this.encoder});
        }

        if (mediaFile !== undefined) {
            formParams = formParams.append('MediaFile', <any>mediaFile) as any || formParams;
        }
        if (uploadMediaType !== undefined) {
            formParams = formParams.append('UploadMediaType', <any>uploadMediaType) as any || formParams;
        }
        if (contentTypeId !== undefined) {
            formParams = formParams.append('ContentTypeId', <any>contentTypeId) as any || formParams;
        }
        if (contentId !== undefined) {
            formParams = formParams.append('ContentId', <any>contentId) as any || formParams;
        }
        if (contentMediaIndex !== undefined) {
            formParams = formParams.append('ContentMediaIndex', <any>contentMediaIndex) as any || formParams;
        }
        if (overwriteContentMedia !== undefined) {
            formParams = formParams.append('OverwriteContentMedia', <any>overwriteContentMedia) as any || formParams;
        }
        if (name !== undefined) {
            formParams = formParams.append('Name', <any>name) as any || formParams;
        }
        if (description !== undefined) {
            formParams = formParams.append('Description', <any>description) as any || formParams;
        }
        if (overrideCrc !== undefined) {
            formParams = formParams.append('OverrideCrc', <any>overrideCrc) as any || formParams;
        }
        if (uploadImageOptionsUseDefaultValues !== undefined) {
            formParams = formParams.append('UploadImageOptions.UseDefaultValues', <any>uploadImageOptionsUseDefaultValues) as any || formParams;
        }
        if (uploadImageOptionsProcessImageSettingsResizeImageOptionsPxWidth !== undefined) {
            formParams = formParams.append('UploadImageOptions.ProcessImageSettings.ResizeImageOptions.PxWidth', <any>uploadImageOptionsProcessImageSettingsResizeImageOptionsPxWidth) as any || formParams;
        }
        if (uploadImageOptionsProcessImageSettingsResizeImageOptionsPxHeight !== undefined) {
            formParams = formParams.append('UploadImageOptions.ProcessImageSettings.ResizeImageOptions.PxHeight', <any>uploadImageOptionsProcessImageSettingsResizeImageOptionsPxHeight) as any || formParams;
        }
        if (uploadImageOptionsProcessImageSettingsResizeImageOptionsIgnoreAspectRatio !== undefined) {
            formParams = formParams.append('UploadImageOptions.ProcessImageSettings.ResizeImageOptions.IgnoreAspectRatio', <any>uploadImageOptionsProcessImageSettingsResizeImageOptionsIgnoreAspectRatio) as any || formParams;
        }
        if (uploadImageOptionsProcessImageSettingsResizeImageOptionsShrinkOnlyLargerImage !== undefined) {
            formParams = formParams.append('UploadImageOptions.ProcessImageSettings.ResizeImageOptions.ShrinkOnlyLargerImage', <any>uploadImageOptionsProcessImageSettingsResizeImageOptionsShrinkOnlyLargerImage) as any || formParams;
        }
        if (uploadImageOptionsProcessImageSettingsResizeImageOptionsEnlargeOnlySmallerImage !== undefined) {
            formParams = formParams.append('UploadImageOptions.ProcessImageSettings.ResizeImageOptions.EnlargeOnlySmallerImage', <any>uploadImageOptionsProcessImageSettingsResizeImageOptionsEnlargeOnlySmallerImage) as any || formParams;
        }
        if (uploadImageOptionsProcessImageSettingsResizeImageOptionsFillArea !== undefined) {
            formParams = formParams.append('UploadImageOptions.ProcessImageSettings.ResizeImageOptions.FillArea', <any>uploadImageOptionsProcessImageSettingsResizeImageOptionsFillArea) as any || formParams;
        }
        if (uploadImageOptionsProcessImageSettingsConvertImageOptionsToExtension !== undefined) {
            formParams = formParams.append('UploadImageOptions.ProcessImageSettings.ConvertImageOptions.ToExtension', <any>uploadImageOptionsProcessImageSettingsConvertImageOptionsToExtension) as any || formParams;
        }
        if (uploadImageOptionsProcessImageSettingsCompressImageOptionsQuality !== undefined) {
            formParams = formParams.append('UploadImageOptions.ProcessImageSettings.CompressImageOptions.Quality', <any>uploadImageOptionsProcessImageSettingsCompressImageOptionsQuality) as any || formParams;
        }
        if (uploadImageOptionsProcessImageSettingsCompressImageOptionsLossLess !== undefined) {
            formParams = formParams.append('UploadImageOptions.ProcessImageSettings.CompressImageOptions.LossLess', <any>uploadImageOptionsProcessImageSettingsCompressImageOptionsLossLess) as any || formParams;
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ApiUploadCompeteMediaPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/MediaManager/UploadComplete`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
