<div class="instructions-drawer-container ps-d-flex ps-flex-wrap ps-justify-content-center ps-w-100 ps-align-self-start ps-align-items-start">


  <div class="ps-w-100 ps-d-flex ps-justify-content-center">
    <img contentId="instructions-print" contentTypeId="instruction-image" purpleEditableImage class="instructions-image-mobile" />
  </div>
  <p purpleEditableText tranKey='instructions_drawer_print' class="ps-w-100 ps-text-center ps-mb-0 instructions-label ps-mb-1">
    {{ 'instructions_drawer_print' | translation: 'Stampa il biglietto con il qr code della tua prenotazione': []}}
  </p>



  <div class="ps-w-100 ps-d-flex ps-justify-content-center ps-mt-5">
    <img contentId="instructions-qr" contentTypeId="instruction-image" purpleEditableImage class="instructions-image-mobile" />
  </div>
  <p purpleEditableText tranKey='instructions_drawer_show_qr_code' class="ps-w-100 ps-text-center ps-mb-0 instructions-label">
    {{ 'instructions_drawer_show_qr_code' | translation: 'Mostra il qr code all’ingresso della svendita': []}}
  </p>

</div>
