<div class="ps-w-100 ps-d-flex ps-flex-wrap ps-justify-content-center verify-account-container">
  <p purpleEditableText [tranKey]='translations.title.key' class="verify-account-section-title">
    {{ translations.title.value}}
  </p>
  <p purpleEditableText [tranKey]='translations.subtitle.key' class="verify-account-section-subtitle">
    {{ translations.subtitle.value}}
  </p>

  <div class="ps-w-100 ps-d-flex ps-flex-wrap ps-justify-content-center verify-account-modal-form-container">
    <app-purple-form [disbledForm]="isLoading" class="verify-account-modal-form"
      *ngIf="purpleFormFieldGroups!= undefined" [(formGroup)]="validateForm"
      [purpleFormFieldGroups]="purpleFormFieldGroups" (purpleFormSubmit)="verifyEmail()">
    </app-purple-form>
    <app-purple-sales-button purpleEditableText [tranKey]='translations.button.key' (click)="verifyEmail()"
      [buttonText]="translations.button.value">
    </app-purple-sales-button>

  </div>
</div>