<nz-spin class="ps-d-flex ps-flex-wrap ps-w-100 verify-account-spin" [nzSpinning]="isLoading">
  <div class="ps-w-100 ps-d-flex ps-flex-wrap verify-account-container">
    <nz-steps class="ps-w-100" [nzCurrent]="currentStep" nzProgressDot>
      <nz-step class="ps-w-100">
      </nz-step>
      <nz-step>
      </nz-step>
    </nz-steps>
    <container-element class="ps-w-100 ps-d-flex ps-flex-wrap ps-justify-content-center" [ngSwitch]="currentStep">

      <ng-container class="ps-w-100" *ngSwitchCase="0">
        <p purpleEditableText [tranKey]='translations.title.key' class="verify-account-section-title">
          {{translations.title.value}}
        </p>
        <p purpleEditableText [tranKey]='translations.subtitle.key' class="verify-account-section-subtitle">
          {{translations.subtitle.value}}
        </p>
        <div class="ps-w-100 ps-d-flex ps-flex-wrap ps-justify-content-center verify-account-modal-form-container">
          <app-purple-form [disbledForm]="isLoading" class="verify-account-modal-form"
            *ngIf="purpleFormFieldGroups!= undefined" [(formGroup)]="validateForm"
            [purpleFormFieldGroups]="purpleFormFieldGroups" (purpleFormSubmit)="requestVerifyPhone(false)">
          </app-purple-form>
          <app-purple-sales-button purpleEditableText [tranKey]='translations.sendButton.key'
            (click)="requestVerifyPhone(false)"
            [buttonText]="translations.sendButton.value">
          </app-purple-sales-button>

        </div>
      </ng-container>
      <ng-container class="ps-w-100" *ngSwitchCase="1">
        <p purpleEditableText [tranKey]='translations.insertCodeTitle.key' class="verify-account-section-title">
          {{ translations.insertCodeTitle.value}}
        </p>
        <p purpleEditableText [tranKey]='translations.insertCodeSubtitle.key'
          class="verify-account-section-subtitle">
          {{translations.insertCodeSubtitle.value}}
        </p>
        <div
          class="ps-w-100 ps-d-flex ps-flex-wrap ps-justify-content-center verify-account-phone-input-number-external-container">
          <div
            class="ps-d-flex ps-justify-content-center ps-align-items-center ps-w-100 verify-account-phone-input-number-container">
            <input #phoneCode1 [next-tab]="phoneCode2" (ngModelChange)="setPhoneCode(1, $event)"
              (keyup)="onDigitInput($event, null, phoneCode2)" [ngModel]="phoneCode.get(1)" type="tel" maxlength="1"
              pattern="[0-9]" class="verify-account-phone-input-number-field">
            <input #phoneCode2 [next-tab]="phoneCode3" (ngModelChange)="setPhoneCode(2, $event)"
              (keyup)="onDigitInput($event, phoneCode1, phoneCode3)" [ngModel]="phoneCode.get(2)" type="tel"
              maxlength="1" pattern="[0-9]" class="verify-account-phone-input-number-field">
            <input #phoneCode3 [next-tab]="phoneCode4" (ngModelChange)="setPhoneCode(3, $event)"
              (keyup)="onDigitInput($event, phoneCode2, phoneCode4)" [ngModel]="phoneCode.get(3)" type="tel"
              maxlength="1" pattern="[0-9]" class="verify-account-phone-input-number-field">
            <input #phoneCode4 [next-tab]="phoneCode5" (ngModelChange)="setPhoneCode(4, $event)"
              (keyup)="onDigitInput($event, phoneCode3, phoneCode5)" [ngModel]="phoneCode.get(4)" type="tel"
              maxlength="1" pattern="[0-9]" class="verify-account-phone-input-number-field">
            <input #phoneCode5 [next-tab]="phoneCode6" (ngModelChange)="setPhoneCode(5, $event)"
              (keyup)="onDigitInput($event, phoneCode4, phoneCode6)" [ngModel]="phoneCode.get(5)" type="tel"
              maxlength="1" pattern="[0-9]" class="verify-account-phone-input-number-field">
            <input #phoneCode6 (ngModelChange)="setPhoneCode(6, $event)" [ngModel]="phoneCode.get(6)" type="tel"
              (keyup)="onDigitInput($event, phoneCode5, null)" maxlength="1" pattern="[0-9]"
              class="verify-account-phone-input-number-field">
          </div>
          <div *ngIf="showRetryButtons && isSubmitCodeButtonDisabled()"
            class="retry-button-container">
              <app-purple-sales-button class="retry-button left" purpleEditableText
                [tranKey]='translations.editNumberButton.key' (click)="currentStep = currentStep-1"
                [buttonText]="translations.editNumberButton.value">
              </app-purple-sales-button>
     
              <app-purple-sales-button class="retry-button right" [buttonType]="'primary'" 
              purpleEditableText [tranKey]='translations.resendCodeButton.key' (click)="requestVerifyPhone(true)"
                [buttonText]="translations.resendCodeButton.value">
              </app-purple-sales-button>

          </div>
          <app-purple-sales-button *ngIf="!showRetryButtons || !isSubmitCodeButtonDisabled()" purpleEditableText
          [tranKey]='translations.insertCodeButton.key' (click)="verifyPhone()"
            [isDisabled]="isSubmitCodeButtonDisabled()"
            [buttonText]="translations.insertCodeButton.value">
          </app-purple-sales-button>
        </div>
      </ng-container>
    </container-element>
  </div>
</nz-spin>