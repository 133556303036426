import { Component, OnInit } from '@angular/core';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AnimationOptions } from 'ngx-lottie';
import { LayoutService } from 'src/app/layout/layout.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BookingService, PRPVSaleEventFull, UserNextSaleEventBookingItem, UserPastSaleEventBookingItem, UserSaleEventBookings } from 'src/core-modules/sdk/api';
import { environment } from 'src/environments/default/environment';
import { SaleEventDatePipe } from 'src/purple-widgets/custom-pipe/sale-event-date.pipe';
import { InstructionsDrawerComponent } from '../drawers/instructions-drawer/instructions-drawer.component';
import { InviteFriendsDrawerComponent } from '../drawers/invite-friends-drawer/invite-friends-drawer.component';
import { QrCodeDrawerComponent } from '../drawers/qr-code-drawer/qr-code-drawer.component';
import { InstructionsModalComponent } from '../modals/instructions-modal/instructions-modal.component';
import { InviteFriendsModalComponent } from '../modals/invite-friends-modal/invite-friends-modal.component';
import { QrCodeModalComponent } from '../modals/qr-code-modal/qr-code-modal.component';
import { PrivateInviteFriendsDrawerComponent } from '../drawers/private-invite-friends-drawer/private-invite-friends-drawer.component';
import { PrivateInviteFriendsModalComponent } from '../modals/private-invite-friends-modal/private-invite-friends-modal.component';
import { PurpleApiMakeRequestResponse, PurpleApiProxyService, PurpleTranslationPipe } from 'purple-lib';
import { pageTitle } from 'src/app/components/purple-sales-page-title/purple-sales-page-title.component';
import { PurpleSalesNotificationService } from 'src/app/components/purple-sales-notification.service';
import { BreakpointsService } from 'src/core-modules/breakpoints/breakpoints.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-private-my-reservation-v2',
  templateUrl: './private-my-reservation-v2.component.html',
  styleUrls: ['./private-my-reservation-v2.component.scss']
})
export class PrivateMyReservationV2Component implements OnInit {

  constructor(private tranPipe: PurpleTranslationPipe, private breakpointObserver: BreakpointObserver, public tsvc: AppTranslationService, private layoutSvc: LayoutService, public breakSvc: BreakpointsService,
    private bookSvc: BookingService, private drawerSvc: NzDrawerService, private mesSvc: NzMessageService, private modalSvc: NzModalService,
    private modal: NzModalService, private datepipe: SaleEventDatePipe, private psNotSvc: PurpleSalesNotificationService, private apiProxySvc: PurpleApiProxyService) {
    this.showCheckInInfo = environment.SHOW_CHEK_IN_INFO;
  }

  subtitleText: string = this.tranPipe.transform("my_reservations_subtitle", "Tutte le prenotazioni e gli eventi a cui hai partecipato <span class='dot'>!</span>")
  nextBookings: UserNextSaleEventBookingItem[] = [];
  options: AnimationOptions = {
    path: '/assets/loaders/' + environment.COMPANY_ABB + '/empty-booking.json',
  };
  showCheckInInfo: boolean = true;
  basePath: string = localStorage.getItem("BasePath")!;

  pageTitle: pageTitle = {
    allRounded: 'allround',
    title: this.tranPipe.transform('my_reservations_title', 'Le mie prenotazioni', []),
    titleTranslationKey: 'sale_events_preview_title',
    subtitle:
    {
      subtitle: this.tranPipe.transform('my_reservations_subtitle', 'Tutte le prenotazioni e gli eventi a cui hai partecipato', []),
      subtitleTranslationKey: 'my_reservations_subtitle'
    }
  }
  showOnlyIcon: boolean = false;

  async getSaleEventBookings() {

    await this.apiProxySvc.makeRequestErrorFunction<UserSaleEventBookings>(() => this.bookSvc.getUserSaleEventBookings(this.tsvc.currentLanguage.value), true, "general", 500,
      (res: PurpleApiMakeRequestResponse<UserSaleEventBookings>) => {
        this.psNotSvc.showMotSimpleNotification(
          this.tranPipe.transform('error_message_default_title', 'Qualcosa è andato storto', []),
          res.message,
          this.tranPipe.transform('error_message_default_button', 'Ok', []),
          async () => { await this.getSaleEventBookings() }, 'modal', true, undefined, undefined, false, false, false, false)
      }, async (res: PurpleApiMakeRequestResponse<UserSaleEventBookings>) => {
        this.nextBookings = res.data!.nextUserSaleEventBooking;
      })
  }

  async ngOnInit(): Promise<void> {
    this.breakpointObserver.observe([
      '(max-width: 760px)',
      '(min-width: 760.01px) and (max-width: 929.99px)',
      '(min-width: 930px)'
    ]).subscribe((state: BreakpointState) => {
      if (state.breakpoints['(max-width: 760px)']) {
        this.showOnlyIcon = false;
      } else if (state.breakpoints['(min-width: 760.01px) and (max-width: 929.99px)']) {
        this.showOnlyIcon = true;        
      } else if ('(min-width: 930px)') {
        this.showOnlyIcon = false;        
      }
    });
    await this.getSaleEventBookings();
  }

  addToCalendar(saleEventSlot: UserNextSaleEventBookingItem): string {

    return `${this.basePath}/${this.tsvc.currentLanguage.value}/booking/downloadCalendarBook?SaleEventSlotBookingId=${saleEventSlot.slot.bookingId}`;
  }

  showQrCodeModal() {
    this.modalSvc.create({
      nzContent: QrCodeModalComponent,
      nzTitle: this.tranPipe.transform('qr_code_modal_title', 'QrCode di Accesso', []),
      nzWidth: '1000px',
      nzClosable: true,
      nzFooter: null,
      nzMaskClosable: false
    });
  }

  showQrCodeDrawer() {
    this.layoutSvc.qrCodeOpen.next(true)
    this.drawerSvc.create<QrCodeDrawerComponent, { value: string }, string>({
      nzTitle: undefined,
      nzContent: QrCodeDrawerComponent,
      nzClosable: true,
      nzWrapClassName: "qr-code",
      nzFooter: undefined,
      nzPlacement: 'bottom'
    }).afterClose.subscribe(() => {
      this.layoutSvc.qrCodeOpen.next(false)
    })
  }

  showInviteFriendsDrawer(saleEventSlot: UserNextSaleEventBookingItem) {
    if (saleEventSlot.isPrivateInviteCounter) {
      this.drawerSvc.create<PrivateInviteFriendsDrawerComponent, { value: string }, string>({
        nzTitle: undefined,
        nzContent: PrivateInviteFriendsDrawerComponent,
        nzClosable: false,
        nzWrapClassName: "private-invite-friends",
        nzFooter: undefined,
        nzPlacement: 'bottom',
        nzContentParams: {
          saleEventId: saleEventSlot.saleEventId
        }
      });
    } else {
      this.drawerSvc.create<InviteFriendsDrawerComponent, { value: string }, string>({
        nzTitle: undefined,
        nzContent: InviteFriendsDrawerComponent,
        nzClosable: false,
        nzWrapClassName: "invite-friends",
        nzFooter: undefined,
        nzPlacement: 'bottom',
        nzContentParams: {
          book: saleEventSlot,
        }
      }).afterClose.subscribe(() => {
        this.layoutSvc.qrCodeOpen.next(false)
      })
    }
  }

  pdfSrcNext(saleEventSlot: UserNextSaleEventBookingItem): string {
    return `${this.basePath}/${this.tsvc.currentLanguage.value}/booking/downloadPdfBooks?SaleEventSlotBookingIds=${saleEventSlot.slot.bookingId}`;
  }

  pdfSrcPast(saleEventSlot: UserPastSaleEventBookingItem): string {
    const ses = saleEventSlot.slots.map(m => m.bookingId).join(',')
    return `${this.basePath}/${this.tsvc.currentLanguage.value}/booking/downloadPdfBooks?SaleEventSlotBookingIds=${ses}`;
  }

  showInviteButton(saleEventSlot: UserNextSaleEventBookingItem): boolean {
    if (!saleEventSlot.isPrivateInviteCounter) {
      return true;
    }

    if (saleEventSlot.isPrivateInviteCounter && saleEventSlot.userCanInvite) {
      return true;
    }

    return false;

  }

  showDeleteButton(saleEventSlot: UserNextSaleEventBookingItem): boolean {
    if (!saleEventSlot.isPrivateInviteCounter) {
      return true;
    }

    if (saleEventSlot.isPrivateInviteCounter && saleEventSlot.userCanDelete) {
      return true;
    }

    return false;

  }

  async showInviteFriendsModal(saleEventSlot: UserNextSaleEventBookingItem) {
    if (saleEventSlot.isPrivateInviteCounter) {
      this.modalSvc.create<PrivateInviteFriendsModalComponent, { saleEventId: string, saleEventName: string, saleEventPhone: string, saleEventEmail: string }>({
        nzContent: PrivateInviteFriendsModalComponent,
        nzTitle: undefined,
        nzWidth: '1000px',
        nzCloseIcon: undefined,
        nzClosable: false,
        nzCentered: true,
        nzClassName: "private-invite-friends-modal",
        nzData: {
          saleEventId: saleEventSlot.saleEventId,
          saleEventEmail: saleEventSlot.saleEventEmail,
          saleEventPhone: saleEventSlot.saleEventPhone,
          saleEventName: saleEventSlot.saleEventName
        },
        nzFooter: null,
        nzMaskClosable: false,
      });
    } else {

      await this.apiProxySvc.makeRequestErrorFunction<PRPVSaleEventFull>(() => this.bookSvc.getSaleEventBySaleEventId(this.tsvc.currentLanguage.value, {
        saleEventId: saleEventSlot.saleEventId
      }), true, "internal-loader", 500, (res: PurpleApiMakeRequestResponse<PRPVSaleEventFull>) => {

      }, (res: PurpleApiMakeRequestResponse<PRPVSaleEventFull>) => {
        this.modalSvc.create<InviteFriendsModalComponent, { book?: UserNextSaleEventBookingItem | undefined, saleEvent: PRPVSaleEventFull }>({
          nzContent: InviteFriendsModalComponent,
          nzTitle: undefined,
          nzWidth: '1000px',
          nzCloseIcon: undefined,
          nzClosable: false,
          nzCentered: true,
          nzClassName: "invite-friends-modal",
          nzData: {
            book: saleEventSlot,
            saleEvent: res.data!
          },
          nzFooter: null,
          nzMaskClosable: false,
        });
      })


    }
  }

  showInstructionsModal() {
    this.modalSvc.create({
      nzContent: InstructionsModalComponent,
      nzTitle: this.tranPipe.transform('instructions_modal_title', 'Informazioni Utili', []),
      nzWidth: '1000px',
      nzClosable: true,
      nzFooter: null,
      nzMaskClosable: false
    });
  }

  showInstructionsDrawer() {
    this.layoutSvc.qrCodeOpen.next(true)
    this.drawerSvc.create<InstructionsDrawerComponent, { value: string }, string>({
      nzTitle: this.tranPipe.transform('instructions_modal_title', 'Informazioni Utili', []),
      nzContent: InstructionsDrawerComponent,
      nzClosable: true,
      nzWrapClassName: "qr-code",
      nzFooter: undefined,
      nzPlacement: 'bottom'
    }).afterClose.subscribe(() => {
      this.layoutSvc.qrCodeOpen.next(false)
    })
  }

  unbook(saleEventSlot: UserNextSaleEventBookingItem, isMobile: boolean) {
    this.modal.create(
      {
        nzTitle: isMobile ? this.tranPipe.transform("modal_unbook_slot_title_mobile", "Cancella prenotazione") : this.tranPipe.transform("modal_unbook_slot_title", "Vuoi davvero cancellare la prenotazione??"),
        nzContent: this.tranPipe.transform("modal_unbook_slot_subtitle", "Sei sicuro di voler cancellare la prenotazione \"<b>{2}</b>\" <b>{0} - {1}</b>",
          [this.datepipe.transform(saleEventSlot.slot.slotStartDate, 'shortTime') ?? "", this.datepipe.transform(saleEventSlot.slot.slotEndDate, 'shortTime') ?? "", saleEventSlot.saleEventName]),
        nzWidth: isMobile ? '80%' : '600px',
        nzClassName: 'ps-modal',
        nzCentered: isMobile ? true : false,
        nzClosable: false,
        nzMaskClosable: false,
        nzOkText: this.tranPipe.transform("modal_unbook_slot_delete_button", "Elimina"),
        nzOnOk: async () => {
          await this.apiProxySvc.makeRequestErrorFunction<void>(() => this.bookSvc.unBookAllNextSlot(this.tsvc.currentLanguage.value, {
            saleEventId: saleEventSlot.saleEventId,
            host: window.location.hostname
          }), false, "internal-loader", 500, (res: PurpleApiMakeRequestResponse<void>) => {
            this.psNotSvc.showMotSimpleNotification(
              this.tranPipe.transform('error_message_default_title', 'Qualcosa è andato storto', []),
              res.message,
              this.tranPipe.transform('error_message_default_button', 'Ok', []),
              undefined, 'modal', true, undefined, undefined, false, false, false, false)
          }, async (res: PurpleApiMakeRequestResponse<void>) => {
            this.mesSvc.success(this.tranPipe.transform("message_unbook_ok_response", "Prenotazione cancellata com successo"), {
              nzDuration: environment.MESSAGE_DURATION
            });
            await this.getSaleEventBookings();
          })
        }
      }
    )
  }

}
