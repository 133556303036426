import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzModalService, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { PurpleApiMakeRequestResponse, PurpleApiProxyService, PurpleTranslationPipe, purpleFormFieldsGroup } from 'purple-lib';
import { PurpleSalesNotificationService } from 'src/app/components/purple-sales-notification.service';
import { AuthenticationService } from 'src/core-modules/authentication/authentication.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { UsersService } from 'src/core-modules/sdk/api';

@Component({
  selector: 'app-verify-account-email-modal',
  templateUrl: './verify-account-email-modal.component.html',
  styleUrls: ['../verify-account-modals.scss','./verify-account-email-modal.component.scss']
})
export class VerifyAccountEmailModalComponent implements OnInit {

  constructor(@Inject(NZ_MODAL_DATA) public translations: EditEmailTranslations, private fb: FormBuilder, private userSvc: UsersService, private tsvc: AppTranslationService,
  private tranPipe: PurpleTranslationPipe, private apiProxySvc: PurpleApiProxyService, private psNotSvc: PurpleSalesNotificationService, private modal: NzModalService,
  private authSvc: AuthenticationService) { }

  validateForm: FormGroup = this.fb.group({});
  purpleFormFieldGroups: purpleFormFieldsGroup[] | undefined;
  isLoading: boolean = false;

  ngOnInit() {
    this.purpleFormFieldGroups = [
      {
        fieldGroupNumber: 1,
        fielGroupBootstrapColumn: 12,
        fieldGroupPaddingLeft: false,
        fieldGroupPaddingRight: false,
        formFieldFormGroup: this.validateForm,
        formFields: [
          {
            fieldType: 'input-text',
            fieldControlName: 'email',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_user_email', 'Email', []),
            fieldPlaceholder: this.tranPipe.transform('edit_user_email_placeholder', 'Indirizzo Email', []),
            fieldValue: this.authSvc.currentUser?.user?.email ?? undefined,
            fieldIsRequired: true,
            fieldCustomValidators: [Validators.email],
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: false
          }
        ]
      }
    ]
  }

  async verifyEmail(){
    this.isLoading = true;
    for (const i in this.validateForm.controls) {
      if (this.validateForm.controls.hasOwnProperty(i)) {
        this.validateForm.controls[i].markAsDirty();
        this.validateForm.controls[i].updateValueAndValidity();
      }
    }

    if (this.validateForm.valid) {
      await this.apiProxySvc.makeRequestErrorFunction<void>(() => this.userSvc.requestVerifyEmail(this.tsvc.currentLanguage.value, {
        email: this.validateForm.controls['email'].value,
        clientHost: window.location.hostname
      }), false, "internal-loader", 500, (res: PurpleApiMakeRequestResponse<void>) => {
        this.psNotSvc.showMotSimpleNotification(
          this.tranPipe.transform('request_change_email_error_title', 'Qualcosa è andato storto', []),
          res.message,
          this.tranPipe.transform('request_change_email_error_button', 'Riprova', []),
          undefined, 'modal', true, undefined, undefined, false, false, false, false)
      }, (res: PurpleApiMakeRequestResponse<void>) => {
        this.psNotSvc.showMotSimpleNotification(
          this.tranPipe.transform('request_change_email_success_title', 'Richiesta inviata correttamente', []),
          this.tranPipe.transform('request_change_email_success_subtitle', 'Richiesta inviata correttamente controlla la posta e clicca sul link contenuto nella eamil', []),
          this.tranPipe.transform('request_change_email_success_button', 'Ok', []),
          ()=>{
            this.modal.closeAll()
          }, 'modal', true, undefined, undefined, false, false, false, false)
      })
    }
    this.isLoading = false;
  }

}


export interface EditEmailTranslations{
  title: {
    key: string,
    value: string
  },
  subtitle: {
    key: string,
    value: string
  },
  button: {
    key: string,
    value: string
  }
}