import { Component, Inject, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { SaleEventService } from 'src/app/sale-event-service.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BookingService, PolicyBookCheck, PRPVPolicyFull, RedeemReferralUserFriends, SaleEventBookedSlot, SaleEventDetailSlotItem, StringPurpleApiResponse, UserExtendedReferral } from 'src/core-modules/sdk/api';
import { AuthenticationService } from 'src/core-modules/authentication/authentication.service';
import { SendEmailInviteModalComponent } from '../send-email-invite-modal/send-email-invite-modal.component';
import { PrivateInviteFriendsModalComponent } from '../private-invite-friends-modal/private-invite-friends-modal.component';
import { Guid, PurpleApiMakeRequestResponse, PurpleApiProxyService, PurpleTranslationPipe } from 'purple-lib';
import { PurpleSalesNotificationService } from 'src/app/components/purple-sales-notification.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-book-slot-modal',
  templateUrl: './book-slot-modal.component.html',
  styleUrls: ['./book-slot-modal.component.scss']
})
export class BookSlotModalComponent implements OnInit {

  constructor(@Inject(NZ_MODAL_DATA) public data: { slot: SaleEventDetailSlotItem | undefined, policies: PRPVPolicyFull[], bookedSlot: SaleEventBookedSlot | undefined, saleEventName: string | undefined, canInvite?: boolean }, public authSvc: AuthenticationService, public seSvc: SaleEventService, public modalRef: NzModalRef, private mesSvc: NzMessageService,
    private bookSvc: BookingService, public tsvc: AppTranslationService, private tranPipe: PurpleTranslationPipe, private modal: NzModalService, private psNotSvc: PurpleSalesNotificationService, private apiProxySvc: PurpleApiProxyService) { }

  empty: string = Guid.empty();
  handicap: boolean = false;
  step: number | undefined = 1;
  loadEnd: boolean = false;
  saleEventSlotBookingId: string | undefined = undefined;
  steps: Map<number, stepItem> = new Map<number, stepItem>();
  newBookingId: string | undefined;
  freeRefCodeNr: number = 0;
  referralFriends: UserExtendedReferral[] = [];
  checkPolicies: string[] = [];
  basePath: string = localStorage.getItem("BasePath")!;
  bookingComplete: boolean = false;

  ngOnInit() {
    if (this.data.bookedSlot && !this.data.bookedSlot.pastSlot) {
      this.saleEventSlotBookingId = this.data.bookedSlot.saleEventSlotBookingId
    }

    this.checkAllPolicies();

    this.steps = new Map<number, stepItem>([
      [1,
        {
          nextButtonText: this.saleEventSlotBookingId == undefined ?
            this.tranPipe.transform('book_slot_modal_book_step_1', 'Prenota', []) :
            this.tranPipe.transform('book_slot_modal_edit_step_1', 'Cambia Prenotazione', []),
          nextButtonTranslationKey: this.saleEventSlotBookingId == undefined ?
            'book_slot_modal_book_step_1' :
            'book_slot_modal_edit_step_1',
          prevButtonText: this.tranPipe.transform('book_slot_modal_back_step_1', 'Indietro', []),
          prevButtonTranslationKey: 'book_slot_modal_back_step_1',
          titleText: this.tranPipe.transform('book_slot_modal_title_step_1', 'Verifica i Dati della tua Prenotazione', []),
          titleTranslationKey: 'book_slot_modal_title_step_1'
        }
      ],
      [2,
        {
          nextButtonText: this.saleEventSlotBookingId == undefined ?
            this.tranPipe.transform('book_slot_modal_book_step_2', 'Prenota', []) :
            this.tranPipe.transform('book_slot_modal_edit_step_2', 'Cambia Prenotazione', []),
          nextButtonTranslationKey: 'book_slot_modal_next_step_2',
          prevButtonText: this.tranPipe.transform('book_slot_modal_back_step_2', 'Indietro', []),
          prevButtonTranslationKey: 'book_slot_modal_back_step_2',
          titleText: this.tranPipe.transform('book_slot_modal_title_step_2', 'Prendi visione delle policy', []),
          titleTranslationKey: 'book_slot_modal_title_step_2'
        }
      ],
      [3,
        {
          nextButtonText: this.tranPipe.transform('book_slot_modal_next_step_3', 'Avanti', []),
          nextButtonTranslationKey: 'book_slot_modal_next_step_3',
          prevButtonText: this.tranPipe.transform('book_slot_modal_back_step_3', 'Indietro', []),
          prevButtonTranslationKey: 'book_slot_modal_back_step_3',
          titleText: this.tranPipe.transform('book_slot_modal_title_step_3', 'Appuntamento Confermato', []),
          titleTranslationKey: 'book_slot_modal_title_step_3'
        }
      ],
      [4,
        {
          nextButtonText: this.tranPipe.transform('book_slot_modal_next_step_4', 'Prenota', []),
          nextButtonTranslationKey: 'book_slot_modal_next_step_4',
          prevButtonText: this.tranPipe.transform('book_slot_modal_back_step_4', 'Indietro', []),
          prevButtonTranslationKey: 'book_slot_modal_back_step_4',
          titleText: this.tranPipe.transform('book_slot_modal_title_step_4', 'Condividi Appuntamento', []),
          titleTranslationKey: 'book_slot_modal_title_step_4 '
        }
      ]
    ]);
    this.setLoad(EffectType.up)
  }

  changePolicyCheck(checked: boolean, policyId: string) {
    if (checked) {
      this.checkPolicies.push(policyId);
    } else {
      const idx = this.checkPolicies.indexOf(policyId);
      this.checkPolicies.splice(idx, 1);
    }
  }

  checkAllPolicies(): boolean {
    if (this.data.policies.length == 0) {
      return true;
    }

    const mandatoryPolicies = this.data.policies.filter(f => f.isMandatoryPolicy);

    var check = true;
    mandatoryPolicies.forEach((mp: PRPVPolicyFull) => {
      const idx = this.checkPolicies.indexOf(mp.policyId);

      if (idx == -1) {
        check = false;
      }
    });

    return check;
  }

  closeModal() {
    this.modalRef.close(this.bookingComplete);
  }

  nextStep() {

    setTimeout(async () => {
      if (this.step == 1) {
        if (this.data.policies.length > 0) {
          this.step = 2;
          this.setLoad(EffectType.up);
        } else {
          await this.bookSlot();
        }
      } else if (this.step == 2) {
        await this.bookSlot();
      }
      else if (this.step == 3) {

        if (this.seSvc.currentSaleEvent.value?.isPrivateInviteCounter ?? false) {
          this.modalRef.close();
          this.modal.create<PrivateInviteFriendsModalComponent, { saleEventId: string, saleEventName: string, saleEventPhone: string, saleEventEmail: string }>({
            nzContent: PrivateInviteFriendsModalComponent,
            nzTitle: undefined,
            nzWidth: '1000px',
            nzCloseIcon: undefined,
            nzClosable: false,
            nzCentered: true,
            nzClassName: "private-invite-friends-modal",
            nzData: {
              saleEventId: this.seSvc.currentSaleEvent.value!.saleEventId,
              saleEventEmail: this.seSvc.currentSaleEvent.value!.saleEventEmail,
              saleEventPhone: this.seSvc.currentSaleEvent.value!.saleEventPhone,
              saleEventName: this.seSvc.currentSaleEvent.value!.saleEventName
            },
            nzFooter: null,
            nzMaskClosable: false,
          });

        } else {
          this.setLoad(EffectType.down);

          await this.apiProxySvc.makeRequestErrorFunction<RedeemReferralUserFriends>(() => this.bookSvc.getUserFreeSaleEventReferral(this.tsvc.currentLanguage.value, {
            saleEventId: this.seSvc.currentSaleEvent.value!.saleEventId!,
            roleId: this.data.slot!.roleId!
          }), false, "internal-loader", 500, (res: PurpleApiMakeRequestResponse<RedeemReferralUserFriends>) => {
            this.freeRefCodeNr = 0
          }, (res: PurpleApiMakeRequestResponse<RedeemReferralUserFriends>) => {
            this.freeRefCodeNr = res.data?.freeReferralsNr ?? 0;
            this.referralFriends = res.data?.redeemReferralFriends ?? []
          })

          this.step = undefined;
          this.step = 4;
          this.setLoad(EffectType.up);
        }
      }

    }, 200);
  }

  showInviteButton() {
    if ((!this.seSvc.currentSaleEvent.value?.isPrivateInviteCounter) ?? false) {
      return true;
    }

    if ((this.seSvc.currentSaleEvent.value?.isPrivateInviteCounter ?? false) && this.data.canInvite) {
      return true;
    }

    return false;
  }


  checkPhoneNumber(): boolean {
    return !String.prototype.isNullOrEmpty(this.authSvc.currentUser?.user?.phone?.replace(" ", ""))
  }

  async bookSlot() {
    if (this.checkAllPolicies()) {
      this.setLoad(EffectType.down);
      this.step = undefined;

      const policies: PolicyBookCheck[] = this.data.policies.map(m => {
        return {
          policyId: m.policyId,
          checked: this.checkPolicies.indexOf(m.policyId) != -1
        }
      });

      await this.apiProxySvc.makeRequestErrorFunction<string>(() => this.bookSvc.bookSlot(this.tsvc.currentLanguage.value, {
        needAssistance: this.handicap,
        saleEventId: this.seSvc.currentSaleEvent.value!.saleEventId!,
        slotStartDate: this.data.slot!.slotStartDate!,
        userRoleId: this.data.slot!.roleId!,
        clientHost: window.location.hostname,
        policies: policies
      }), false, "internal-loader", 500, (res: PurpleApiMakeRequestResponse<string>) => {
        this.psNotSvc.showMotSimpleNotification(
          this.tranPipe.transform('error_message_default_title', 'Qualcosa è andato storto', []),
          res.message,
          this.tranPipe.transform('error_message_default_button', 'Ok', []),
          () => {
            this.newBookingId = undefined;
            this.modal.closeAll();
          }, 'modal', true, undefined, undefined, false, false, false, false)
      }, (res: PurpleApiMakeRequestResponse<string>) => {
        this.mesSvc.success(this.tranPipe.transform("message_book_slot_success_response", "Congratulazioni! Prenotazione effettuata con successo"), {
          nzDuration: 4000
        })
        this.step = 3;
        this.newBookingId = res.data;
        this.bookingComplete = true;
        this.setLoad(EffectType.up);
      })
    } else {
      this.psNotSvc.showMotSimpleNotification(
        this.tranPipe.transform('error_message_policy_check_modal_title', 'Consenso policy obbligatorio!', []),
        this.tranPipe.transform('error_message_policy_check_modal_subtitle', 'Il consenso alle policy è obbligatorio per effettuare la prenotazione', []),
        this.tranPipe.transform('error_message_default_button', 'Ok', []),
        undefined, 'modal', true, undefined, undefined, false, false, false, false);
    }
  }

  prevStep() {
    this.setLoad(EffectType.down);

    setTimeout(() => {
      if (this.step == 2) {
        this.step = undefined;
        this.step = 1;
        this.setLoad(EffectType.up);
      }

      if (this.step == 4) {
        this.step = undefined;
        this.step = 3;
        this.setLoad(EffectType.up);
      }

    }, 200);
  }

  async checkUserreferral(): Promise<StringPurpleApiResponse> {
    const resp = await lastValueFrom(
      this.bookSvc.addSaleEventUserRoleReferral(this.tsvc.currentLanguage.value, {
        roleId: this.data.slot?.roleId!,
        saleEventId: this.seSvc.currentSaleEvent.value?.saleEventId!
      }));

    return resp ?? undefined
  }

  async sendEmail() {
    var referralId = await this.checkUserreferral();
    if (referralId.data != undefined) {
      this.modal.create<SendEmailInviteModalComponent, { roleId: string, referralId: string, slotStartDate: string, isMobile: boolean, saleEventId?: string | undefined }>({
        nzTitle: this.tranPipe.transform("modal_send_user_email_invite_title", "Invita Amico", []),
        nzContent: SendEmailInviteModalComponent,
        nzData: {
          roleId: this.data.slot!.roleId,
          referralId: referralId.data,
          slotStartDate: this.data.slot!.slotStartDate,
          isMobile: false
        },
        nzWidth: '600px',
        nzClassName: 'ps-modal',
        nzFooter: null
      });
    } else {
      this.psNotSvc.showMotSimpleNotification(
        this.tranPipe.transform('error_message_policy_check_modal_title', 'Consenso policy obbligatorio!', []),
        this.tranPipe.transform('error_message_policy_check_modal_subtitle', 'Il consenso alle policy è obbligatorio per effettuare la prenotazione', []),
        this.tranPipe.transform('error_message_default_button', 'Ok', []),
        undefined, 'modal', true, undefined, undefined, false, false, false, false);

    }
  }

  async sendWhatsapp() {
    var referralId = await this.checkUserreferral();
    if (referralId.data != undefined) {
      var textToSend = this.tranPipe.transform('send_user_whatsapp_invite_text', '{0} ti ha inviato alla private sale \"{1}\", clicca sul seguente link per prenotarti:{2}',
        [this.authSvc.currentUser!.user!.firstName + " " + this.authSvc.currentUser!.user!.lastName, this.seSvc.currentSaleEvent.value?.saleEventName ?? "",
        " https://" + window.location.hostname + "/" + this.tsvc.currentLanguage.value + "/referral?id=" + referralId.data]);

      window.open('https://wa.me/?text=' + textToSend);
    } else {
      this.psNotSvc.showMotSimpleNotification(
        this.tranPipe.transform('error_message_policy_check_modal_title', 'Consenso policy obbligatorio!', []),
        this.tranPipe.transform('error_message_policy_check_modal_subtitle', 'Il consenso alle policy è obbligatorio per effettuare la prenotazione', []),
        this.tranPipe.transform('error_message_default_button', 'Ok', []),
        undefined, 'modal', true, undefined, undefined, false, false, false, false);

    }
  }

  calendarSrc(): string {
    return `${this.basePath}/${this.tsvc.currentLanguage.value}/booking/downloadCalendarBook?SaleEventSlotBookingId=${this.newBookingId}`;
  }

  pdfSrc(): string {
    return `${this.basePath}/${this.tsvc.currentLanguage.value}/booking/downloadPdfBooks?SaleEventSlotBookingIds=${this.newBookingId}`
  }

  setLoad(type: EffectType) {
    if (type == EffectType.up) {
      this.loadEnd = false;
      setTimeout(() => {
        this.loadEnd = true;
      }, 200);
    }

    if (type == EffectType.down) {
      this.loadEnd = false;
    }

  }
}

export enum EffectType {
  up,
  down
}


export interface stepItem {
  nextButtonText: string,
  nextButtonTranslationKey: string
  prevButtonText: string,
  prevButtonTranslationKey: string,
  titleText: string,
  titleTranslationKey: string
}
