<div class="book-slot-modal-container ps-d-flex ps-algin-items-start ps-w-100">
  <div
    class="ps-col-4 book-slot-modal-info-container ps-d-flex ps-flex-wrap ps-justify-content-center ps-align-items-start">
    <div class="book-slot-modal-stepper-container ps-d-flex ps-align-items-center ps-justify-content-center ps-w-100">
      <div class="book-slot-modal-step-item"></div>
      <div class="book-slot-modal-step-item"></div>
      <div class="book-slot-modal-step-item active"></div>
    </div>

    <div class="ps-d-flex ps-flex-wrap ps-justify-content-center">
      <img class="book-slot-modal-info-sale-event-logo {{loadEnd?'load-end':''}}" contentId="{{data.saleEventId}}-logo"
        contentTypeId="sale-event-logo" purpleEditableImage />
      <h3 class="ps-w-100 book-slot-modal-info-order-detail-title {{loadEnd?'load-end':''}}">
        <!-- {{ this.step?this.steps.get(this.step)?.leftBannerTitle:""}} -->
        {{data.saleEventName}}
      </h3>
      <!-- <p class="book-slot-modal-info-order-detail-subtitle {{loadEnd?'load-end':''}} ps-m-0">
        {{ this.step?this.steps.get(this.step)?.leftBannerSubTitle:""}}
      </p> -->
    </div>
    <div class="ps-d-flex ps-flex-wrap ps-justify-content-center ps-mt-auto">
      <h4 class="ps-w-100 book-slot-modal-info-problem" purpleEditableText tranKey='verify_order_have_problem'>
        {{ 'verify_order_have_problem' | translation: 'Hai problemi?': []}}
      </h4>
      <p class="book-slot-modal-info-problem-subtitle ps-m-0" purpleEditableText tranKey='verify_order_have_problem_help_info'>
        {{ 'verify_order_have_problem_help_info' | translation: "Chiama {0} o contattaci all'indirizzo email {1} per aiutarti": [data.saleEventPhone, data.saleEventEmail]}}
      </p>
    </div>
  </div>
  <div class="ps-col-8 {{referralFriends==undefined?'ps-align-items-center ps-justify-content-center':'ps-align-items-start'}} ps-p-0 ps-w-100 ps-d-flex ps-flex-wrap book-slot-modal-detail-container">

    <div class="ps-d-flex ps-justify-content-center ps-align-items-center book-slot-modal-detail-container-loader" 
    *ngIf="referralFriends== undefined || isLoading">
      <i nz-icon [nzType]="'loading'"></i>
    </div>

    <div class="ps-w-100 ps-d-flex ps-flex-wrap book-slot-modal-detail-container-step">
      <div class="ps-w-100 ps-d-flex ps-align-items-center book-slot-modal-detail-header">
        <h2 purpleEditableText tranKey='book_slot_modal_share_title' class="book-slot-modal-detail-header-title ps-mr-auto {{loadEnd?'load-end':''}}">
          {{ 'book_slot_modal_share_title' | translation: 'Condividi Appuntamento': []}}
        </h2>
        <i (click)="closeModal()" class="ps-cursor-pointer" nz-icon nzType="close" nzTheme="outline"></i>
      </div>

      <div class="ps-d-flex ps-flex-wrap ps-w-100 book-slot-modal-detail-content {{loadEnd?'load-end':''}}" *ngIf="referralFriends!= undefined">
        <p class="book-slot-modal-detail-content-intro ps-m-0">
          <span purpleEditableText tranKey='private_invite_friends_intro_limited' *ngIf="freeRefCodeNr > 0"
            [innerHtml]="'private_invite_friends_intro_limited' | translation: 'Hai a disposizione \<b>{0}\</b> inviti': [freeRefCodeNr.toString()]">
          </span>
          <span purpleEditableText tranKey='private_invite_friends_intro_free' *ngIf="freeRefCodeNr == -1"
            [innerHtml]="'private_invite_friends_intro_free' | translation: 'Hai a disposizione inviti \<b>illimitati\</b>': []">
          </span>
        </p>


        <nz-tabset [(nzSelectedIndex)]="currentTab" nzTabPosition="top" class="ps-w-100">
          <nz-tab [nzTitle]="'private_invite_friends_form_tab_invite' | translation: 'Invita': []">

            <ng-container *ngIf="freeRefCodeNr > 0 || freeRefCodeNr == -1; else noFreeRef">

              <div class="private-invite-form-box ps-d-flex ">
                <form nz-form [formGroup]="validateForm"
                  class="private-invite-form {{isFormEnable?'':'disable'}} ps-d-flex ps-flex-wrap"
                  (ngSubmit)="submitForm()">

                  <nz-form-item class="ps-col-6 ps-pl-0">
                    <nz-form-control [nzErrorTip]="errorTplName">
                      <ng-template #errorTplName let-control>
                        <ng-container purpleEditableText tranKey='required_input_error' *ngIf="control.hasError('required')">
                          {{ 'required_input_error' | translation: 'Campo obbligatorio!': []}}
                        </ng-container>
                        <ng-container purpleEditableText tranKey='input_error_firstname_not_valid' *ngIf="control.hasError('format')">
                          {{ 'input_error_firstname_not_valid' 
                          | translation: 'Formato nome non valido': []}}
                        </ng-container>
                      </ng-template>     
                      <div class="ps-w-100 ps-d-flex ps-flex-wrap">
                        <p class="form-item-title ps-w-100" purpleEditableText tranKey='invite_friend_form_firstname_title'>
                          {{ 'invite_friend_form_firstname_title' | translation:'Nome': []}}<sup>*</sup>:</p>
                        <nz-input-group nzPrefixIcon="user">
                          <input [disabled]="!isFormEnable" purpleEditableText tranKey='invite_friend_form_firstname'
                            type="text" nz-input formControlName="firstname"
                            [placeholder]="'invite_friend_form_firstname' | translation: 'Nome': []" />
                        </nz-input-group>
                      </div>
                    </nz-form-control>
                  </nz-form-item>

                  <nz-form-item class="ps-col-6 ps-pr-0">
                    <nz-form-control [nzErrorTip]="errorTplLastname">
                      <ng-template #errorTplLastname let-control>
                        <ng-container purpleEditableText tranKey='required_input_error' *ngIf="control.hasError('required')">
                          {{ 'required_input_error' | translation: 'Campo obbligatorio!': []}}
                        </ng-container>
                        <ng-container purpleEditableText tranKey='input_error_lastname_not_valid' *ngIf="control.hasError('format')">
                          {{ 'input_error_lastname_not_valid' 
                          | translation: 'Formato cognome non valido': []}}
                        </ng-container>
                      </ng-template>
                      <div class="ps-w-100 ps-d-flex ps-flex-wrap">
                        <p class="form-item-title ps-w-100" purpleEditableText tranKey='invite_friend_form_lastname_title'>
                          {{ 'invite_friend_form_lastname_title' | translation: 'Cognome': []}}<sup>*</sup>:</p>
                        <nz-input-group nzPrefixIcon="user">
                          <input [disabled]="!isFormEnable" purpleEditableText tranKey='invite_friend_form_lastname'
                            type="text" nz-input formControlName="lastname"
                            [placeholder]="'invite_friend_form_lastname' | translation: 'Cognome': []" />
                        </nz-input-group>
                      </div>
                    </nz-form-control>
                  </nz-form-item>

                  <nz-form-item class="ps-col-12 ps-px-0">
                    <nz-form-control [nzErrorTip]="errorTplEmail">
                      <p class="form-item-title ps-w-100" purpleEditableText tranKey='invite_friend_form_email_title'>
                        {{ 'invite_friend_form_email_title' | translation: 'Email': []}}<sup>*</sup>:</p>
                      <nz-input-group nzPrefixIcon="mail">
                        <input purpleEditableText tranKey='register_light_form_email' type="email" nz-input
                          formControlName="email"
                          [placeholder]="'register_light_form_email' | translation: 'Email': []" />
                      </nz-input-group>
                      <ng-template #errorTplEmail let-control>
                        <ng-container purpleEditableText tranKey='required_input_error' *ngIf="control.hasError('required')">
                          {{ 'required_input_error' | translation: 'Campo obbligatorio!': []}}
                        </ng-container>
                        <ng-container purpleEditableText tranKey='input_error_email_invalid'
                          *ngIf="control.hasError('format')">
                          {{ 'input_error_email_invalid' | translation: 'Formato email non valido':
                          []}}
                        </ng-container>
                      </ng-template>
                    </nz-form-control>
                  </nz-form-item>
                  
                  <!-- <div class="ps-w-100 ps-d-flex ps-flex-wrap ps-align-items-center ps-mb-3">
                    <p class="show-book-toggle-label ps-m-0 ps-pb-0">{{ 'invite_friend_form_show_book' | translation: 'Prenota Slot:': []}}</p>
                    <nz-switch [ngModelOptions]="{standalone: true}" (ngModelChange)="toggleShowBook($event)" [(ngModel)]="showBookOptions"></nz-switch>
                  </div>

                  <ng-container *ngIf="showBookOptions">
                    <nz-form-item class="ps-col-6 ps-pl-0">
                      <nz-form-control>
                        <div class="ps-w-100 ps-d-flex ps-flex-wrap">
                          <p class="form-item-title ps-w-100" purpleEditableText tranKey='invite_friend_form_day_title'>
                            {{ 'invite_friend_form_day_title' | translation:'Giorno': []}}:</p>
                          <nz-input-group nzPrefixIcon="calendar" class="select">
                            <nz-select (ngModelChange)="selectDay($event)" class="ps-w-100" purpleEditableText tranKey='invite_friend_form_day'
                              [nzPlaceHolder]="'invite_friend_form_day' | translation: 'Giorno Prenotazione': []"
                              [nzShowArrow]="true" formControlName="day">
  
                              <nz-option *ngFor="let item of days??[]" [nzLabel]="item.name"
                                [nzValue]="item.id"></nz-option>
                            </nz-select>
                          </nz-input-group>
                        </div>
                      </nz-form-control>
                    </nz-form-item>
  
                    <nz-form-item class="ps-col-6 ps-pr-0">
                      <nz-form-control [nzErrorTip]="'required_input_error' | translation: 'Campo obbligatorio!': []">
                        <div class="ps-w-100 ps-d-flex ps-flex-wrap">
                          <p class="form-item-title ps-w-100" purpleEditableText tranKey='invite_friend_form_slot_title'>
                            {{ 'invite_friend_form_slot_title' | translation: 'Fascia Oraria': []}}:</p>
                          <nz-input-group nzPrefixIcon="field-time" class="select {{isDisabled('slot')}}">
                            <nz-select class="ps-w-100" purpleEditableText tranKey='invite_friend_form_slot'
                              [nzPlaceHolder]="'invite_friend_form_slot' | translation: 'Fascia Oraria': []"
                              [nzShowArrow]="true" formControlName="slot">
  
                              <nz-option *ngFor="let item of slots??[]" [nzLabel]="item.name"
                                [nzValue]="item.id"></nz-option>
                            </nz-select>
                          </nz-input-group>
                        </div>
                      </nz-form-control>
                    </nz-form-item>
                  </ng-container> -->
                 

                  <button purpleEditableText tranKey='invite_friend_form_button' nz-button
                    class="submit-button purple-button" nzType="primary">
                    {{ 'invite_friend_form_button' | translation: 'Invita': []}}</button>
                </form>
              </div>
            </ng-container>
    
            <ng-template #noFreeRef>
              <p class="ps-w-100 ps-pt-3" purpleEditableText tranKey='private_invite_friends_intro_finish' *ngIf="freeRefCodeNr == 0">
                {{ 'private_invite_friends_intro_finish' | translation: 'Numero massimo inviti raggiunto'}}
              </p>
            </ng-template>
          </nz-tab>



          <nz-tab [nzTitle]="'private_invite_friends_form_tab_list' | translation: 'Invitati': []">
            <ng-container
            *ngIf="(freeRefCodeNr > 0 || freeRefCodeNr == -1) || (freeRefCodeNr == 0 && referralFriends.length > 0); else noFriends">
            <div class="private-invite-invited-friends ps-d-flex ps-w-100">
              
              <div *ngFor="let user of referralFriends"
                class="ps-w-100 ps-d-flex detail-list-item">
                <div class="ps-d-flex ps-align-items-center ps-pl-0 ps-w-100">

                  <div class="line-status-dot">
                    <i class="fsp-20-0" nz-icon nzType="user" nzTheme="outline"></i>
                  </div>

                  <div class="ps-d-flex ps-flex-wrap ps-detail-list-item-info-container">
                    <h2 class="detail-list-item-title ps-w-100">{{user.firstName}} {{user.lastName}}</h2>
                    <p class="detail-list-item-subtitle ps-w-100">{{user.email}}</p>
                  </div>

                  <nz-tag class="ps-ml-auto" [nzColor]="getInviteTagColor(user.isPending)">{{getInviteText(user.isPending)}}</nz-tag>

                  <span class="edit-activity-button ps-px-0 ps-cursor-pointer" (click)="removeInvite(user)">
                    <i nz-icon class="fsp-20-0" nzType="delete" ></i>
                  </span>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-template #noFriends>
            <p purpleEditableText tranKey='book_slot_share_share_friend_list_no_friends' class="ps-w-100 ps-pt-3">
              {{ 'book_slot_share_share_friend_list_no_friends' | translation: 'Ancora nessun amico ha accettato il tuo invito': []}}</p>
          </ng-template>
          </nz-tab>
        </nz-tabset>

      </div>
    </div>

    <div class="ps-w-100 ps-d-flex ps-mt-auto ps-justify-content-end ps-align-items-center book-slot-modal-detail-button-container" >
      
    
        <button (click)="closeModal()" class="book-slot-modal-detail-button sw-border-radius" nzType="primary" nz-button purpleEditableText tranKey='book_slot_info_close_button'>
          {{ 'book_slot_info_close_button' | translation: 'Chiudi': []}}
        </button>

    </div>
  </div>

</div>
