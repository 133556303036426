import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PurpleSalesPageTitleComponent } from './purple-sales-page-title/purple-sales-page-title.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { PurpleEditTextModule } from 'purple-lib';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { PurpleSalesButtonComponent } from './purple-sales-button/purple-sales-button.component';
import { PurpleSalesButtonIconComponent } from './purple-sales-button/purple-sales-button-icon/purple-sales-button-icon.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    PurpleEditTextModule,
    NzButtonModule,
    NzIconModule
  ],
  declarations: [PurpleSalesPageTitleComponent, PurpleSalesButtonComponent, PurpleSalesButtonIconComponent],
  exports: [PurpleSalesPageTitleComponent, PurpleSalesButtonComponent]
})
export class PurpleSalesComponentsModule { }
