import { Component, OnInit } from '@angular/core';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';

@Component({
  selector: 'app-instructions-drawer',
  templateUrl: './instructions-drawer.component.html',
  styleUrls: ['./instructions-drawer.component.scss']
})
export class InstructionsDrawerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
