<app-purple-api-error-wrapper [pageBody]="pageBody" [enabledErrorPages]="enableErrorPages" [loaderId]="loaderId" [reqConditions]="(tsvc.isInitialized | async)">
</app-purple-api-error-wrapper>

<ng-template #pageBody>
  <app-purple-sales-header></app-purple-sales-header>
  <div class="purple-sales-layout-main-container" (window:resize)="onResize()">
      <div class="purple-sales-layout-main-container-internal">
        <app-account-need-verification-banner></app-account-need-verification-banner>
        <div class="purple-sales-layout-main-container-internal-router">
          <router-outlet></router-outlet>
        </div>
          <app-purple-sales-footer></app-purple-sales-footer>
      </div>
  </div>
  <button *ngIf="authService.canEdit()" (click)="editSvc.toggleEditMode()" class="purple-edit-button" nz-button
      nzType="primary">
      <span class="desktop-edit-button">Edit: {{editSvc.editModeOn.value?'active':'disable'}}</span>
  </button> 
</ng-template> 

<purple-loader [speed]="2" [width]="loaderWidth" [loaderPath]="loaderPath"
  [loaderId]="loaderId"></purple-loader>