import { Component, Input, OnInit, Inject } from '@angular/core';
import { PolicyQuery, UserAttributeValuesItem } from 'src/core-modules/sdk/api';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-show-policy-preview-modal',
  templateUrl: './show-policy-preview-modal.component.html',
  styleUrls: ['./show-policy-preview-modal.component.scss']
})
export class ShowPolicyPreviewModalComponent implements OnInit {

  constructor(@Inject(NZ_MODAL_DATA) public policy: PolicyQuery) { }
  ngOnInit() {

    //console.log("POLICY: ", this.policy)
  }

}
