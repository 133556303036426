import { AfterViewInit, Component, ElementRef, Input, OnInit } from '@angular/core';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { lastValueFrom } from 'rxjs';
import { SaleEventService } from 'src/app/sale-event-service.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { SaleEventDetailSlotItem, SaleEventBookedSlot, BookingService, PurpleApiResponseStatus, PRPVPolicyFull, PolicyBookCheck, UserExtendedReferral, StringPurpleApiResponse } from 'src/core-modules/sdk/api';
import { AuthenticationService } from 'src/core-modules/authentication/authentication.service';
import { environment } from 'src/environments/default/environment';
import { EffectType, stepItem } from '../../modals/book-slot-modal/book-slot-modal.component';
import { SendEmailInviteModalComponent } from '../../modals/send-email-invite-modal/send-email-invite-modal.component';
import { PrivateInviteFriendsDrawerComponent } from '../private-invite-friends-drawer/private-invite-friends-drawer.component';
import { Guid, PurpleApiMakeRequestResponse, PurpleApiProxyService, PurpleTranslationPipe } from 'purple-lib';
import { PurpleSalesNotificationService } from 'src/app/components/purple-sales-notification.service';

@Component({
  selector: 'app-book-slot-drawer',
  templateUrl: './book-slot-drawer.component.html',
  styleUrls: ['./book-slot-drawer.component.scss']
})
export class BookSlotDrawerComponent implements OnInit {

  constructor(public authSvc: AuthenticationService, public seSvc: SaleEventService, private drawerRef: NzDrawerRef,
    private bookSvc: BookingService, public tsvc: AppTranslationService, private mesSvc: NzMessageService, private psNotSvc: PurpleSalesNotificationService,
    private tranPipe: PurpleTranslationPipe, private modal: NzModalService, private drawerSvc: NzDrawerService, private apiProxySvc: PurpleApiProxyService) {
    this.useIubenda = environment.USE_IUBENDA;
  }

  @Input() slot: SaleEventDetailSlotItem | undefined = undefined;
  @Input() bookedSlot: SaleEventBookedSlot | undefined = undefined;
  @Input() policies: PRPVPolicyFull[] = [];
  @Input() canInvite: boolean = false;
  @Input() minHeight!: string;

  empty: string = Guid.empty();
  handicap: boolean = false;
  step: number | undefined = 1;
  loadEnd: boolean = false;
  saleEventSlotBookingId: string | undefined = undefined;
  steps: Map<number, stepItem> = new Map<number, stepItem>();
  newBookingId: string | undefined;
  freeRefCodeNr: number = 0;
  referralFriends: UserExtendedReferral[] = [];
  useIubenda: boolean = false;
  checkPolicies: string[] = [];
  basePath: string = localStorage.getItem("BasePath")!;
  bookingComplete: boolean = false;

  ngOnInit() {
    //console.log(this.authSvc.currentUser)
    //console.log(this.seSvc.currentSaleEvent.value)
    if (this.bookedSlot && !this.bookedSlot.pastSlot) {
      this.saleEventSlotBookingId = this.bookedSlot.saleEventSlotBookingId
    }

    this.checkAllPolicies();

    this.steps = new Map<number, stepItem>([
      [1,
        {
          nextButtonText: this.saleEventSlotBookingId == undefined ?
            this.tranPipe.transform('book_slot_drawer_book_step_1', 'Prenota', []) :
            this.tranPipe.transform('book_slot_drawer_edit_step_1', 'Cambia Prenotazione', []),
          nextButtonTranslationKey: this.saleEventSlotBookingId == undefined ?
            'book_slot_drawer_book_step_1' :
            'book_slot_drawer_edit_step_1',
          prevButtonText: this.tranPipe.transform('book_slot_drawer_back_step_1', 'Indietro', []),
          prevButtonTranslationKey: 'book_slot_drawer_back_step_1',
          titleText: this.tranPipe.transform('book_slot_drawer_title_step_1', 'Dati della Prenotazione', []),
          titleTranslationKey: 'book_slot_drawer_title_step_1'
        }
      ],
      [2,
        {
          nextButtonText: this.saleEventSlotBookingId == undefined ?
            this.tranPipe.transform('book_slot_modal_book_step_2', 'Prenota', []) :
            this.tranPipe.transform('book_slot_modal_edit_step_2', 'Cambia Prenotazione', []),
          nextButtonTranslationKey: 'book_slot_modal_next_step_2',
          prevButtonText: this.tranPipe.transform('book_slot_modal_back_step_2', 'Indietro', []),
          prevButtonTranslationKey: 'book_slot_modal_back_step_2',
          titleText: this.tranPipe.transform('book_slot_modal_title_step_2', 'Prendi visione delle policy', []),
          titleTranslationKey: 'book_slot_modal_title_step_2'
        }
      ],
      [3,
        {
          nextButtonText: this.tranPipe.transform('book_slot_modal_next_step_3', 'Avanti', []),
          nextButtonTranslationKey: 'book_slot_modal_next_step_3',
          prevButtonText: this.tranPipe.transform('book_slot_modal_back_step_3', 'Indietro', []),
          prevButtonTranslationKey: 'book_slot_modal_back_step_3',
          titleText: this.tranPipe.transform('book_slot_modal_title_step_3', 'Appuntamento Confermato', []),
          titleTranslationKey: 'book_slot_modal_title_step_3'
        }
      ],
      [4,
        {
          nextButtonText: this.tranPipe.transform('book_slot_modal_next_step_4', 'Prenota', []),
          nextButtonTranslationKey: 'book_slot_modal_next_step_4',
          prevButtonText: this.tranPipe.transform('book_slot_modal_back_step_4', 'Indietro', []),
          prevButtonTranslationKey: 'book_slot_modal_back_step_4',
          titleText: this.tranPipe.transform('book_slot_modal_title_step_4', 'Condividi Appuntamento', []),
          titleTranslationKey: 'book_slot_modal_title_step_4 '
        }
      ]
    ]);
    this.setLoad(EffectType.up)
  }

  closeDrawer() {
    this.drawerRef.close(this.bookingComplete);
  }

  showInviteButton() {
    if ((!this.seSvc.currentSaleEvent.value?.isPrivateInviteCounter) ?? false) {
      return true;
    }

    if ((this.seSvc.currentSaleEvent.value?.isPrivateInviteCounter ?? false) && this.canInvite) {
      return true;
    }

    return false;
  }


  changePolicyCheck(checked: boolean, policyId: string) {
    if (checked) {
      this.checkPolicies.push(policyId);
    } else {
      const idx = this.checkPolicies.indexOf(policyId);
      this.checkPolicies.splice(idx, 1);
    }
  }

  checkAllPolicies(): boolean {
    if (this.policies.length == 0) {
      return true;
    }

    const mandatoryPolicies = this.policies.filter(f => f.isMandatoryPolicy);

    var check = true;
    mandatoryPolicies.forEach((mp: PRPVPolicyFull) => {
      const idx = this.checkPolicies.indexOf(mp.policyId);

      if (idx == -1) {
        check = false;
      }
    });

    return check;
  }

  checkPhoneNumber() {
    return !String.prototype.isNullOrEmpty(this.authSvc.currentUser?.user?.phone?.replace(" ", ""))
  }

  nextStep() {

    setTimeout(async () => {
      if (this.step == 1) {
        if (this.policies.length > 0) {
          this.step = 2;
          this.setLoad(EffectType.up);
        } else {
          await this.bookSlot();
        }
      } else if (this.step == 2) {
        await this.bookSlot();
      }
      else if (this.step == 3) {

        if (this.seSvc.currentSaleEvent.value?.isPrivateInviteCounter ?? false) {
          this.drawerRef.close(this.bookingComplete);
          this.drawerSvc.create<PrivateInviteFriendsDrawerComponent, { value: string }, string>({
            nzTitle: undefined,
            nzContent: PrivateInviteFriendsDrawerComponent,
            nzClosable: false,
            nzWrapClassName: "private-invite-friends",
            nzFooter: undefined,
            nzPlacement: 'bottom',
            nzContentParams: {
              saleEventId: this.seSvc.currentSaleEvent.value!.saleEventId!
            }
          })

        } else {
          this.setLoad(EffectType.down);
          const resp = await lastValueFrom(
            this.bookSvc.getUserFreeSaleEventReferral(this.tsvc.currentLanguage.value, {
              saleEventId: this.seSvc.currentSaleEvent.value!.saleEventId!,
              roleId: this.slot!.roleId!
            }));

          if (resp.status == PurpleApiResponseStatus.Success) {
            this.freeRefCodeNr = resp.data?.freeReferralsNr ?? 0;
            this.referralFriends = resp.data?.redeemReferralFriends ?? []
          } else {
            this.freeRefCodeNr = 0
          }


          this.step = undefined;
          this.step = 4;
          this.setLoad(EffectType.up);
        }
      }

    }, 200);
  }

  async bookSlot() {
    if (this.checkAllPolicies()) {
      this.setLoad(EffectType.down);
      this.step = undefined;

      const policies: PolicyBookCheck[] = this.policies.map(m => {
        return {
          policyId: m.policyId,
          checked: this.checkPolicies.indexOf(m.policyId) != -1
        }
      });

      await this.apiProxySvc.makeRequestErrorFunction<string>(() => this.bookSvc.bookSlot(this.tsvc.currentLanguage.value, {
        needAssistance: this.handicap,
        saleEventId: this.seSvc.currentSaleEvent.value!.saleEventId!,
        slotStartDate: this.slot!.slotStartDate!,
        userRoleId: this.slot!.roleId!,
        clientHost: window.location.hostname,
        policies: policies
      }), false, "internal-loader", 500, (res: PurpleApiMakeRequestResponse<string>) => {
        this.psNotSvc.showMotSimpleNotification(
          this.tranPipe.transform('error_message_default_title', 'Qualcosa è andato storto', []),
          res.message,
          this.tranPipe.transform('error_message_default_button', 'Ok', []),
          () => {
            this.newBookingId = undefined;
            this.drawerRef.close(this.bookingComplete);
          }, 'modal', true, undefined, undefined, false, false, false, false)
      }, (res: PurpleApiMakeRequestResponse<string>) => {
        this.mesSvc.success(this.tranPipe.transform("message_book_slot_success_response_mobile", "Prenotazione confermata!"), {
          nzDuration: 4000
        })
        this.step = 3;
        this.bookingComplete = true;
        this.newBookingId = res.data;
        this.setLoad(EffectType.up);
      })
    } else {
      this.psNotSvc.showMotSimpleNotification(
        this.tranPipe.transform('error_message_policy_check_modal_title', 'Consenso policy obbligatorio!', []),
        this.tranPipe.transform('error_message_policy_check_modal_subtitle', 'Il consenso alle policy è obbligatorio per effettuare la prenotazione', []),
        this.tranPipe.transform('error_message_default_button', 'Ok', []),
        undefined, 'modal', true, undefined, undefined, false, false, false, false);
    }
  }



  prevStep() {
    this.setLoad(EffectType.down);

    setTimeout(() => {
      if (this.step == 2) {
        this.step = undefined;
        this.step = 1;
        this.setLoad(EffectType.up);
      }

      if (this.step == 4) {
        this.step = undefined;
        this.step = 3;
        this.setLoad(EffectType.up);
      }

    }, 200);
  }

  async checkUserreferral(): Promise<StringPurpleApiResponse> {
    const resp = await lastValueFrom(
      this.bookSvc.addSaleEventUserRoleReferral(this.tsvc.currentLanguage.value, {
        roleId: this.slot?.roleId!,
        saleEventId: this.seSvc.currentSaleEvent.value?.saleEventId!
      }));

    return resp
  }

  async sendEmail() {
    var referralId = await this.checkUserreferral();
    if (referralId.data != undefined) {
      this.modal.create<SendEmailInviteModalComponent, { roleId: string, referralId: string, slotStartDate: string, isMobile: boolean, saleEventId?: string | undefined }>({
        nzTitle: this.tranPipe.transform("modal_send_user_email_invite_title", "Invita Amico", []),
        nzContent: SendEmailInviteModalComponent,
        nzData: {
          roleId: this.slot!.roleId,
          referralId: referralId.data,
          slotStartDate: this.slot!.slotStartDate,
          isMobile: true
        },
        nzWidth: '90%',
        nzClassName: 'ps-modal',
        nzFooter: null,
        nzCentered: true,
        nzMaskClosable: false
      });
    } else {
      this.psNotSvc.showMotSimpleNotification(
        this.tranPipe.transform('error_message_default_title', 'Qualcosa è andato storto', []),
        referralId.message,
        this.tranPipe.transform('error_message_default_button', 'Ok', []),
        undefined, 'modal', true, undefined, undefined, false, false, false, false)

    }
  }

  async genericShare() {
    var referralId = await this.checkUserreferral();
    if (referralId.data != undefined) {
      const shareData = {
        title: this.tranPipe.transform('send_user_whatsapp_invite_title', 'Invito alla private sale \"{0}\"', [this.seSvc.currentSaleEvent.value?.saleEventName ?? ""]),
        text: this.tranPipe.transform('send_user_whatsapp_invite_text', '{0} ti ha inviato alla private sale \"{1}\", clicca sul seguente link per prenotarti:{2}',
          [this.authSvc.currentUser!.user!.firstName + " " + this.authSvc.currentUser!.user!.lastName, this.seSvc.currentSaleEvent.value?.saleEventName ?? "",
          " https://" + window.location.hostname + "/" + this.tsvc.currentLanguage.value + "/referral?id=" + referralId.data]),
        url: "https://" + window.location.hostname + "/" + this.tsvc.currentLanguage.value + "/referral?id=" + referralId.data
      }

      await navigator.share(shareData)
    } else {
      this.psNotSvc.showMotSimpleNotification(
        this.tranPipe.transform('error_message_default_title', 'Qualcosa è andato storto', []),
        referralId.message,
        this.tranPipe.transform('error_message_default_button', 'Ok', []),
        undefined, 'modal', true, undefined, undefined, false, false, false, false)
    }
  }

  calendarSrc(): string {
    return `${this.basePath}/${this.tsvc.currentLanguage.value}/booking/downloadCalendarBook?SaleEventSlotBookingId=${this.newBookingId}`;
  }

  pdfSrc(): string {
    return `${this.basePath}/${this.tsvc.currentLanguage.value}/booking/downloadPdfBook?SaleEventSlotBookingId=${this.newBookingId}`;
  }

  setLoad(type: EffectType) {
    if (type == EffectType.up) {
      this.loadEnd = false;
      setTimeout(() => {
        this.loadEnd = true;
      }, 200);
    }

    if (type == EffectType.down) {
      this.loadEnd = false;
    }

  }

  getFooterLayout() {
    if (this.useIubenda) {
      return "ps-justify-content-center";
    } else {
      if (this.step == 2 || this.step == 4) {
        return "ps-justify-content-between";
      } else {
        return "ps-justify-content-end";
      }
    }
  }
}
