<div class="ps-w-100 ps-d-flex ps-flex-wrap ps-mt-0 ps-mt-lg-3">
  <p purpleEditableText tranKey='send_user_email_invite_subtitle' class="ps-w-100 fsp-16-0">
    {{ 'send_user_email_invite_subtitle' | translation: 'Inserisci lindirizzo email della persona che vuoi invitare': []}}
  </p>
    <form nz-form [formGroup]="validateForm" class="ps-w-100">
      
        <div class="ps-col-12 ps-px-0 ps-d-flex ps-align-items-center form-item-container ps-pt-2">
            <p purpleEditableText tranKey='send_user_email_invite_email' *ngIf="!data.isMobile" class="form-label"><i nz-icon nzType="mail" nzTheme="outline"></i> {{ 'send_user_email_invite_email' |
                translation: 'Email': []}}:<sup>*</sup></p>
            <nz-form-item class="form-item">
                <nz-form-control [nzErrorTip]="'input_error_required' | translation: 'Campo obbligatorio': []">
                    <input nz-input purpleEditableText tranKey='send_user_email_invite_email_placeholder'
                        formControlName="email" type="email" 
                        [placeholder]="'send_user_email_invite_email_placeholder' | translation: 'Nuovo Indirizzo Email': []" />
                </nz-form-control>
            </nz-form-item>
        </div>
        
    </form>
    <div class="ps-w-100 ps-d-flex ps-justify-content-center ps-align-items-center ps-mt-4">
        <button purpleEditableText tranKey='send_user_email_invite_button_send' (click)="changeUserEmail()" nzType="default" nz-button class="modal-ok-button sw-border-radius" [disabled]="isLoading" [nzLoading]="isLoading">
          {{ 'send_user_email_invite_button_send' | translation: 'Invita': []}}
      </button>
    </div>
  </div>