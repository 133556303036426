import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PrivateSaleEventDetailComponent } from './private-sale-event-detail/private-sale-event-detail.component';
import { PrivateSaleEventsPreviewComponent } from './private-sale-events-preview/private-sale-events-preview.component';
import { PrivateUserProfileComponent } from './private-user-profile/private-user-profile.component';
import { MembershipPaymentComponent } from './payments/membership-payment/membership-payment.component';
import { SetMenuGuard } from 'src/core-modules/menu/setMenu.guard';
import { ContactsComponent } from '../public/contacts/contacts.component';
import { PrivateMembershipComponent } from './private-membership/private-membership.component';
import { PrivateMembershipDetailComponent } from './private-membership-detail/private-membership-detail.component';
import { PrivateMyReservationV2Component } from './private-my-reservation-v2/private-my-reservation-v2.component';
import { MembershipGuard } from 'src/core-modules/membership/membership.guard';


const routes: Routes = [
  { path: '', redirectTo: 'private-sales', pathMatch: 'full' },
  {
    path: 'contacts',
    component: ContactsComponent,
    canActivate: [SetMenuGuard],
    children:[
      {
        path: ':saleEventCode',
        component: PrivateSaleEventDetailComponent
      }
    ]
  },
  {
    path: 'private-sales',
    canActivate: [SetMenuGuard],
    component: PrivateSaleEventsPreviewComponent
  },
  {
    path: 'detail',
    canActivate: [SetMenuGuard],
    component: PrivateSaleEventDetailComponent,
    children:[
      {
        path: ':saleEventCode',
        component: PrivateSaleEventDetailComponent
      }
    ]
  },
  {
    path: 'my-reservations',
    canActivate: [SetMenuGuard],
    component: PrivateMyReservationV2Component
  },
  {
    path: 'user-profile',
    canActivate: [SetMenuGuard], 
    component: PrivateUserProfileComponent
  }, 
  {
    path: 'membership',
    canActivate: [MembershipGuard, SetMenuGuard],
    component: PrivateMembershipComponent
  },
  {
    path: 'membership-detail',
    canActivate: [MembershipGuard],
    component: PrivateMembershipDetailComponent
  },
  {
    path: 'membership-payment',
    component: MembershipPaymentComponent
  }
];

@NgModule({

  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PrivateRoutingModule { }
