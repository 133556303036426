import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PurpleApiMakeRequestResponse, PurpleApiProxyService, PurpleSelectFilter, PurpleTranslationPipe, purpleFormFieldsGroup } from 'purple-lib';
import { AuthenticationService } from 'src/core-modules/authentication/authentication.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BookingService, MembershipManagerService } from 'src/core-modules/sdk/api';
import { NzModalRef, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-membership-payment-info-check-modal',
  templateUrl: './membership-payment-info-check-modal.component.html',
  styleUrls: ['../../verify-modals/verify-account-modals.scss', './membership-payment-info-check-modal.component.scss']
})
export class MembershipPaymentInfoCheckModalComponent implements OnInit {

  constructor(@Inject(NZ_MODAL_DATA) public invoiceAddressId: string | undefined, private fb: FormBuilder, private membSvc: MembershipManagerService, private tsvc: AppTranslationService, private bookSvc: BookingService,
    private tranPipe: PurpleTranslationPipe, private apiProxySvc: PurpleApiProxyService, private modalRef: NzModalRef, private authSvc: AuthenticationService) { }

  validateForm: FormGroup = this.fb.group({});
  purpleFormFieldGroups: purpleFormFieldsGroup[] | undefined;
  isLoading: boolean = false;

  //#region Address
  addressSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.bookSvc.getAddressByNameSelect(args.culture, args)

  }

  addressSearchIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.bookSvc.getAddressByNameSelect(args.culture, args)
  }
  //#endregion

  ngOnInit() {
    this.purpleFormFieldGroups = [
      {
        fieldGroupNumber: 1,
        fielGroupBootstrapColumn: 12,
        fieldGroupPaddingLeft: false,
        fieldGroupPaddingRight: false,
        formFieldFormGroup: this.validateForm,
        formFields: [
          {
            fieldType: 'input-text',
            fieldControlName: 'taxId',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('memebership_payment_info_modal_taxId', 'Codice Fiscale', []),
            fieldPlaceholder: this.tranPipe.transform('memebership_payment_info_modal_taxid_placeholder', 'Codice Fiscale', []),
            fieldValue: this.authSvc.currentUser?.user?.taxId ?? undefined,
            fieldIsRequired: true,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: false
          },
          {
            fieldType: 'select',
            fieldControlName: 'address',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('memebership_payment_info_modal_address_title', 'Indirizzo', []),
            fieldPlaceholder: this.tranPipe.transform('memebership_payment_info_modal_address_placeholder', 'Indirizzo', []),
            fieldValue: this.invoiceAddressId,
            fieldIsRequired: true,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: false,
            fieldOptions: [
              { id: 'searchFunction', value: this.addressSearchFunction },
              { id: 'searchByIdFunction', value: this.addressSearchIdFunction },
            ]
          }
        ]
      }
    ]
  }

  async verifyPaymentInfo() {
    this.isLoading = true;
    for (const i in this.validateForm.controls) {
      if (this.validateForm.controls.hasOwnProperty(i)) {
        this.validateForm.controls[i].markAsDirty();
        this.validateForm.controls[i].updateValueAndValidity();
      }
    }

    if (this.validateForm.valid) {
      await this.apiProxySvc.makeRequestErrorFunction<boolean>(()=>this.membSvc.updateUserBuyMembershipRequiredInfo(this.tsvc.currentLanguage.value, {
        taxId: this.validateForm.controls["taxId"].value,
        invoiceExternalAddressId: this.validateForm.controls["address"].value,
      }),true, "internal-loader",500,(res: PurpleApiMakeRequestResponse<boolean>)=>{
        this.modalRef.close(false)
      },(res: PurpleApiMakeRequestResponse<boolean>)=>{
        this.modalRef.close(res.data!)
      })
    }
    this.isLoading = false;
  }

}
