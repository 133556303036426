import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { PurpleApiMakeRequestResponse, PurpleApiProxyService, PurpleTranslationPipe } from 'purple-lib';
import { PurpleSalesNotificationService } from 'src/app/components/purple-sales-notification.service';
import { SaleEventService } from 'src/app/sale-event-service.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BookingService } from 'src/core-modules/sdk/api';
import { environment } from 'src/environments/default/environment';

@Component({
  selector: 'app-send-email-invite-modal',
  templateUrl: './send-email-invite-modal.component.html',
  styleUrls: ['./send-email-invite-modal.component.scss']
})
export class SendEmailInviteModalComponent implements OnInit {

  constructor(@Inject(NZ_MODAL_DATA) public data: { roleId?: string | undefined, referralId: string, slotStartDate?: string | undefined, isMobile: boolean, saleEventId?: string | undefined }, private fb: UntypedFormBuilder, 
  private tranPipe: PurpleTranslationPipe, private modalRef: NzModalRef, private bookSvc: BookingService, private tsvc: AppTranslationService, private mesSvc: NzMessageService, private seSvc: SaleEventService, 
  private psNotSvc:PurpleSalesNotificationService, private apiProxySvc: PurpleApiProxyService) { }

  isLoading: boolean = false;
  validateForm!: UntypedFormGroup;
  subtitle: string = "";


  ngOnInit() {
    this.validateForm = this.fb.group({
      email: [{ value: null, disabled: false }, [Validators.required, Validators.email]]
    });
  }

  async changeUserEmail() {
    this.isLoading = true; for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }


    if (this.validateForm.valid) {

      await this.apiProxySvc.makeRequestErrorFunction<boolean>(()=>this.bookSvc.sendUserEmailInvite(this.tsvc.currentLanguage.value, {
        clientHost: window.location.hostname,
        email: this.validateForm.controls['email'].value,
        referralId: this.data.referralId,
        roleId: this.data.roleId,
        saleEventId: this.data.saleEventId?? this.seSvc.currentSaleEvent.value?.saleEventId!,
        slotStartDate: this.data.slotStartDate
      }),true, "internal-loader",500,(res: PurpleApiMakeRequestResponse<boolean>)=>{
        this.psNotSvc.showMotSimpleNotification(
          this.tranPipe.transform('error_message_default_title', 'Qualcosa è andato storto', []),
          res.message,
          this.tranPipe.transform('error_message_default_button', 'Ok', []),
          () => {
            this.isLoading = false;
          }, 'modal', true, undefined, undefined, false, false, false, false)
      },(res: PurpleApiMakeRequestResponse<boolean>)=>{
        this.mesSvc.success(this.tranPipe.transform('message_send_user_email_invite_success', 'Invito inviato con successo', []), {
          nzDuration: environment.MESSAGE_DURATION
        });
        this.isLoading = false;
        this.modalRef.close(res.data);
      })
    }
  }
}
