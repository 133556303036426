import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BreakpointsService {

  constructor() { }

  breakPointDevice$: BehaviorSubject<"mobile" | "tablet" | "desktop" | undefined> = new BehaviorSubject<"mobile" | "tablet" | "desktop" | undefined>(undefined);
  deviceHeight: number | undefined;

  setCurrentBreakPointDevice(se: "mobile" | "tablet" | "desktop" | undefined): void {
    this.breakPointDevice$.next(se);
  }

  setDeviceHeight(height: number) {
    this.deviceHeight = height;
  }

  getDrawerHeight(minDrawerHeight: number): string {
    if (this.deviceHeight == undefined) {
      return "auto";
    } else {
      console.log("Check height: ", this.deviceHeight < minDrawerHeight)
      if (this.deviceHeight < minDrawerHeight) {
        return "100%";
      } else {
        return "auto";
      }
    }
  }

}
