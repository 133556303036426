<div class="ps-w-100 ps-justify-content-center ps-d-flex">
  <div class="error-animation-container ps-d-flex ps-flex-wrap ps-justify-content-center">
    <div class="ps-w-100 ps-d-flex ps-flex-wrap ps-mb-2">
      <h1 class="error-title ps-w-100">{{ '404_error_tile' | translation: 'Oops!': []}}</h1>
      <h3 class="error-subtitle ps-w-100">{{ '404_error_subtile' | translation: 'La pagina che hai cercato non esiste':
        []}}</h3>
      <div class="error-back-home ps-w-100">
        {{ 'error_404_back_to' | translation: 'Torna alla': []}}
        <a [routerLink]="['/', tsvc.currentLanguage.value, 'login']">HomePage</a>
      </div>
    </div>
  </div>
</div>