<div class="purple-sales-header-container">
  <div class="purple-sales-header-container-internal">
    <div class="purple-sales-header-img-container">
      <container-element [ngSwitch]="layoutSvc.showHeaderIcon">
        <ng-container *ngSwitchCase="true">
          <span (click)="layoutSvc.headerIconFunction()"
            class="purple-sales-header-img-icon {{layoutSvc.headerIconName}}"></span>
        </ng-container>
        <ng-container *ngSwitchCase="false">
          <img (click)="layoutSvc.headerIconFunction()" contentId="logo-header" contentTypeId="logo-company"
            purpleEditableImage class="purple-sales-header-img" />
        </ng-container>
      </container-element>
    </div>
    <container-element [ngSwitch]="breakSvc.breakPointDevice$ | async">
      <ng-container *ngSwitchDefault>

        <div
          class="ps-d-flex purple-header-menu-mobile-buttons-container ps-flex-wrap ps-align-items-center ps-justiy-content-center">
          <container-element [ngSwitch]="authSvc.isLoggedIn">
            <ng-container *ngSwitchCase="true">
              <i (click)="showQrCodeDrawer()" class="purple-header-menu-mobile-button-icon {{qrCodeOpen?'active':''}}"
                nz-icon nzType="qrcode" nzTheme="outline"></i>

              <i (click)="showUserMenuDrawer()" nz-icon nzType="user" nzTheme="outline"
                class="purple-header-menu-mobile-button-icon"></i>
            </ng-container>
            <ng-container *ngSwitchCase="false">
              <i (click)="openChangeLanguageDrawer()" nz-icon nzType="global" nzTheme="outline"
                class="purple-header-menu-mobile-button-icon global-icon"></i>
            </ng-container>
          </container-element>

        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'desktop'">
        <ul class="purple-sales-header-menu-container" nz-menu nzMode="horizontal">
          <ng-container *ngFor="let menuItem of layoutSvc.activeMenu">
            <li
              *ngIf="layoutSvc.showMenu(menuItem.maxLevel, menuItem.minLevel, menuItem.exlusiveRoles, menuItem.excludeRoles)"
              nz-menu-item routerLinkActive="ant-menu-item-selected" [routerLink]="menuItem.relativeLink"
              [queryParams]="menuItem.queryParams" [nzSelected]="layoutSvc.checkIsCurrentMenu(menuItem.id)"
              class="purple-sales-header-menu-button">
              <p class="purple-sales-menu-text">{{menuItem.name}}</p>
            </li>
          </ng-container>


          <container-element [ngSwitch]="authSvc.isLoggedIn">
            <ng-container *ngSwitchCase="true">
              <li>
                <button class="purple-sales-user-header-button ps-d-flex ps-align-items-center" nz-popover
                  [nzPopoverContent]="userTemplate" nzPopoverTrigger="click" nzPopoverPlacement="bottomRight"
                  nzPopoverOverlayClassName="purple-sales-user-header-menu"
                  [(nzPopoverVisible)]="layoutSvc.isUserMenuOpen" (nzPopoverVisibleChange)="openMenu()">
                  <p class="purple-sales-user-header-button-label">{{authSvc.currentUser?.user?.firstName??"NS"}}</p>
                  <i nz-icon [nzType]="layoutSvc.isUserMenuOpen?'up':'down'" nzTheme="outline"></i>
                </button>
              </li>
            </ng-container>
            <ng-container *ngSwitchCase="false">
              <li>
                <nz-select class="purple-sales-header-menu-language-select" [ngModel]="tsvc.currentLanguage.value"
                  (ngModelChange)="tsvc.switchLanguage($event)">
                  <nz-option *ngFor="let lan of tsvc.availableLanguages" [nzValue]="lan.languageId"
                    [nzLabel]="lan.languageName.toUpperCase()">
                  </nz-option>
                </nz-select>
              </li>
            </ng-container>
          </container-element>
        </ul>
      </ng-container>
    </container-element>
  </div>
</div>


<div *ngIf="(breakSvc.breakPointDevice$ | async)!='desktop'" class="ps-d-flex ps-align-items-center ps-justify-content-end 
  purple-sales-header-mobile-hamburger-container {{menuOpen?'visible':''}}">
  <div (click)="hamburgerClick()" class="hamburger-button">
    <div class="hamburger-button-line" [@hamburguerX]="!menuOpen ? 'hamburguer' : 'topX'"></div>
    <div class="hamburger-button-line" [@hamburguerX]="!menuOpen ? 'hamburguer' : 'hide'"></div>
    <div class="hamburger-button-line" [@hamburguerX]="!menuOpen ? 'hamburguer' : 'bottomX'"></div>
  </div>
</div>

<nz-drawer nzWrapClassName="purple-sales-mobile-menu-drawer" [nzClosable]="false" [nzVisible]="menuOpen"
  nzPlacement="right" nzWidth="100%" nzHeight="100%">
  <ng-container *nzDrawerContent>
    <div class="purple-sales-mobile-menu-container {{mobileMenuClass}}">
      <ul nz-menu nzMode="vertical">
        <ng-container *ngFor="let menuItem of layoutSvc.activeMenu">
          <li
            *ngIf="layoutSvc.showMenu(menuItem.maxLevel, menuItem.minLevel, menuItem.exlusiveRoles, menuItem.excludeRoles)"
            nz-menu-item routerLinkActive="ant-menu-item-selected" [routerLink]="menuItem.relativeLink"
            (click)="mobileNavigate()" [nzSelected]="layoutSvc.checkIsCurrentMenu(menuItem.id)"
            [queryParams]="menuItem.queryParams">
            <p class="purple-sales-mobile-menu-text">{{menuItem.name}}</p>
          </li>
        </ng-container>
      </ul>
    </div>
  </ng-container>
</nz-drawer>


<ng-template #userTemplate>
  <div class="purple-sales-user-menu-popover-container ps-d-flex ps-flex-wrap ps-align-items-start">
    <div class="ps-w-100 ps-d-flex ps-flex-wrap">
      <h2 class="purple-sales-user-menu-popover-firstname ps-w-100">{{authSvc.currentUser?.user?.firstName??"NS"}}</h2>
      <h3 class="purple-sales-user-menu-popover-email ps-w-100">{{authSvc.currentUser?.user?.email??"******@****.***"}}
      </h3>
      <div class="purple-sales-user-menu-popover-line"></div>
      @if(membershipInfo?.hasMembership??false){
          <div class="purple-sales-user-menu-popover-membership-container disable-select">
            <div class="ps-w-100 ps-d-flex ps-flex-wrap ps-align-items-center ps-justify-content-between">
              <span class="purple-sales-user-menu-popover-membership-icon" nz-icon nzType="star" nzTheme="fill"></span>
              <p class="purple-sales-user-menu-popover-membership-name disable-select">{{membershipInfo!.membershipName}}</p>
              <span class="purple-sales-user-menu-popover-membership-icon" nz-icon nzType="star" nzTheme="fill"></span>
            </div>
            <p *ngIf="membershipInfo!.membershipExpireDate" class="purple-sales-user-menu-popover-membership-valid-until">
              {{ 'menu_user_membership_valid_to' | translation: 'valido fino al {0}': [(membershipInfo!.membershipExpireDate | date: "shortDate")!]}}
            </p>
          </div>
        <div class="purple-sales-user-menu-popover-line"></div>
      }
      <div class="ps-w-100 ps-d-flex">
        <a class="purple-sales-user-menu-popover-button" purpleEditableText tranKey='menu_user_my_profile'
          [routerLink]="['/',tsvc.currentLanguage.value, 'event', 'user-profile']">
          {{ 'menu_user_my_profile' | translation: 'Il Mio Account': []}}
        </a>
      </div>
      <div class="ps-w-100 ps-d-flex">
        <a class="purple-sales-user-menu-popover-button" purpleEditableText tranKey='menu_user_exit'
          (click)="layoutSvc.logout()">
          {{ 'menu_user_exit' | translation: 'Esci': []}}
        </a>
      </div>
      <nz-select nzPlaceHolder="Seleziona Lingua" class="purple-sales-user-menu-popover-language-select ps-w-100"
        [ngModel]="tsvc.currentLanguage.value" (ngModelChange)="tsvc.switchLanguage($event)">
        <nz-option *ngFor="let lan of tsvc.availableLanguages" [nzValue]="lan.languageId" [nzLabel]="lan.languageName">
          {{lan.languageName}}
        </nz-option>
      </nz-select>
    </div>
  </div>
</ng-template>