import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-private-user-profile-title',
  templateUrl: './private-user-profile-title.component.html',
  styleUrls: ['./private-user-profile-title.component.scss']
})
export class PrivateUserProfileTitleComponent implements OnInit {

  constructor() { }
  @Input() title!: string;
  @Input() titleKey?: string | undefined | null;
  @Input() subtitle: string | undefined | null;
  @Input() subtitleKey?: string | undefined | null;

  ngOnInit() {
  }

}