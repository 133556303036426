<div class="ps-w-100 ps-d-flex ps-flex-wrap ps-justify-content-center verify-account-container membership-payments">
  <p purpleEditableText tranKey='membership_payment_info_check_title' class="verify-account-section-title">
    {{ 'membership_payment_info_check_title' | translation: 'Inserisci i dati richiesti': []}}
  </p>
  <p purpleEditableText tranKey='membership_payment_info_check_subtitle' class="verify-account-section-subtitle">
    {{ 'membership_payment_info_check_subtitle' 
    | translation: 'Per effettuare il pagamento è necessario specificare i seguenti dati': []}}
  </p>

  <div class="ps-w-100 ps-d-flex ps-flex-wrap ps-justify-content-center verify-account-modal-form-container">
    <app-purple-form class="verify-account-modal-form"
      *ngIf="purpleFormFieldGroups!= undefined" [(formGroup)]="validateForm"
      [purpleFormFieldGroups]="purpleFormFieldGroups" (purpleFormSubmit)="verifyPaymentInfo()">
    </app-purple-form>
    <app-purple-sales-button purpleEditableText tranKey='membership_payment_info_check_button' (click)="verifyPaymentInfo()"
      [buttonText]="'membership_payment_info_check_button' | translation: 'Conferma dati'">
    </app-purple-sales-button>
  </div>
</div>