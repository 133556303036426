import { Pipe, PipeTransform } from "@angular/core";
import { SaleEventDatePipe } from "./sale-event-date.pipe";

@Pipe({
  name: 'saleEventDateRange'
})
export class SaleEventDateRangePipe implements PipeTransform {
  constructor(private saleEventDatePipe: SaleEventDatePipe) { }

  transform(startDate: string, endDate: string, format: string, timezone?: string, locale?: string): string {
    const startFormatted = this.saleEventDatePipe.transform(startDate, format, timezone, locale);
    const endFormatted = this.saleEventDatePipe.transform(endDate, format, timezone, locale);

    console.log("🚀 ~ SaleEventDateRangePipe ~ transform ~ startFormatted:", startFormatted)
    console.log("🚀 ~ SaleEventDateRangePipe ~ transform ~ endFormatted:", endFormatted)
    if (startFormatted === endFormatted) {
      return startFormatted ?? '';
    } else {
      return `${startFormatted} - ${endFormatted}`;
    }
  }
}