<div class="auth-external-container ps-w-100 ps-d-flex ps-flex-wrap">

  <div class="auth-form-mobile-banner ps-d-lg-none ps-d-flex ps-align-items-center" contentId="{{seId}}-authentication"
    contentTypeId="authentication-image-mobile" purpleEditableImage></div>

  <div
    class="auth-form-left-container ps-d-flex ps-w-100 ps-flex-wrap ps-col-12 ps-col-lg-6 ps-align-items-center ps-justify-content-end">
    <div class="ps-d-flex ps-w-100 ps-flex-wrap">
      
      <app-purple-sales-page-title *ngIf="pageTitle.title!='#NS#'"
        [pageTitle]="pageTitle"></app-purple-sales-page-title>

      <div class="ps-col-12 ps-px-0 ps-d-flex ps-flex-wrap ps-align-items-start">
        <app-purple-form [disbledForm]="isLoading" class="ps-w-100" *ngIf="purpleFormFieldGroups!= undefined"
          [(formGroup)]="validateForm" [purpleFormFieldGroups]="purpleFormFieldGroups"
          (purpleFormSubmit)="resetPassword()">
        </app-purple-form>

        <app-purple-sales-button (click)="resetPassword()" class="auth-main-button" purpleEditableText
          tranKey='reset_password_button' [buttonText]="'reset_password_button' | translation: 'Reimposta password'"
          [buttonType]="'primary'"></app-purple-sales-button>
      </div>
    </div>
  </div>

  <div class="auth-form-right-container ps-col-6 ps-d-none ps-d-lg-flex 
  ps-justify-content-center ps-align-items-end ps-justify-content-start" contentId="{{seId}}-authentication"
    contentTypeId="authentication-image" purpleEditableImage>
  </div>

</div>