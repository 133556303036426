import { Component, Inject, OnInit } from '@angular/core';
import { NzModalRef, NzModalService, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { lastValueFrom } from 'rxjs';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BookingService, PurpleApiResponseStatus, UserNextSaleEventBookingItem, UserExtendedReferral, StringPurpleApiResponse, PRPVSaleEventFull } from 'src/core-modules/sdk/api';
import { AuthenticationService } from 'src/core-modules/authentication/authentication.service';
import { EffectType } from '../book-slot-modal/book-slot-modal.component';
import { SendEmailInviteModalComponent } from '../send-email-invite-modal/send-email-invite-modal.component';
import { PurpleTranslationPipe } from 'purple-lib';
import { PurpleSalesNotificationService } from 'src/app/components/purple-sales-notification.service';

@Component({
  selector: 'app-invite-friends-modal',
  templateUrl: './invite-friends-modal.component.html',
  styleUrls: ['./invite-friends-modal.component.scss']
})
export class InviteFriendsModalComponent implements OnInit {

  constructor(@Inject(NZ_MODAL_DATA) public data : { book?: UserNextSaleEventBookingItem | undefined, saleEvent: PRPVSaleEventFull }, public authSvc: AuthenticationService, private modalRef: NzModalRef,
    private bookSvc: BookingService, public tsvc: AppTranslationService, private psNotSvc: PurpleSalesNotificationService,
    private tranPipe: PurpleTranslationPipe, private modal: NzModalService) { }

    freeRefCodeNr: number = 0;
    referralFriends: UserExtendedReferral[] | undefined = undefined;
    loadEnd: boolean = false;


  async ngOnInit() {
    const resp = await lastValueFrom(
      this.bookSvc.getUserFreeSaleEventReferral(this.tsvc.currentLanguage.value, {
        saleEventId: this.data.book?.saleEventId??this.data.saleEvent.saleEventId,
        roleId: this.data.book?.slot.roleId??undefined
      }));

    if (resp.status == PurpleApiResponseStatus.Success) {
      this.freeRefCodeNr = resp.data?.freeReferralsNr ?? 0;
      this.referralFriends = resp.data?.redeemReferralFriends ?? [];
    } else {
      this.freeRefCodeNr = 0;
      this.referralFriends = [];
    }
    
    this.setLoad(EffectType.up)
  }

  async checkUserreferral(): Promise<StringPurpleApiResponse> {
    const resp = await lastValueFrom(
      this.bookSvc.addSaleEventUserRoleReferral(this.tsvc.currentLanguage.value, {
        roleId: this.data.book?.slot.roleId??undefined,
        saleEventId: this.data.book?.saleEventId??this.data.saleEvent.saleEventId
      }));

    return resp
  }

  async sendEmail() {
    var referralId = await this.checkUserreferral();
    if (referralId.data != undefined) {
      this.modal.create<SendEmailInviteModalComponent, { roleId?: string | undefined, referralId: string, slotStartDate?: string | undefined, isMobile: boolean, saleEventId?: string | undefined }>({
        nzTitle: this.tranPipe.transform("modal_send_user_email_invite_title", "Invita Amico", []),
        nzContent: SendEmailInviteModalComponent,
        nzData: {
          roleId: this.data.book?.slot.roleId??undefined,
          referralId: referralId.data,
          slotStartDate: this.data.book?.slot.slotStartDate??undefined,
          saleEventId: this.data.book?.saleEventId??this.data.saleEvent.saleEventId,
          isMobile: false
        },
        nzWidth: '600px',
        nzClassName: 'ps-modal',
        nzFooter: null
      });
    } else{
      this.psNotSvc.showMotSimpleNotification(
        this.tranPipe.transform('error_message_default_title', 'Qualcosa è andato storto', []),
        referralId.message,
        this.tranPipe.transform('error_message_default_button', 'Ok', []),
        undefined, 'modal', true, undefined, undefined, false, false, false, false)    
      
    }
  }

  async sendWhatsapp() {
    var referralId = await this.checkUserreferral();
    if (referralId.data != undefined) {
      var textToSend = this.tranPipe.transform('send_user_whatsapp_invite_text','{0} ti ha inviato alla private sale \"{1}\", clicca sul seguente link per prenotarti:{2}', 
      [this.authSvc.currentUser!.user!.firstName + " " + this.authSvc.currentUser!.user!.lastName, this.data.book?.saleEventName??this.data.saleEvent.saleEventName, 
      " https://"+window.location.hostname+"/"+this.tsvc.currentLanguage.value+"/referral?id="+referralId.data]);

      window.open('https://wa.me/?text=' + textToSend);
    } else{
      this.psNotSvc.showMotSimpleNotification(
        this.tranPipe.transform('error_message_default_title', 'Qualcosa è andato storto', []),
        referralId.message,
        this.tranPipe.transform('error_message_default_button', 'Ok', []),
        undefined, 'modal', true, undefined, undefined, false, false, false, false) 
      
    }
  }

  closeModal() {
    this.modalRef.close();
    //this.modal.destroy();
  }

  setLoad(type: EffectType) {
    if (type == EffectType.up) {
      this.loadEnd = false;
      setTimeout(() => {
        this.loadEnd = true;
      }, 200);
    }

    if (type == EffectType.down) {
      this.loadEnd = false;
    }

  }

}
