import { Component, OnInit } from '@angular/core';
import { PurpleApiMakeRequestResponse, PurpleApiProxyService, PurpleLoaderService, PurpleTranslationPipe } from 'purple-lib';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { PurpleSalesNotificationService } from 'src/app/components/purple-sales-notification.service';
import { MembershipManagerService, ApiCreateCheckoutResponse, CheckUserBuyMembershipRequiredInfoResponse } from 'src/core-modules/sdk/api/index';
import { ActivatedRoute, Router } from '@angular/router';
import { SumupPay } from '../sumup-pay.js';
import { AuthenticationService } from 'src/core-modules/authentication/authentication.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { MembershipPaymentInfoCheckModalComponent } from '../../modals/membership-modals/membership-payment-info-check-modal/membership-payment-info-check-modal.component';
import { RegisterUserMemebershipModalComponent } from '../../modals/membership-modals/register-user-memebership-modal/register-user-memebership-modal.component';

@Component({
  selector: 'app-membership-payment',
  templateUrl: './membership-payment.component.html',
  styleUrls: ['./membership-payment.component.scss']
})

export class MembershipPaymentComponent implements OnInit {

  constructor(private psNotSvc: PurpleSalesNotificationService, private tranPipe: PurpleTranslationPipe, private route: ActivatedRoute, private router: Router, private modal: NzModalService,
    private apiProxySvc: PurpleApiProxyService, private memberSvc: MembershipManagerService, private loaderSvc: PurpleLoaderService, private tsvc: AppTranslationService, private authSvc: AuthenticationService) { }


  locales: string[] = [
    "bg-BG", "cs-CZ", "da-DK", "de-AT", "de-CH", "de-DE", "de-LU", "el-CY", "el-GR", "en-GB", "en-IE", "en-MT", "en-US", "es-CL", "es-ES", "et-EE",
    "fi-FI", "fr-BE", "fr-CH", "fr-FR", "fr-LU", "hu-HU", "it-CH", "it-IT", "lt-LT", "lv-LV", "nb-NO", "nl-BE", "nl-NL", "pt-BR", "pt-PT", "pl-PL",
    "sk-SK", "sl-SI", "sv-SE"
  ]

  countries: string[] = [
    "AT", "BE", "BG", "BR", "CH", "CL", "CO", "CY", "CZ", "DE", "DK", "EE", "ES", "FI", "FR", "GB", "GR", "HR", "HU", "IE", "IT", "LT",
    "LU", "LV", "MT", "NL", "NO", "PL", "PT", "RO", "SE", "SI", "SK", "US"
  ]

  ngOnInit() {
    this.loaderSvc.addRequest("general")

    this.route.queryParams.subscribe(async params => {
      const variantId = params['variantId'];

      if (!variantId) {
        this.loaderSvc.removeAllRequest("general")
        this.psNotSvc.showMotSimpleNotification(
          this.tranPipe.transform('begin_sumup_payment_error_title', 'Qualcosa è andato storto', []),
          this.tranPipe.transform('begin_sumup_payment_error_subtitle', 'Impossibile aprire la pagina del pagamento', []),
          this.tranPipe.transform('begin_sumup_payment_error_button', 'Riprova', []),
          () => {
            this.router.navigate([this.tsvc.currentLanguage.value, 'event', 'membership']);
          }, 'modal', true, undefined, undefined, false, false, false, false)
      }

      await this.apiProxySvc.makeRequestErrorFunction<ApiCreateCheckoutResponse>(() => this.memberSvc.createCheckout(this.tsvc.currentLanguage.value, {
        skuId: variantId
      }), true, "general", 10, (res: PurpleApiMakeRequestResponse<ApiCreateCheckoutResponse>) => {
        this.loaderSvc.removeAllRequest("general")
        this.psNotSvc.showMotSimpleNotification(
          this.tranPipe.transform('error_message_default_title', 'Qualcosa è andato storto', []),
          res.message,
          this.tranPipe.transform('error_message_default_button', 'Ok', []),
          () => {
            this.router.navigate([this.tsvc.currentLanguage.value, 'event', 'membership']);
          }, 'modal', true, undefined, undefined, false, false, false, false)
      }, async (res: PurpleApiMakeRequestResponse<ApiCreateCheckoutResponse>) => {
        await this.apiProxySvc.makeRequestErrorFunction<CheckUserBuyMembershipRequiredInfoResponse>(() => this.memberSvc.checkUserBuyMembershipRequiredInfo(this.tsvc.currentLanguage.value), true,
          "general", 500, (res1: PurpleApiMakeRequestResponse<CheckUserBuyMembershipRequiredInfoResponse>) => {
            this.loaderSvc.removeAllRequest("general")
            this.psNotSvc.showMotSimpleNotification(
              this.tranPipe.transform('error_message_default_title', 'Qualcosa è andato storto', []),
              res.message,
              this.tranPipe.transform('error_message_default_button', 'Ok', []),
              () => {
                this.router.navigate([this.tsvc.currentLanguage.value, 'event', 'membership']);
              }, 'modal', true, undefined, undefined, false, false, false, false)
          }, (res1: PurpleApiMakeRequestResponse<CheckUserBuyMembershipRequiredInfoResponse>) => {
            if (res1.data!.checkAll) {
              this.loaderSvc.removeAllRequest("general")
              this.runSumupPay(res.data!.providerTransactionId!, res.data!.transactionPrice!, res.data!.transactionCurrency!);
            } else {
              this.loaderSvc.removeAllRequest("general")
              this.modal.create<MembershipPaymentInfoCheckModalComponent, string | undefined>({
                nzTitle: this.tranPipe.transform("check_member_payment_info_modal_title", "Dati fatturazione", []),
                nzContent: MembershipPaymentInfoCheckModalComponent,
                nzWidth: '850px',
                nzClassName: 'purple-sales-modal',
                nzData: res1.data?.invoiceAddressExternalId ?? undefined,
                nzFooter: null,
                nzCentered: false,
                nzMaskClosable: false,
                nzClosable: true
              }).afterClose.subscribe((completed: boolean) => {
                if (completed) {
                  this.runSumupPay(res.data!.providerTransactionId!, res.data!.transactionPrice!, res.data!.transactionCurrency!);
                } else {
                  this.psNotSvc.showMotSimpleNotification(
                    this.tranPipe.transform('sumup_payment_no_info_title', 'Impossibile continuare', []),
                    this.tranPipe.transform('sumup_payment_no_info_subtitle', 'Per continuare è necessario specificare i dati richiesti', []),
                    this.tranPipe.transform('error_message_default_button', 'Ok', []),
                    () => {
                      this.router.navigate([this.tsvc.currentLanguage.value, 'event', 'membership']);
                    }, 'modal', true, undefined, undefined, false, false, false, false)
                }
              });
            }
          })
      })
    });
  }

  runSumupPay(providerTransactionId: string, transactionPrice: number, transactionCurrency: string) {
    SumupPay(
      providerTransactionId,
      this.authSvc.currentUser!.user!.email, transactionPrice, true, this.getLocale(), this.getCountry(), transactionCurrency, true,
      () => {
        this.loaderSvc.removeAllRequest("general")
      },
      (res: sumupPyamentResponse) => {

        console.log("RES JS SUMUP: ", res)

        if(!res.type.strEq("sent")){
          if (res.isSuccess) {
            this.showSubscriptionModal(providerTransactionId);
          } else {
            this.psNotSvc.showMotSimpleNotification(
              this.tranPipe.transform('begin_sumup_payment_error_title', 'Qualcosa è andato storto', []),
              res.body.message,
              this.tranPipe.transform('begin_sumup_payment_error_button', 'Riprova', []),
              () => {
                this.router.navigate([this.tsvc.currentLanguage.value, 'event', 'membership']);
              }, 'modal', true, undefined, undefined, false, false, false, false)
          }
        }
        
      })
  }

  showSubscriptionModal(providerTransactionId: string) {
    this.modal.create<RegisterUserMemebershipModalComponent>({
      nzTitle: this.tranPipe.transform("register_user_membership_modal_title", "Registrazione membership", []),
      nzContent: RegisterUserMemebershipModalComponent,
      nzWidth: '850px',
      nzData: providerTransactionId,
      nzClassName: 'purple-sales-modal',
      nzFooter: null,
      nzCentered: false,
      nzMaskClosable: false,
      nzClosable: true
    })
  }

  getLocale() {
    const idx = this.locales.findIndex(f => f.strEq(this.tsvc.currentLanguage.value));

    if (idx != -1) {
      return this.locales[idx];
    } else {
      return "en-US";
    }
  }

  getCountry() {
    const idx = this.countries.findIndex(f => f.strEq(this.tsvc.currentLanguage.value.substring(3)));

    if (idx != -1) {
      return this.countries[idx];
    } else {
      return "US";
    }
  }
}

interface sumupPyamentResponse {
  body: sumupPyamentBodyError;
  isSuccess: boolean;
  type: string;
}


interface sumupPyamentBodyError {
  detail: string
  error_code: string
  instance: string
  message: string
  param: undefined | null | any
  status: number
  title: string
  type: string
}