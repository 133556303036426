import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AccountNeedVerificationService {
  bannerVisible: boolean = false;

  constructor() { }

  showBanner() {
    this.bannerVisible = true;
  }

  hideBanner() {
    this.bannerVisible = false;
  }

}
