import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SaleEventService } from '../sale-event-service.service';
import { RedeemReferralComponent } from './redeem-referral/redeem-referral.component';
import { NzNotificationModule } from 'ng-zorro-antd/notification';

@NgModule({
  declarations: [
    RedeemReferralComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NzNotificationModule
  ],
  providers:[
    SaleEventService
  ]
})
export class ReferralModule { }
