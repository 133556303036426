<div class="user-profile-section-intro-container">
  <div class="user-profile-section-title-container">
    <h2 purpleEditableText [tranKey]='titleKey??undefined' class="user-profile-section-title">
      {{title}}
    </h2>
    <div class="user-profile-section-title-line"></div>
  </div>
  <p *ngIf="subtitle" purpleEditableText [tranKey]='subtitleKey??undefined' class="user-profile-section-subtitle">
    {{subtitle}}
  </p>
</div>