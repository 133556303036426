import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { PurpleTranslationPipe } from 'purple-lib';
import { AuthenticationService } from 'src/core-modules/authentication/authentication.service';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { UserMenuDrawerComponent } from './drawers/user-menu-drawer/user-menu-drawer.component';
import { purpleSalesMenuItem } from './header/interfaces/saywowMenuItem';
import { PurpleSalesNotificationService } from '../components/purple-sales-notification.service';
import { environment } from 'src/environments/default/environment';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  isUserMenuOpen: boolean = false;

  constructor(private router: Router, private tsvc: AppTranslationService, private tranPipe: PurpleTranslationPipe, 
    private authSvc: AuthenticationService, private psNotSvc: PurpleSalesNotificationService) {
    this.tsvc.currentLanguage.subscribe(()=>{
      this.refreshMenuItems();
    });
  }

  currentActiveMenuitem: string | undefined;
  headerIconFunction:(()=>any) = async ()=>{await this.router.navigate([this.tsvc.currentLanguage.value, 'event', 'private-sales'])};
  showHeaderIcon: boolean = false;
  headerIconName: string | undefined;

  notLoggedMenu: purpleSalesMenuItem[] = [];
  loggedMenu: purpleSalesMenuItem[] = [];
  activeMenu: purpleSalesMenuItem[] = this.notLoggedMenu;
  redirectUrl: string | undefined;

  qrCodeOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  refreshMenuItems(){
    this.notLoggedMenu = [
      {
        id: "login",
        name: this.tranPipe.transform('menu_login', 'Accedi', []),
        relativeLink: [this.tsvc.currentLanguage.value, "login"],
        queryParams: {redirectUrl: this.redirectUrl}
      },
      {
        id: "register",
        name: this.tranPipe.transform('menu_register', 'Registrati', []),
        relativeLink: [this.tsvc.currentLanguage.value, "register"],
        queryParams: {redirectUrl: this.redirectUrl}
      },
      {
        id: "contacts",
        name: this.tranPipe.transform('menu_contacts', 'Contatti', []),
        relativeLink: [this.tsvc.currentLanguage.value, "contacts"],
        queryParams: {redirectUrl: this.redirectUrl}
      }
    ]

    if(environment.SHOW_MEMBERSHIPS){
      this.loggedMenu = [
        {
          id: "home",
          name: this.tranPipe.transform('menu_events', 'Eventi', []),
          relativeLink: [this.tsvc.currentLanguage.value, 'event', 'private-sales'],
          queryParams: {}
        },
        {
          id: "my-bookings",
          name: this.tranPipe.transform('menu_my_reservations', 'Prenotazioni', []),
          relativeLink: [this.tsvc.currentLanguage.value, 'event', 'my-reservations'],
          queryParams: {}
        },
        {
          id: "membership",
          name: this.tranPipe.transform('menu_membership', 'Membership', []),
          relativeLink: [this.tsvc.currentLanguage.value, 'event', 'membership'],
          queryParams: {}
        },
        {
          id: "contacts",
          name: this.tranPipe.transform('menu_contacts', 'Contatti', []),
          relativeLink: [this.tsvc.currentLanguage.value, 'event', 'contacts'],
          queryParams: {}
        }
      ]
    }else{
      this.loggedMenu = [
        {
          id: "home",
          name: this.tranPipe.transform('menu_events', 'Eventi', []),
          relativeLink: [this.tsvc.currentLanguage.value, 'event', 'private-sales'],
          queryParams: {}
        },
        {
          id: "my-bookings",
          name: this.tranPipe.transform('menu_my_reservations', 'Prenotazioni', []),
          relativeLink: [this.tsvc.currentLanguage.value, 'event', 'my-reservations'],
          queryParams: {}
        },
        {
          id: "contacts",
          name: this.tranPipe.transform('menu_contacts', 'Contatti', []),
          relativeLink: [this.tsvc.currentLanguage.value, 'event', 'contacts'],
          queryParams: {}
        }
      ]
    }
  }

  setCurrentActiveMenuItem(menu?: string | undefined): void {
    this.currentActiveMenuitem = menu;
  }

  setCurrentActiveMenu(isLogged: boolean): void {
    if(!isLogged){
      this.activeMenu = this.notLoggedMenu
    }else{
      this.activeMenu = this.loggedMenu
    }
  }

  showMenu(maxLevel: number | undefined, minLevel: number | undefined, exlusiveRoles: string[] | undefined, excludeRoles: string[] | undefined, ){
    const uRoleLevel = this.authSvc.currentUser?.roleLevel??100;

    if(maxLevel != undefined && uRoleLevel > maxLevel){
      return false;
    }

    if(minLevel != undefined && uRoleLevel < minLevel){
      return false;
    }

    const uRoles = this.authSvc.currentUser?.roles??[];

    if(exlusiveRoles != undefined && !exlusiveRoles.some(element => uRoles.includes(element))){
      return false;
    }

    if(excludeRoles != undefined && excludeRoles.some(element => uRoles.includes(element))){
      return false;
    }

    return true;
  }

  checkIsCurrentMenu(menu: string): boolean {
    return (this.currentActiveMenuitem?.strEq(menu) ?? false)
  }



  logout(userMenuDrawer: NzDrawerRef<UserMenuDrawerComponent, string> | undefined = undefined) {
    this.isUserMenuOpen = false;
    
    this.psNotSvc.showMotSimpleNotification(
      this.tranPipe.transform('modal_logout_title', 'Logout', []),
      this.tranPipe.transform('modal_logout_subtitle', '{0} sei sicuro di voler uscire?', [this.authSvc.currentUser?.user?.firstName!]),
      this.tranPipe.transform("modal_logout_button", "logout"),
      ()=>{
        var res = this.authSvc.logout();
        if (res) {
          if(userMenuDrawer != undefined){
            userMenuDrawer.close();
          }
          this.isUserMenuOpen = false;
          this.router.navigate([this.tsvc.currentLanguage.value, 'login'], {});
        }else{
          this.psNotSvc.showMotSimpleNotification(
            this.tranPipe.transform('my_mot_logout_error_title', 'Qualcosa è andato storto', []),
            this.tranPipe.transform('my_mot_logout_error_subtitle', 'Impossibile effettuare il logout', []),
            this.tranPipe.transform('my_mot_logout_error_button', 'Ok', []),
            undefined, 'modal', true, undefined, undefined, false, false, false, false)
        }
      },
      'modal',
      true,
      undefined,
      undefined,
      true,
      false,
      true,
      true,
      undefined,
      undefined
    )
  }

  lightOrDark(color: any | null | undefined): 'light' | 'dark' {
    if(!color){
      return 'light';
    }

    var r: number = 0;
    var g: number = 0;
    var b: number = 0;

    if (color.match(/^rgb/)) {
      color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
  
      r = color[1];
      g = color[2];
      b = color[3];
    } 
    else {
      color = +("0x" + color.slice(1).replace( 
        color.length < 5 && /./g, '$&$&'
      ));
      r = color >> 16;
      g = color >> 8 & 255;
      b = color & 255;
    }
  
    const hsp = Math.sqrt(
      0.299 * (r * r) +
      0.587 * (g * g) +
      0.114 * (b * b)
    );
  
    if (hsp>127.5) {
  
      return 'light';
    } 
    else {
  
      return 'dark';
    }
  }
}
