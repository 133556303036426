import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from 'src/environments/default/environment';
import { RequestResetPasswordComponent } from './authentication/request-reset-password/request-reset-password.component';
import { LoginComponent } from './authentication/login/login.component';
import { RegisterComponent } from './authentication/register/register.component';
import { ResetPasswordComponent } from './authentication/reset-password/reset-password.component';
import { VerifyUserEmailComponent } from './authentication/verify-user-email/verify-user-email.component';
import { Error404Component } from './error/404/404.component';
import { RedeemReferralComponent } from './referral/redeem-referral/redeem-referral.component';
import { PrivateRegisterComponent } from './authentication/private-register/private-register.component';
import { LocalizationGuard } from 'src/core-modules/localization/localization.guard';
import { CountryGuard } from 'src/core-modules/country/country.guard';
import { TermsAndConditionsComponent } from './public/terms-and-conditions/terms-and-conditions.component';
import { SaleEventsPreviewComponent } from './public/sale-events-preview/sale-events-preview.component';
import { AuthenticationGuard } from 'src/core-modules/authentication/authentication.guard';
import { SetMenuGuard } from 'src/core-modules/menu/setMenu.guard';
import { ContactsComponent } from './public/contacts/contacts.component';

function switchCompanyRedirect() {
  return environment.COMPANY_ABB == 'ke' || environment.COMPANY_ABB == 'swpr';
}

const routes: Routes = [
  {
    path: '',
    redirectTo: 'none-none/login',
    pathMatch: 'full',
  },
  { path: '404', component: Error404Component, canActivate: [CountryGuard] },
  {
    path: ':cultureId',
    canActivate: [CountryGuard, LocalizationGuard],
    children: [
      {
        path: '',
        redirectTo: switchCompanyRedirect() ? 'login' : 'register',
        pathMatch: 'full',
      },
      {
        path: 'login',
        data: { menu: "login", mustBeLogged: false },
        canActivate: [AuthenticationGuard, SetMenuGuard],
        component: LoginComponent,
      },
      switchCompanyRedirect()
        ? {
            path: 'register',
            pathMatch: 'full',
            redirectTo: 'login',
          }
        : {
            path: 'register',
            data: { menu: "register", mustBeLogged: false },
            canActivate: [AuthenticationGuard, SetMenuGuard],
            component: RegisterComponent,
          },
      {
        path: 'private-register',
        component: PrivateRegisterComponent,
        data: { mustBeLogged: false },
        canActivate: [AuthenticationGuard]
      },
      {
        path: 'forgot-password',
        component: RequestResetPasswordComponent,
        data: { mustBeLogged: false },
        canActivate: [AuthenticationGuard]
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent,
        data: {mustBeLogged: undefined },
        canActivate: [AuthenticationGuard]
      },
      {
        path: 'contacts',
        component: ContactsComponent,
        data: { menu: "contacts", mustBeLogged: false },
        canActivate: [AuthenticationGuard, SetMenuGuard]
      },
      {
        path: 'private-sales-preview',
        component: SaleEventsPreviewComponent,
        data: { menu: "private-sales-preview", mustBeLogged: false },
        canActivate: [AuthenticationGuard, SetMenuGuard]
      },
      {
        path: 'referral',
        component: RedeemReferralComponent,
        data: { menu: "referral", mustBeLogged: undefined },
        canActivate: [AuthenticationGuard]
      },
      {
        path: 'verify-email',
        component: VerifyUserEmailComponent,
        data: { menu: "verify-email", mustBeLogged: undefined },
        canActivate: [AuthenticationGuard]
      },
      {
        path: 'terms-and-conditions',
        component: TermsAndConditionsComponent,
      },
      {
        path: 'event',
        data: { mustBeLogged: true },
        canActivate: [AuthenticationGuard],
        loadChildren: () =>
          import('./private/private.module').then((m) => m.PrivateModule),
      },
    ],
  },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      //preloadingStrategy: PreloadAllModules
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}


