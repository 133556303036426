import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-purple-sales-button',
  templateUrl: './purple-sales-button.component.html',
  styleUrls: ['./purple-sales-button.component.scss']
})
export class PurpleSalesButtonComponent implements OnInit {

  constructor() { }
  @Input() buttonType: 'default' | 'primary' = 'default';
  @Input() buttonText: string | undefined = undefined;
  @Input() cssClass: string | undefined;
  @Input() isLoading: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() href: string | undefined = undefined;

  @Input() iconType: 'ng-zorro' | 'custom' = 'ng-zorro';
  @Input() icon: string | undefined = undefined;
  @Input() iconPosition: 'before' | 'after' = 'before';
  @Input() showOnlyIcon: boolean = false;

  ngOnInit() {
    if (!this.buttonText && !this.icon) {
      throw new Error('You must provide a buttonText or Icon');
    }
  }

  goToLink() {
    if(this.href){
      window.open(this.href, "_blank");
    }
  }

  goToPage() {
    if(this.href){
      window.location.href = this.href;
    }
  }

}
