import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BookingService, LoginResponse, UsersService } from 'src/core-modules/sdk/api';
import { PurpleApiMakeRequestResponse, PurpleApiProxyService, PurpleStorageService, PurpleTranslationPipe } from 'purple-lib';
import { PurpleSalesNotificationService } from 'src/app/components/purple-sales-notification.service';
import { AuthenticationService } from 'src/core-modules/authentication/authentication.service';

@Component({
  selector: 'app-verify-user-email',
  templateUrl: './verify-user-email.component.html',
  styleUrls: ['./verify-user-email.component.scss']
})
export class VerifyUserEmailComponent implements OnInit {

  constructor(private tsvc: AppTranslationService, private bookSvc: BookingService, private route: ActivatedRoute, private apiProxySvc: PurpleApiProxyService,
    private authSvc: AuthenticationService, private psNotSvc: PurpleSalesNotificationService, private router: Router, private tranPipe: PurpleTranslationPipe,
    private storageSvc: PurpleStorageService, private userSvc: UsersService) { }

  async ngOnInit() {
    this.route.queryParams.subscribe(async params => {
      const verifyLink = params["verificationId"];

      if (verifyLink != undefined) {
        await this.apiProxySvc.makeRequestErrorFunction<string>(() => this.bookSvc.verifyUserEmail(this.tsvc.currentLanguage.value, {
          verificationId: verifyLink,
          clientHost: window.location.hostname
        }), false, "global", 500, async (res: PurpleApiMakeRequestResponse<string>) => {
          this.psNotSvc.showMotSimpleNotification(
            this.tranPipe.transform('verify_email_link_error_title', 'Qualcosa è andato storto', []),
            res.message,
            this.tranPipe.transform('verify_email_link_error_button', 'Ok', []),
            async () => {
              await this.router.navigate([[this.tsvc.currentLanguage.value, 'event']])
            }, 'modal', true, undefined, undefined, false, false, false, false)
        }, async (res: PurpleApiMakeRequestResponse<string>) => {

          if(this.authSvc.isLoggedIn){
            this.authSvc.currentUser!.user!.email! = res.data!;
            this.authSvc.currentUser!.user!.emailVerified! = true;
            this.authSvc.currentUser!.isVerified = (this.authSvc.currentUser?.user?.phoneVerified ?? false) && (this.authSvc.currentUser?.user?.emailVerified ?? false)
  
  
            if (this.authSvc.currentUser!.isVerified) {
              await this.apiProxySvc.makeRequestErrorFunction<LoginResponse>(() => this.userSvc.getUserInfo(this.tsvc.currentLanguage.value), false,
                "internal-loader", 500, (res: PurpleApiMakeRequestResponse<LoginResponse>) => {
                  this.authSvc.logout()
                }, (res: PurpleApiMakeRequestResponse<LoginResponse>) => {
                  this.authSvc.currentUser!.token = res.data!.accessToken;
                })
            }
  
            this.storageSvc.set('CurrentUser', this.authSvc.currentUser, this.authSvc.storageMode);
          }
          
          this.psNotSvc.showMotSimpleNotification(
            this.tranPipe.transform('verify_email_link_success_title', 'Indirizzo verificato', []),
            res.message,
            this.tranPipe.transform('verify_email_link_success_button', 'Ok', []),
            async () => {
              await this.router.navigate([[this.tsvc.currentLanguage.value, 'event']])
            }, 'modal', true, undefined, undefined, false, false, false, false)
        });
      } else {
        this.psNotSvc.showMotSimpleNotification(
          this.tranPipe.transform('email_verify_link_not_valid_error_title', 'Qualcosa è andato storto', []),
          this.tranPipe.transform('email_verify_link_not_valid_error_subtitle', 'Link di verifica mail non valido', []),
          this.tranPipe.transform('verify_email_link_success_button', 'Ok', []),
          async () => {
            await this.router.navigate([[this.tsvc.currentLanguage.value, 'event']])
          }, 'modal', true, undefined, undefined, false, false, false, false)
      }
    });
  }
}
