import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/default/environment';
import { AppTranslationService } from '../localization/localization.service';
@Injectable({
  providedIn: 'root'
})
export class MembershipGuard {

  constructor(private router: Router, private tsvc: AppTranslationService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (environment.SHOW_MEMBERSHIPS) {
        return true;
      } else {
        return this.router.navigate([this.tsvc.currentLanguage.value, 'event', 'private-sales']);
      }
  }
}