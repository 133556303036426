export const SumupPay = function(checkoutId, email, amount, showEmail, locale, country, currency, showSubmitButton, fnOnLoad, fnSuccess) {
    console.log(
        {
            checkoutId: checkoutId,
            email: email,
            amount: amount,
            showEmail: showEmail,
            locale: locale,
            country: country,
            showSubmitButton: showSubmitButton,
            currency: currency
        }
    )

    
    try{
        SumUpCard.mount({
            checkoutId: checkoutId,
            email: email,
            amount: amount,
            showEmail: showEmail,
            locale: locale,
            country: country,
            currency: currency,
            showSubmitButton: showSubmitButton,
            onLoad: function () {
                fnOnLoad()
            },
            onResponse: function (type, body) {
                console.log(type);
                console.log(body);
                if (type === 'error' || (body && body.status === 'FAILED')) {
                    fnSuccess({isSuccess: false, type: type, body: body});
                    return "error";
                }
                if (type === 'success') {
                    fnSuccess({isSuccess: true, type: type, body: body});
                    return "success";
                }
            }
        })
    }catch{
        console.log("ERRORE SUMUP")
    }
    
};