import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { PurpleTranslationPipe } from 'purple-lib';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';

@Component({
  selector: 'app-404',
  templateUrl: './404.component.html',
  styleUrls: ['./404.component.scss']
})
export class Error404Component implements OnInit, OnDestroy {

  constructor(private router: Router, public tsvc: AppTranslationService, private tranPipe: PurpleTranslationPipe) { }
  subtitleText: string = this.tranPipe.transform("error_404_subtitle", "Torna alla <a [routerLink]=\"['/', tsvc.currentLanguage.value, 'login']\">HomePage</a>")

  ngOnDestroy(): void {
    //this.layoutSvc.setFooterVisibility(true);
    //this.layoutSvc.setHeaderVisibility(true);
  }

  ngOnInit() {
    //this.layoutSvc.setFooterVisibility(false);
    //this.layoutSvc.setHeaderVisibility(false);
  }

  options: AnimationOptions = {
    path: '/assets/loaders/error-404.json',
  };

  backToHome(){
    this.router.navigate([this.tsvc.currentLanguage.value, 'event', 'private-sales'])
  }
}
