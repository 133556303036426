import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NZ_MODAL_DATA, NzModalService } from 'ng-zorro-antd/modal';
import { Helper, PurpleApiMakeRequestResponse, PurpleApiProxyService, PurpleStorageService, PurpleTranslationPipe, purpleFormFieldsGroup } from 'purple-lib';
import { PurpleSalesNotificationService } from 'src/app/components/purple-sales-notification.service';
import { AuthenticationService } from 'src/core-modules/authentication/authentication.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { LoginResponse, UsersService, VerifyPhoneResponse } from 'src/core-modules/sdk/api';

@Component({
  selector: 'app-verify-account-phone-modal',
  templateUrl: './verify-account-phone-modal.component.html',
  styleUrls: ['../verify-account-modals.scss', './verify-account-phone-modal.component.scss']
})
export class VerifyAccountPhoneModalComponent implements OnInit {
  currentStep: number = 0;

  validateForm: FormGroup = this.fb.group({});
  purpleFormFieldGroups: purpleFormFieldsGroup[] | undefined;
  phonePrefixes: { label: string, value: string }[] = Helper.getPhonePrefixes().map(m => { return { label: m, value: m } });
  isLoading: boolean = false;

  showRetryButtons: boolean = false;

  phoneCode: Map<number, number | undefined> = new Map<number, number | undefined>([
    [1, undefined],
    [2, undefined],
    [3, undefined],
    [4, undefined],
    [5, undefined],
    [6, undefined]
  ]);

  constructor(@Inject(NZ_MODAL_DATA) public translations: EditPhoneNumberTranslations, private fb: FormBuilder, private authSvc: AuthenticationService, private userSvc: UsersService, private modal: NzModalService,
    private tsvc: AppTranslationService, private apiProxySvc: PurpleApiProxyService, private tranPipe: PurpleTranslationPipe, private psNotSvc: PurpleSalesNotificationService,
    private storageSvc: PurpleStorageService) { }


  ngOnInit() {

    this.purpleFormFieldGroups = [
      {
        fieldGroupNumber: 1,
        fielGroupBootstrapColumn: 12,
        fieldGroupPaddingLeft: false,
        fieldGroupPaddingRight: false,
        formFieldFormGroup: this.validateForm,
        formFields: [
          {
            fieldType: 'input-text',
            fieldControlName: 'phoneNumber',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_user_phone', 'Telefono', []),
            fieldPlaceholder: this.tranPipe.transform('edit_user_phone_placeholder', 'Telefono Utente', []),
            fieldValue: this.authSvc.currentUser?.user?.phone ?? undefined,
            fieldIsRequired: true,
            fieldIsDisabled: false,
            fieldAffix: {
              fieldControlName: "phonePrefix",
              fieldPlaceholder: this.tranPipe.transform('edit_user_phone_prefix_placeholder', 'Pref.', []),
              fieldType: "select-local",
              fieldWidth: "90px",
              fieldIsRequired: true,
              fieldIsDisabled: false,
              fieldValue: this.authSvc.currentUser?.user?.phonePrefix ?? undefined,
              fieldOptions: [
                { id: 'type', value: 'default' },
                { id: 'showArrow', value: true },
                {
                  id: 'options', value: this.phonePrefixes
                }
              ]
            },
            fieldPaddingLeft: false,
            fieldPaddingRight: false
          }
        ]
      }
    ]
  }

  async requestVerifyPhone(isRepeat: boolean) {
    this.isLoading = true;
    this.showRetryButtons = false;

    console.log("FORM: ", this.validateForm)

    for (const i in this.validateForm.controls) {
      if (this.validateForm.controls.hasOwnProperty(i)) {
        this.validateForm.controls[i].markAsDirty();
        this.validateForm.controls[i].updateValueAndValidity();
      }
    }
    if (this.validateForm.valid || isRepeat) {
      await this.apiProxySvc.makeRequestErrorFunction<void>(() => this.userSvc.requestVerifyPhone(this.tsvc.currentLanguage.value, {
        phone: this.validateForm.controls['phoneNumber'].value,
        phonePrefix: this.validateForm.controls['phonePrefix'].value
      }), false, "internal-loader", 500, (res: PurpleApiMakeRequestResponse<void>) => {
        this.psNotSvc.showMotSimpleNotification(
          this.tranPipe.transform('request_change_phone_error_title', 'Qualcosa è andato storto', []),
          res.message,
          this.tranPipe.transform('request_change_phone_error_button', 'Riprova', []),
          undefined, 'modal', true, undefined, undefined, false, false, false, false)
      }, (res: PurpleApiMakeRequestResponse<void>) => {

        if (this.currentStep == 0) {
          this.currentStep++;
        }

        setTimeout(() => {
          this.showRetryButtons = true;
        }, 30000);
      })
    }

    this.isLoading = false;
  }

  async verifyPhone() {
    this.isLoading = true;

    const check = Array.from(this.phoneCode.values()).includes(undefined);
    if (check) {
      this.psNotSvc.showMotSimpleNotification(
        this.tranPipe.transform('verify_phone_no_full_code_error_title', 'Codice incompleto', []),
        this.tranPipe.transform('verify_phone_no_full_code_error_subtitle', 'Inserisci tutti i numeri del codice di verifica per poter continuare', []),
        this.tranPipe.transform('verify_phone_no_full_code_error_button', 'Ok', []),
        undefined, 'modal', true, undefined, undefined, false, false, false, false)
      return;
    }

    const otp = "" + this.phoneCode.get(1) + this.phoneCode.get(2) + this.phoneCode.get(3) + this.phoneCode.get(4) + this.phoneCode.get(5) + this.phoneCode.get(6)

    await this.apiProxySvc.makeRequestErrorFunction<VerifyPhoneResponse>(() => this.userSvc.verifyPhone(this.tsvc.currentLanguage.value, {
      otp: otp,
      phone: this.validateForm.controls['phoneNumber'].value,
      phonePrefix: this.validateForm.controls['phonePrefix'].value,
      clientHost: window.location.hostname
    }), false, "internal-loader", 500, (res: PurpleApiMakeRequestResponse<VerifyPhoneResponse>) => {
      this.phoneCode.forEach((value: number | undefined, key: number)=>{
        this.phoneCode.set(key, undefined);
      });

      this.psNotSvc.showMotSimpleNotification(
        this.tranPipe.transform('verify_phone_error_title', 'Qualcosa è andato storto', []),
        res.message,
        this.tranPipe.transform('verify_phone_error_button', 'Riprova', []),
        undefined, 'modal', true, undefined, undefined, true, true, true, true);
        this.currentStep--;
    }, async (res: PurpleApiMakeRequestResponse<VerifyPhoneResponse>) => {
      
      this.authSvc.currentUser!.user!.phonePrefix! = res.data!.phonePrefix;
      this.authSvc.currentUser!.user!.phone! = res.data!.phone;
      this.authSvc.currentUser!.user!.phoneVerified! =true;
      this.authSvc.currentUser!.isVerified = (this.authSvc.currentUser?.user?.phoneVerified??false) && (this.authSvc.currentUser?.user?.emailVerified??false)

      if(this.authSvc.currentUser!.isVerified){
        await this.apiProxySvc.makeRequestErrorFunction<LoginResponse>(()=>this.userSvc.getUserInfo(this.tsvc.currentLanguage.value),false, 
        "internal-loader",500,(res: PurpleApiMakeRequestResponse<LoginResponse>)=>{
          this.authSvc.logout()
        },(res: PurpleApiMakeRequestResponse<LoginResponse>)=>{ 
          this.authSvc.currentUser!.token = res.data!.accessToken;
        })
      }

      this.storageSvc.set('CurrentUser', this.authSvc.currentUser, this.authSvc.storageMode);

      this.psNotSvc.showMotSimpleNotification(
        this.tranPipe.transform('verify_phone_success_title', 'Numero verificato con successo', []),
        this.tranPipe.transform('verify_phone_success_subtitle', 'Congratulazioni il tuo numero di telefono è stato verificato con successo', []),
        this.tranPipe.transform('verify_phone_success_button', 'Ok', []),
        () => {
          this.modal.closeAll();
        }, 'modal', true, undefined, undefined, true, false, true, false)
    })

    this.isLoading = false;
  }


  getPhoneDark(): string {
    const phone = this.validateForm.controls['phoneNumber'].value as string;
    const astLen = phone.length - 3;
    var res = '';
    for (let len = 0; len < astLen; len++) {
      res += "*";
    }
    res += phone.substring(phone.length - 3);
    return res;
  }

  setPhoneCode(idx: number, value: number) {
    this.phoneCode.set(idx, value);
  }

  onDigitInput(event: any, previousElement: any, nextElement: any): void {
    if (event.code !== 'Backspace' && nextElement !== null) {
      nextElement.focus();
    }
    if (event.code === 'Backspace' && previousElement !== null) {
      previousElement.focus();
      previousElement.value = '';
    }
  }

  isSubmitCodeButtonDisabled() {
    return Array.from(this.phoneCode.values()).findIndex(f => f == undefined) != -1;
  }
}


export interface EditPhoneNumberTranslations{
  title: {
    key: string,
    value: string
  },
  subtitle: {
    key: string,
    value: string
  },
  sendButton: {
    key: string,
    value: string
  },
  insertCodeTitle: {
    key: string,
    value: string
  },
  insertCodeSubtitle: {
    key: string,
    value: string
  },
  editNumberButton: {
    key: string,
    value: string
  },
  resendCodeButton: {
    key: string,
    value: string
  },
  insertCodeButton: {
    key: string,
    value: string
  }
}