import { Component, Input, OnInit } from '@angular/core';
import { PolicyQuery } from 'src/core-modules/sdk/api';

@Component({
  selector: 'app-show-policy-preview-drawer',
  templateUrl: './show-policy-preview-drawer.component.html',
  styleUrls: ['./show-policy-preview-drawer.component.scss']
})
export class ShowPolicyPreviewDrawerComponent implements OnInit {

  constructor() { }
  @Input() policy!: PolicyQuery;
  ngOnInit() {

    //console.log("POLICY: ", this.policy)
  }

}
