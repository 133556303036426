<div class="contact-form-container">
    <app-purple-sales-page-title [pageTitle]="pageTitle"></app-purple-sales-page-title>
    <div class="contact-form-box">
        <app-purple-form [disbledForm]="isLoading" class="ps-w-100" *ngIf="purpleFormFieldGroups!= undefined"
            [(formGroup)]="validateForm" [purpleFormFieldGroups]="purpleFormFieldGroups"
            (purpleFormSubmit)="sendContactForm()">
        </app-purple-form>
        <app-purple-sales-button (click)="sendContactForm()" class="auth-main-button" purpleEditableText
            tranKey='contact_page_button' [buttonText]="'contact_page_button' | translation: 'Invia il tuo messaggio'">
        </app-purple-sales-button>
    </div>

    <div class="contact-infos-container">
        <div *ngFor="let info of (contactInfo?.contactInfos??[])" class="ps-col-12 ps-col-sm-6 ps-col-md-4 contact-info-box">
            <div class="ps-w-100 ps-d-flex ps-flex-wrap">
                <h2 class="contact-info-box-city">
                    {{info.address? info.address.addressCity : ('contact_info_general' | translation: 'Generali')}}
                </h2>
                <p *ngIf="info.address" class="contact-info-box-contact">
                    <span class="contact-info-box-contact-icon" nz-icon nzType="global" nzTheme="outline"></span>
                    <span class="contact-info-box-contact-text">
                        {{info.address.addressFull}}
                    </span>
                </p>
                <p *ngIf="info.phone" class="contact-info-box-contact">
                    <span class="contact-info-box-contact-icon" nz-icon nzType="phone" nzTheme="outline"></span>
                    <span class="contact-info-box-contact-text">
                        {{info.phone}}
                    </span>
                </p>
                <p *ngIf="info.email" class="contact-info-box-contact">
                    <span class="contact-info-box-contact-icon" nz-icon nzType="mail" nzTheme="outline"></span>
                    <span class="contact-info-box-contact-text">
                        {{info.email}}
                    </span>
                </p>
            </div>
        </div>
    </div>

</div>