import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AppTranslationService } from '../localization/localization.service';

@Injectable({
  providedIn: 'root'
})
export class LocalizationGuard {

  constructor(private tranSvc: AppTranslationService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const cultureId = route.params["cultureId"];
    return this.tranSvc.checkIfCultureExist(cultureId, state.url);
  }

}
