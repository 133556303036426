<button (click)="href!=undefined?goToPage():undefined" nz-button [nzLoading]="isLoading" [disabled]="isDisabled"
  class="purple-sales-button {{buttonType}} {{cssClass}}">
  <app-purple-sales-button-icon *ngIf="icon != undefined && iconPosition == 'before'" [iconName]="icon"
    [iconPosition]="iconPosition" [iconType]="iconType" [noMargin]="buttonText!= undefined || showOnlyIcon">
  </app-purple-sales-button-icon>

  <p *ngIf="buttonText!= undefined && !showOnlyIcon" class="purple-sales-button-text">{{buttonText}}</p>

  <app-purple-sales-button-icon *ngIf="icon != undefined && iconPosition == 'after'"
    [noMargin]="buttonText!= undefined || showOnlyIcon" [iconName]="icon" [iconPosition]="iconPosition"
    [iconType]="iconType">
  </app-purple-sales-button-icon>
</button>