import { Component, Inject, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BookingService, UserExtendedReferral, SaleEventDetailSlot, RedeemReferralUserFriends } from 'src/core-modules/sdk/api';
import { AuthenticationService } from 'src/core-modules/authentication/authentication.service';
import { environment } from 'src/environments/default/environment';
import { EffectType } from '../book-slot-modal/book-slot-modal.component';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PurpleApiMakeRequestResponse, PurpleApiProxyService, PurpleSelectItem, PurpleTranslationPipe } from 'purple-lib';
import { PurpleSalesNotificationService } from 'src/app/components/purple-sales-notification.service';
@Component({
  selector: 'app-private-invite-friends-modal',
  templateUrl: './private-invite-friends-modal.component.html',
  styleUrls: ['./private-invite-friends-modal.component.scss']
})
export class PrivateInviteFriendsModalComponent implements OnInit {
  constructor(@Inject(NZ_MODAL_DATA) public data: { saleEventId: string, saleEventName: string, saleEventPhone: string, saleEventEmail: string }, public authSvc: AuthenticationService, 
    private bookSvc: BookingService, public tsvc: AppTranslationService, private mesSvc: NzMessageService, private datePipe: DatePipe, private apiProxySvc: PurpleApiProxyService,
    private tranPipe: PurpleTranslationPipe, private modal: NzModalService, private fb: FormBuilder, private modalRef:NzModalRef, private psNotSvc:PurpleSalesNotificationService) { }

  currentTab: number = 0;
  freeRefCodeNr: number = 0;
  referralFriends: UserExtendedReferral[] | undefined = undefined;
  loadEnd: boolean = false;
  isFormEnable: boolean = true;
  isLoading: boolean = true;
  firstTime: boolean = true;
  showBookOptions: boolean = false;

  validateForm!: FormGroup;
  days: PurpleSelectItem[] = [];


  slots: PurpleSelectItem[] = [];
  currentSlot: string | undefined;

  saleEventSlots: SaleEventDetailSlot[] = [];

  closeModal() {
    this.modalRef.close();
    //this.modal.destroy();
  }

  async ngOnInit() {
    this.validateForm = this.fb.group({
      firstname: [null, [Validators.required, this.validateNameAndSurname]],
      lastname: [null, [Validators.required, this.validateNameAndSurname]],
      email: [null, [Validators.email, Validators.required]],
      /* day: [null],
      slot: [{ value: null, disabled: true }] */
    });

    await this.refresh();

    this.setLoad(EffectType.up)
  }


  async refresh() {
    this.isLoading = true;
    await this.apiProxySvc.makeRequestErrorFunction<RedeemReferralUserFriends>(() => this.bookSvc.getUserFreeSaleEventReferral(this.tsvc.currentLanguage.value, {
      saleEventId: this.data.saleEventId
    }), false, "internal-loader", 500, (res: PurpleApiMakeRequestResponse<RedeemReferralUserFriends>) => {
      this.freeRefCodeNr = 0;
      this.referralFriends = [];
    }, async (res: PurpleApiMakeRequestResponse<RedeemReferralUserFriends>) => {
      this.freeRefCodeNr = res.data?.freeReferralsNr ?? 0;
      this.referralFriends = res.data?.redeemReferralFriends ?? [];
      this.referralFriends = [...res.data?.redeemReferralFriends ?? [], ...res.data?.pendingReferralUsers ?? []].sort((a, b) => a.firstName.localeCompare(b.firstName))

      if (this.firstTime) {
        if (this.freeRefCodeNr > 0 || this.freeRefCodeNr == -1) {
          this.currentTab = 0;
        } else {
          this.currentTab = 1;
        }
      }

      await this.apiProxySvc.makeRequestErrorFunction<Array<SaleEventDetailSlot>>(() => this.bookSvc.getSaleEventRoleSlots(this.tsvc.currentLanguage.value, {
        saleEventId: this.data.saleEventId,
        roleId: res.data!.referralRoleId
      }), true, "internal-loader", 500, (res: PurpleApiMakeRequestResponse<Array<SaleEventDetailSlot>>) => {
        this.saleEventSlots = [];
        this.days = [];
      }, (res: PurpleApiMakeRequestResponse<Array<SaleEventDetailSlot>>) => {
        this.saleEventSlots = res.data!;
        this.days = res.data!.map(m => {
          return {
            name: this.datePipe.transform(m.slotDayDate, "dd MMM yyyy") ?? "",
            id: m.slotDayDate,
            toTranslate: false
          }
        });
      })
    })

    setTimeout(() => {
      this.isLoading = false;
    }, 200);
  }

  async submitForm(): Promise<void> {
    this.isLoading = true;
    for (const i in this.validateForm.controls) {
      if (this.validateForm.controls.hasOwnProperty(i)) {
        this.validateForm.controls[i].markAsDirty();
        this.validateForm.controls[i].updateValueAndValidity();
      }
    }

    if (this.validateForm.valid) {
      await this.apiProxySvc.makeRequestErrorFunction<void>(() => this.bookSvc.privateRegistration(this.tsvc.currentLanguage.value, {
        saleEventId: this.data.saleEventId,
        clientHost: window.location.hostname,
        email: this.validateForm.controls["email"].value,
        firstName: this.validateForm.controls["firstname"].value,
        lastName: this.validateForm.controls["lastname"].value,
        policies: [],
        bookedSlot: undefined
      }), false, "internal-loader", 500, (res: PurpleApiMakeRequestResponse<void>) => {
        this.psNotSvc.showMotSimpleNotification(
          this.tranPipe.transform('error_message_default_title', 'Qualcosa è andato storto', []),
          res.message,
          this.tranPipe.transform('error_message_default_button', 'Ok', []),
          undefined, 'modal', true, undefined, undefined, false, false, false, false)
      }, async (res: PurpleApiMakeRequestResponse<void>) => {
        this.mesSvc.success(this.tranPipe.transform("message_invite_ok_response", "Invito inviato con successo"), {
          nzDuration: environment.MESSAGE_DURATION
        })

        this.firstTime = false;
        this.currentTab = 1;
        await this.refresh();
      })
    }

    this.isLoading = false
  }

  toggleShowBook(show:boolean){
    if(show){
      if(this.validateForm.get("day")==null){
        this.validateForm.addControl("day", new FormControl(null));
      }

      if(this.validateForm.get("slot")==null){
        this.validateForm.addControl("slot", new FormControl(null));
        this.validateForm.get("slot")!.disable();
      }
    }
  }

  selectDay(dayId: string | undefined) {
    if (dayId != undefined) {
      this.validateForm.controls["slot"].enable()
      //console.log("Slot per day: ", dayId, " - Gli Slots: ", this.saleEventSlots.filter(f => f.slotDayDate.strEq(dayId ?? ""))[0])
      this.slots = this.saleEventSlots.filter(f => f.slotDayDate.strEq(dayId ?? ""))[0].slots.map(m => {
        return {
          name: this.datePipe.transform(m.slotStartDate, "HH:mm") + " - " + this.datePipe.transform(m.slotEndDate, "HH:mm"),
          id: m.slotStartDate,
          toTranslate: false
        }
      });
      this.validateForm.get("slot")!.addValidators(Validators.required);
    }else{
      this.validateForm.get("slot")!.removeValidators(Validators.required);
      this.validateForm.controls["slot"].disable()
    }

  }

  isDisabled(controlName: string){
    if(this.validateForm.controls[controlName].disabled)
      return "disabled";
    
    return "";
  }

  removeInvite(user: UserExtendedReferral) {
    this.modal.create(
      {
        nzTitle: this.tranPipe.transform("modal_remove_invite_title", "Cancella invito"),
        nzContent: this.tranPipe.transform("modal_remove_invite_subtitle", "{0} sei sicuro di voler cancellare l'invito a <b>{1}</b>", [this.authSvc.currentUser?.user?.firstName!, user.firstName + " " + user.lastName]),
        nzWidth: '500px',
        nzClassName: 'ps-modal full-button',
        nzCentered: true,
        nzClosable: true,
        nzMaskClosable: false,
        nzOkText: this.tranPipe.transform("modal_remove_invite_button", "cancella"),
        nzCancelDisabled: true,
        nzCancelText: null,
        nzZIndex: 1000,
        nzOnOk: async () => {
          await this.apiProxySvc.makeRequestErrorFunction<void>(() => this.bookSvc.unBookGuestSlot(this.tsvc.currentLanguage.value, {
            saleEventId: this.data.saleEventId,
              userId: user.userId,
              host: window.location.hostname
          }), false, "internal-loader", 500, (res: PurpleApiMakeRequestResponse<void>) => {
            this.psNotSvc.showMotSimpleNotification(
              this.tranPipe.transform('error_message_default_title', 'Qualcosa è andato storto', []),
              res.message,
              this.tranPipe.transform('error_message_default_button', 'Ok', []),
              undefined, 'modal', true, undefined, undefined, false, false, false, false)
              this.isLoading = false;
          }, async (res: PurpleApiMakeRequestResponse<void>) => {
            this.mesSvc.success(this.tranPipe.transform("message_remove_invite_ok_response", "Invito cancellato con successo"), {
              nzDuration: environment.MESSAGE_DURATION
            })

            await this.refresh();
          })
        }
      }
    )
  }

  getInviteTagColor(isPending: boolean) {
    if (isPending) {
      return "blue"
    } else {
      return "green"
    }
  }


  getInviteText(isPending: boolean) {
    if (isPending) {
      return this.tranPipe.transform('invite_frined_pending', 'In Attesa', [])
    } else {
      return this.tranPipe.transform('invite_frined_accepted', 'Accettato', [])
    }
  }

  setLoad(type: EffectType) {
    if (type == EffectType.up) {
      this.loadEnd = false;
      setTimeout(() => {
        this.loadEnd = true;
      }, 200);
    }

    if (type == EffectType.down) {
      this.loadEnd = false;
    }

  }

  validateNameAndSurname = (control: FormControl): { [s: string]: boolean } => {
    const val = control.value as string;
    
    if(String.prototype.isNullOrEmpty(val)){
      return {}
    }

    const re = /^[\p{L}&&\p{Lu}][\p{L}]*((\s|-)[\p{L}&&\p{Lu}][\p{L}]*)*$/u;
    const res = re.test(val.trim());

    if (!res) {
      return { format: true, error: true }
    }
    return {}
  }

}
