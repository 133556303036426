import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-purple-sales-button-icon',
  templateUrl: './purple-sales-button-icon.component.html',
  styleUrls: ['./purple-sales-button-icon.component.scss']
})
export class PurpleSalesButtonIconComponent implements OnInit {

  constructor() { }
  @Input() iconType: 'ng-zorro' | 'custom' = 'ng-zorro';
  @Input() iconName!: string;
  @Input() iconPosition: 'before' | 'after' = 'before';
  @Input() noMargin: boolean = false;

  ngOnInit() {
  }

}
