<div [style.background]="hexColor" class="qr-code-container">
  <span (click)="closeDarwer()" class="qr-code-close {{layoutSvc.lightOrDark(hexColor)}}" nz-icon nzType="close" nzTheme="outline"></span>
  <div class="ps-w-100 ps-d-flex ps-flex-wrap ps-justify-content-center">
    <div class="ps-w-100 ps-d-flex ps-flex-wrap ps-justify-content-center">
      <img [contentId]="contentId" contentTypeId="logo-company" purpleEditableImage class="qr-code-logo-image" />
    </div>
    <div class="ps-w-100 ps-d-flex ps-flex-wrap ps-justify-content-center">
      <nz-qrcode [nzValue]="authSvc.currentUser?.user?.userId??''" [nzSize]="180"
      [nzColor]="layoutSvc.lightOrDark(hexColor)=='light'?'#000':'#fff'"
      [nzBgColor]="hexColor" [nzBordered]="false"
      ></nz-qrcode>
    </div>
    <div class="ps-w-100 ps-d-flex ps-flex-wrap ps-justify-content-center">
      <p class="qr-code-info-text {{layoutSvc.lightOrDark(hexColor)}}">
        {{ 'qr_code_drawer_info' | translation: 'Il tuo QR code per accedere alle sale': []}}
      </p>
    </div>
    <div class="ps-w-100 ps-d-flex ps-flex-wrap ps-justify-content-center">
      <p class="qr-code-username {{layoutSvc.lightOrDark(hexColor)}}">
        {{getName()}}
      </p>
    </div>
    <div *ngIf="membershipName != undefined" class="ps-w-100 ps-d-flex ps-flex-wrap ps-justify-content-center">
      <p class="qr-code-membership {{layoutSvc.lightOrDark(hexColor)}}">
        {{ 'qr_code_drawer_member' | translation: '{0} member': [membershipName] | uppercase}}
      </p>
    </div>
    <p *ngIf="membershipExpireDate" class="qr-code-membership-valid-until {{layoutSvc.lightOrDark(hexColor)}}">
      {{ 'qr_code_drawer_membership_valid_to' | translation: 'valido fino al {0}': [(membershipExpireDate | date: "shortDate")!]}}
    </p>
  </div>