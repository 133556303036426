import { Injectable } from '@angular/core';

import { lastValueFrom } from 'rxjs';
import { AppTranslationService } from '../localization/localization.service';
import { environment } from 'src/environments/default/environment';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { SaleEventService } from 'src/app/sale-event-service.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BookingService, CheckReferral, FeedbackPolicy, LoginResponse, UsersService } from '../sdk/api';
import { Helper, PurpleApiMakeRequestResponse, PurpleApiProxyService, PurpleApiResponseStatus, PurpleStorageService, PurpleTranslationPipe } from 'purple-lib';
import { IAuthenticatedUser } from './IAuthenticatedUser';
import { PurpleSalesNotificationService } from 'src/app/components/purple-sales-notification.service';
import { VerifyAccountModalComponent } from 'src/app/private/modals/verify-modals/verify-account-modal/verify-account-modal.component';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  isLoggedIn: boolean = false;
  currentUser: IAuthenticatedUser | undefined;
  storageMode: string = "local";

  constructor(private storageService: PurpleStorageService, private tsvc: AppTranslationService, private userSvc: UsersService, private mesSvc: NzMessageService, private jwtSvc: JwtHelperService,
    private modal: NzModalService, private tranPipe: PurpleTranslationPipe, private bookingSvc: BookingService, private seSvc: SaleEventService, private notSvc: NzNotificationService,
    private apiProxySvc: PurpleApiProxyService, private psNotSvc: PurpleSalesNotificationService) {
    this.loadFromStorage();

  }

  async requestResetPassword(email: string, clientHost: string): Promise<{isOk: boolean, message: string}> {
    const reqResp = await lastValueFrom(this.userSvc.requestResetPassword(this.tsvc.currentLanguage.value, {
      email: email,
      clientHost: clientHost
    }));

    return {isOk: reqResp.status == PurpleApiResponseStatus.Success, message: reqResp.message};
  }

  async checkResetPasswordLinkUrl(resetId: string): Promise<{ status: boolean, message: string }> {
    const reqResp = await lastValueFrom(this.userSvc.checkResetPasswordLink(this.tsvc.currentLanguage.value, {
      resetId: resetId
    }));

    return { status: (reqResp.status ?? PurpleApiResponseStatus.Error) == PurpleApiResponseStatus.Success, message: reqResp.message };
  }

  async resetPassword(email: string, resetId: string, newPassword: string): Promise<{ status: boolean, message: string }> {
    const reqResp = await lastValueFrom(this.userSvc.resetPassword(this.tsvc.currentLanguage.value, {
      email: email,
      newPassword: newPassword,
      resetId: resetId,
      clientHost: window.location.hostname
    }));

    return { status: (reqResp.status ?? PurpleApiResponseStatus.Error) == PurpleApiResponseStatus.Success, message: reqResp.message };
  }

  async changeEmail(newEmail: string, resetId: string): Promise<{ status: boolean, message: string }> {
    const reqResp = await lastValueFrom(this.userSvc.changeEmail(this.tsvc.currentLanguage.value, {
      newEmail: newEmail,
      resetId: resetId,
      clientHost: window.location.hostname
    }));

    return { status: (reqResp.status ?? PurpleApiResponseStatus.Error) == PurpleApiResponseStatus.Success, message: reqResp.message };
  }

  async requestChangeEmail(email: string, clientHost: string, isMobile: boolean): Promise<boolean> {
    const reqResp = await lastValueFrom(this.userSvc.requestChangeEmail(this.tsvc.currentLanguage.value, {
      email: email,
      newEmail: "", //TODO DA SISTEMARE COSI DA SEMPRE ERRORE
      clientHost: clientHost
    }));

    const isOk = reqResp.status == PurpleApiResponseStatus.Success;
    var title = "";

    if (isOk) {
      if (isMobile) {
        title = this.tranPipe.transform("modal_request_change_email_title_mobile_ok", "Richiesta inviata")
      } else {
        title = this.tranPipe.transform("modal_request_change_email_title_ok", "Richiesta inviata correttamente")
      }
    } else {
      if (isMobile) {
        title = this.tranPipe.transform("modal_request_change_email_title_mobile_ko", "Errore")
      } else {
        title = this.tranPipe.transform("modal_request_change_email_title_ko", "Ops! qualcosa è andato storto")
      }
    }

    this.modal.create(
      {
        nzTitle: title,
        nzContent: reqResp.message,
        nzWidth: isMobile ? '80%' : '600px',
        nzClassName: 'ps-modal',
        nzCentered: isMobile ? true : false,
        nzClosable: false,
        nzMaskClosable: false,
        nzCancelText: null,
        nzOkText: this.tranPipe.transform("request_change_email_modal_button", "Conferma")
      }
    )

    return isOk;
  }

  async checkChangeEmailLinkUrl(email: string | undefined, changeId: string | undefined): Promise<{ status: boolean, message: string }> {

    if (email == undefined) {
      return { status: false, message: this.tranPipe.transform("check_change_email_email_undefined", "Errore! Qualcosa è andato storto") };
    }

    if (changeId == undefined) {
      return { status: false, message: this.tranPipe.transform("check_change_email_changeId_undefined", "Errore! Qualcosa è andato storto") };
    }

    const reqResp = await lastValueFrom(this.userSvc.checkChangeEmailLink(this.tsvc.currentLanguage.value, {
      email: email,
      changeId: changeId
    }));

    return { status: (reqResp.status ?? PurpleApiResponseStatus.Error) == PurpleApiResponseStatus.Success, message: reqResp.message };
  }


  isAdminUser(): boolean {
    if (this.currentUser != undefined) {
      return (this.currentUser.roles??[]).findIndex(f => f.toLowerCase() == "admin") != -1;
    }

    return false;
  }

  async login(username: string, password: string, mode: string = 'local'): Promise<boolean> {
    this.isLoggedIn = false;
    this.storageMode = mode;

    await this.apiProxySvc.makeRequestErrorFunction<LoginResponse>(() => this.userSvc.login(this.tsvc.currentLanguage.value, {
      email: username,
      password: password
    }), false, "global", 500, (res: PurpleApiMakeRequestResponse<LoginResponse>) => {
      this.psNotSvc.showMotSimpleNotification(
        this.tranPipe.transform('message_login_error', 'Errore! Login non effettuato', []),
        res?.message, this.tranPipe.transform('login_error_button', 'Riprova', []), undefined, 'modal')
    }, (res: PurpleApiMakeRequestResponse<LoginResponse>) => {
      this.setUserLogin(res.data!)
    });

    return this.isLoggedIn;
  }

  setUserLogin(loginResp: LoginResponse){
    this.currentUser = {
      token: loginResp.accessToken,
      user: loginResp.user
    };
    this.storageService.set('CurrentUser', this.currentUser, this.storageMode);
    this.storageService.set('StorageMode', this.storageMode);

    const checkRoles = this.checkRoles(loginResp.accessToken);
    this.currentUser.roleLevel = checkRoles.roleLevel;
    this.currentUser.roles = checkRoles.role;

    this.currentUser.isVerified = this.checkVerified(loginResp.accessToken);

    this.isLoggedIn = this.currentUser?.token != undefined;
  }

  async register(firstname: string, lastname: string, email: string, gender: string, password: string, phone: string | undefined, phonePrefix: string | undefined, addressId: string, policies: FeedbackPolicy[],
    returnUrl: string | undefined, saleEventId: string | undefined, clientHost: string):
    Promise<registerResponse> {
    var regOk = true;
    const registerResp = await lastValueFrom(this.bookingSvc.registerBooking(this.tsvc.currentLanguage.value, {
      email: email,
      firstName: firstname,
      lastName: lastname,
      password: password,
      externalAddressId: addressId,
      phone: phone,
      phonePrefix: phonePrefix,
      returnUrl: returnUrl,
      saleEventId: saleEventId,
      clientHost: clientHost,
      gender: gender,
      policies: policies
    }))

    if (registerResp) {
      if ((registerResp.status ?? PurpleApiResponseStatus.Error) === PurpleApiResponseStatus.Success) {
        this.mesSvc.success(this.tranPipe.transform("message_register_success", "Registrazione effettuata con successo, verifica la tua mail"), {
          nzDuration: environment.MESSAGE_DURATION + 1500
        });
      } else {
        this.psNotSvc.showMotSimpleNotification(
          this.tranPipe.transform('error_message_default_title', 'Qualcosa è andato storto', []),
          registerResp.message,
          this.tranPipe.transform('error_message_default_button', 'Ok', []),
          undefined, 'modal', true, undefined, undefined, false, false, false, false)
        regOk = false;
      }
    }

    return { registerOk: regOk, returnUrl: registerResp.data ?? undefined };
  }

  logout(): boolean {
    //console.log("LOGOUT")
    this.isLoggedIn = false;
    this.currentUser = undefined;
    this.seSvc.currentSaleEvent.next(undefined);
    this.seSvc.currentSaleEventUtc.next(undefined);
    this.storageService.remove('CurrentUser', this.storageMode);
    this.storageService.remove('StorageMode', this.storageMode);
    this.storageMode = 'local';

    var ref: CheckReferral | undefined | null = Helper.storeNewObj(this.storageService.get("Referral", "session"));

    if (ref != undefined && ref != null) {
      ref.alreadyLoggedWithIncorrectDomain = false;

      this.storageService.remove("Referral", "session");
      this.storageService.set("Referral", ref, "session");
    }
    return !this.isLoggedIn;
  }

  private async loadFromStorage(): Promise<void> {
    this.storageMode = this.storageService.get<string>('StorageMode', this.storageMode);
    this.currentUser = this.storageService.get<IAuthenticatedUser>('CurrentUser', this.storageMode);

    const token = this.currentUser?.token ?? "";
    try {
      this.jwtSvc.decodeToken(token);

      if (!this.jwtSvc.isTokenExpired(token)) {
        this.isLoggedIn = true;
      } else {
        this.isLoggedIn = false;
      }


    } catch (error) {
      this.isLoggedIn = false;
    }

    if (!this.isLoggedIn) {
      this.storageService.remove('CurrentUser');
    }
    //console.log("LOAD FROM STORAGE")
  }

  checkRoles(accessToken: string): { roleLevel: number, role: string[] } {
    try {
      var t = this.jwtSvc.decodeToken(accessToken);
      var roles: string[] = [];

      if(typeof(t.role) == 'string'){
        roles.push(t.role);
      }else{
        roles = t.role
      }

      return { roleLevel: +t.RoleLevel, role: roles }
    } catch (error) {
      return { roleLevel: environment.DEFAULT_MENU_LEVEL, role: [] }
    }
  }

  checkVerified(accessToken: string): boolean {
    try {
      var t = this.jwtSvc.decodeToken(accessToken);
      var res = t.IsVerified.strEq("true");
      return res;
    } catch (error) {
      return false;
    }
  }

  checkUser(accessToken: string) {
    if (this.currentUser != undefined) {
      const checkRoles = this.checkRoles(accessToken);
      this.currentUser.roleLevel = checkRoles.roleLevel;
      this.currentUser.roles = checkRoles.role;

      this.currentUser.isVerified = this.checkVerified(accessToken);
    }
  }

  canEdit(){
    return (this.currentUser?.roleLevel??100) < 3;
  }

  async checkVerifyAccount(){
    if(this.currentUser){
      const resp = await lastValueFrom(this.userSvc.getUserInfo(this.tsvc.currentLanguage.value));
      if(resp.status == PurpleApiResponseStatus.Success){
        this.currentUser!.token = resp.data!.accessToken;

        this.currentUser!.isVerified = this.checkVerified(this.currentUser!.token);
      }
    }    
  }

  async openVerifyAccountModal(showMessage: boolean): Promise<boolean>{
    await this.checkVerifyAccount();

    if(this.currentUser?.isVerified??false){
      if(showMessage){
        this.psNotSvc.showMotSimpleNotification(
          this.tranPipe.transform('verify_account_already_verified_modal_title', 'Account già verificato', []),
          this.tranPipe.transform('verify_account_already_verified_modal_subtitle', 'Il tuo account è già stato verificato correttamente', []),
          this.tranPipe.transform('verify_account_already_verified_modal_button', 'Ok', []),
          undefined, 'modal', true, undefined, undefined, true, true, true, true)
      }
      return true;
    }else{
      this.modal.create<VerifyAccountModalComponent>({
        nzTitle: this.tranPipe.transform("verify_account_modal_title", "Verifica Profilo", []),
        nzContent: VerifyAccountModalComponent,
        nzWidth: '800px',
        nzClassName: 'purple-sales-modal',
        nzFooter: null,
        nzCentered: false,
        nzMaskClosable: false,
        nzClosable: true
      });
      return false;
    }
    
  }

}


export interface registerResponse {
  returnUrl: string | undefined;
  registerOk: boolean;
}
