import { Injectable } from '@angular/core';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzModalService } from 'ng-zorro-antd/modal';
import { BreakpointsService } from 'src/core-modules/breakpoints/breakpoints.service';
import { PurpleSalesSimpleDrawerComponent } from './purple-sales-simple-drawer/purple-sales-simple-drawer.component';

@Injectable({
  providedIn: 'root'
})
export class PurpleSalesNotificationService {

  constructor(private drawer: NzDrawerService, private modal: NzModalService, private breakSvc: BreakpointsService) { }


  showMotSimpleNotification(title: string, message: string, buttonText: string, buttonFunction?: (() => Promise<any>) | (() => any), mobileType?: 'modal' | 'drawer', autoClose?: boolean, height?:
    string, width?: string, closable?: boolean, maskClosable?: boolean, mobileClosable?: boolean, mobileMaskClosable?: boolean, cancelBottonText?: string | undefined, cancelButtonFunction?: (() => Promise<any>) | (() => any) | undefined) {
    switch (this.breakSvc.breakPointDevice$.value) {
      case 'mobile':
      case 'tablet':
        if ((mobileType ?? 'drawer') == 'drawer') {
          this.drawer.create<PurpleSalesSimpleDrawerComponent>({
            nzTitle: title,
            nzContent: PurpleSalesSimpleDrawerComponent,
            nzClosable: mobileClosable ?? false,
            nzMaskClosable: mobileMaskClosable ?? true,
            nzWrapClassName: "purple-sales-simple-drawer",
            nzPlacement: 'bottom',
            nzHeight: height ?? '60%',
            nzContentParams: {
              buttonText: buttonText,
              message: message,
              buttonFunction: buttonFunction,
              autoClose: autoClose
            }
          });
        } else {
          let mod = this.modal.create(
            {
              nzTitle: title,
              nzContent: message,
              nzWidth: width ?? '80%',
              nzClassName: 'purple-sales-simple-modal purple-sales-simple-modal-mobile',
              nzCentered: false,
              nzClosable: mobileClosable ?? true,
              nzMaskClosable: mobileMaskClosable ?? false,
              nzCancelText: cancelBottonText ?? null,
              nzOkText: buttonText,
              nzOnCancel: async () => {
                if (cancelButtonFunction != undefined) {
                  await cancelButtonFunction()
  
                  if (autoClose ?? true) {
                    mod.close()
                  }
                } else {
                  mod.close()
                }
              },
              nzOnOk: async () => {
                if (buttonFunction != undefined) {
                  await buttonFunction()

                  if (autoClose ?? true) {
                    mod.close()
                  }
                } else {
                  mod.close()
                }
              }

            }
          );
        }

        break;
      case 'desktop':
        let mod = this.modal.create(
          {
            nzTitle: title,
            nzContent: message,
            nzWidth: width ?? '600px',
            nzClassName: 'purple-sales-simple-modal',
            nzCentered: false,
            nzClosable: closable ?? true,
            nzMaskClosable: maskClosable ?? false,
            nzCancelText: cancelBottonText ?? null,
            nzOkText: buttonText,
            nzOnCancel: async () => {
              if (cancelButtonFunction != undefined) {
                await cancelButtonFunction()

                if (autoClose ?? true) {
                  mod.close()
                }
              } else {
                mod.close()
              }
            },
            nzOnOk: async () => {
              if (buttonFunction != undefined) {
                await buttonFunction()

                if (autoClose ?? true) {
                  mod.close()
                }
              } else {
                mod.close()
              }
            }

          }
        );
        break;

      default:
        break;
    }



  }

}
