import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService, registerResponse } from 'src/core-modules/authentication/authentication.service';
import { SaleEventService } from 'src/app/sale-event-service.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BookingService, PolicyItem, PRPVSaleEventFull, FeedbackPolicy, PurpleSelectPagedResponsePurpleApiResponse } from 'src/core-modules/sdk/api';
import { environment } from 'src/environments/default/environment';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ReferralService } from 'src/app/referral/referral.service';
import { of, Subscription } from 'rxjs';
import { PolicyAndUserAttributesService } from 'src/app/policy-and-user-attributes-service';
import { Guid, Helper, PurpleSelectFilter, PurpleTranslationPipe, purpleFormFieldsGroup } from 'purple-lib';
import { pageTitle } from 'src/app/components/purple-sales-page-title/purple-sales-page-title.component';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['../authentication.scss', './register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {

  seId: string = "";
  fullAddressPlaceholder: string = "";
  companyName = environment.COMPANY_NAME ?? "purple_sales_backoffice";
  isLoading: boolean = false;

  validateForm: FormGroup = this.fb.group({});
  purpleFormFieldGroups: purpleFormFieldsGroup[] | undefined = [];

  phonePrefixes: { label: string, value: string }[] = Helper.getPhonePrefixes().map(m => { return { label: m, value: m } });
  policies: PolicyItem[] = [];

  registerBookingPolicies: FeedbackPolicy[] = [];
  subs: Subscription = new Subscription();

  pageTitle: pageTitle = {
    allRounded: 'allround',
    title: this.tranPipe.transform("register_title_new", "Benvenuto in {0}!", [this.companyName]),
    titleTranslationKey: 'register_title_new',
    subtitle:
    {
      subtitle: this.tranPipe.transform("register_subtitle", "Registrati se non hai un account saywow", [this.companyName]),
      subtitleTranslationKey: 'register_subtitle'
    },
    textAlign: 'center',
  }
  universityService: UniversityService | undefined;

  constructor(private fb: FormBuilder, private authenticationService: AuthenticationService, private bookSvc: BookingService, private router: Router, public policySvc: PolicyAndUserAttributesService,
    public tsvc: AppTranslationService, private route: ActivatedRoute, public seSvc: SaleEventService, private tranPipe: PurpleTranslationPipe, private refSvc: ReferralService) {
    this.fullAddressPlaceholder = environment.REGISTRATION_FULL_ADDRESS ? this.tranPipe.transform('register_form_address', 'Indirizzo (Opzionale)', []) : this.tranPipe.transform('register_form_city', 'Città (Opzionale)', [])

  }

  async ngOnInit(): Promise<void> {

    this.route.queryParams.subscribe(params => {
      this.seSvc.getSaleEventParameters(params)
    });

    this.subs.add(this.seSvc.currentSaleEvent.subscribe((saleEvent: PRPVSaleEventFull | undefined) => {
      this.seId = saleEvent?.saleEventId ?? Guid.empty();
    }));

    this.subs.add(this.policySvc.publicPolicies.subscribe(s => {
      this.policies = (s.filter(f => f.pageName?.strEq('register')))[0]?.policies ?? [];

      this.policies.forEach(policy => {
        this.validateForm.addControl(policy.policyId, new FormControl(false, policy.isMandatoryPolicy ? Validators.requiredTrue : null));
      });
    }));

    this.purpleFormFieldGroups = [
      {
        fieldGroupNumber: 1,
        fielGroupBootstrapColumn: 12,
        fieldGroupPaddingLeft: false,
        fieldGroupPaddingRight: false,
        formFieldFormGroup: this.validateForm,
        formFields: [
          {
            fieldType: 'input-text',
            fieldControlName: 'firstname',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_user_name', 'Nome', []),
            fieldPlaceholder: this.tranPipe.transform('edit_user_name_placeholder', 'Nome utente', []),
            fieldValue: undefined,
            fieldIsRequired: true,
            fieldPaddingLeft: false,
            fieldPaddingRight: false
          },
          {
            fieldType: 'input-text',
            fieldControlName: 'lastname',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_user_lastname', 'Cognome', []),
            fieldPlaceholder: this.tranPipe.transform('edit_user_lastname_placeholder', 'Cognome utente', []),
            fieldValue: undefined,
            fieldIsRequired: true,
            fieldPaddingLeft: false,
            fieldPaddingRight: false
          },
          {
            fieldType: 'input-text',
            fieldControlName: 'email',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_user_email', 'Email', []),
            fieldPlaceholder: this.tranPipe.transform('edit_user_email_placeholder', 'Indirizzo Email', []),
            fieldValue: undefined,
            fieldIsRequired: true,
            fieldCustomValidators: [Validators.email],
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: false
          },
          {
            fieldType: 'input-text',
            fieldControlName: 'phoneNumber',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_user_phone', 'Telefono', []),
            fieldPlaceholder: this.tranPipe.transform('edit_user_phone_placeholder', 'Telefono Utente', []),
            fieldValue: undefined,
            fieldIsRequired: true,
            fieldIsDisabled: false,
            fieldAffix: {
              fieldControlName: "phonePrefix",
              fieldPlaceholder: this.tranPipe.transform('edit_user_phone_prefix_placeholder', 'Pref.', []),
              fieldType: "select-local",
              fieldWidth: "90px",
              fieldIsRequired: true,
              fieldIsDisabled: false,
              fieldValue: environment.DEFAULT_PHONE_PREFIX,
              fieldOptions: [
                { id: 'type', value: 'default' },
                { id: 'showArrow', value: false },
                {
                  id: 'options', value: this.phonePrefixes
                }
              ]
            },
            fieldPaddingLeft: false,
            fieldPaddingRight: false
          },
          {
            fieldType: 'input-text',
            fieldControlName: 'password',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('register_page_form_password', 'Password', []),
            fieldPlaceholder: this.tranPipe.transform('register_page_form_password_placeholder', 'Password', []),
            fieldValue: undefined,
            fieldIsRequired: true,
            fieldPaddingLeft: false,
            fieldPaddingRight: false,
            fieldOptions: [{ id: 'isPassword', value: true }],
            fieldCustomValidators: [this.validatePassword],
            fieldCustomValidatorErrors: [{ errorId: "format", text: this.tranPipe.transform("register_invalid_password_validator_error_text", "La password deve contenere almeno 8 caratteri, una lettera maiuscola e un carattere speciale (,;.!@#$%^&*)") }]
          },
          {
            fieldType: 'input-text',
            fieldControlName: 'checkPassword',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('register_page_form_check_password', 'Conferma password', []),
            fieldPlaceholder: this.tranPipe.transform('register_page_form_check_password_placeholder', 'Conferma password', []),
            fieldValue: undefined,
            fieldIsRequired: true,
            fieldPaddingLeft: false,
            fieldPaddingRight: false,
            fieldCustomValidators: [this.confirmationValidator],
            fieldCustomValidatorErrors: [{ errorId: "match", text: this.tranPipe.transform("invalid_email_match_validator_error_text", "Le password inserite non corrispondono") }],
            fieldOptions: [{ id: 'isPassword', value: true }]
          },
          {
            fieldType: 'select-local',
            fieldControlName: 'gender',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_user_gender', 'Sesso', []),
            fieldPlaceholder: this.tranPipe.transform('edit_user_gender_placeholder', 'Sesso utente', []),
            fieldValue: undefined,
            fieldIsRequired: true,
            fieldOptions: [
              { id: 'type', value: 'default' },
              { id: 'showArrow', value: true },
              {
                id: 'options', value: [
                  {
                    label: this.tranPipe.transform('edit_user_gender_man', 'Uomo', []),
                    value: "M"
                  },
                  {
                    label: this.tranPipe.transform('edit_user_gender_female', 'Donna', []),
                    value: "F"
                  },
                  {
                    label: this.tranPipe.transform('edit_user_gender_other', 'Altro', []),
                    value: "O"
                  }
                ]
              }
            ],
            fieldPaddingLeft: false,
            fieldPaddingRight: false
          },
          {
            fieldType: 'select',
            fieldControlName: 'address',
            fieldBootstrapColumn: 12,
            fieldName: environment.REGISTRATION_FULL_ADDRESS ? this.tranPipe.transform('edit_profile_form_address_title_full', 'Indirizzo Residenza', []) : this.tranPipe.transform('edit_profile_form_address_title_city', 'Città', []),
            fieldPlaceholder: environment.REGISTRATION_FULL_ADDRESS ? this.tranPipe.transform('register_form_address', 'Indirizzo Residenza (Obbligatorio)', []) : this.tranPipe.transform('register_form_city', 'Città (Obbligatoria)', []),
            fieldValue: undefined,
            fieldIsRequired: true,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: false,
            fieldOptions: [
              { id: 'searchFunction', value: this.addressSearchFunction },
              { id: 'searchByIdFunction', value: this.addressSearchIdFunction },
            ]
          },

        ]

      }

    ]
    //if environment.REGISTRATION_HOME_IF_DIFFERENT is true, the user can insert a different address from the one of residence
    if (environment.REGISTRATION_HOME_IF_DIFFERENT) {
      this.purpleFormFieldGroups[0].formFields.push(
        {
          fieldType: 'select',
          fieldControlName: 'addressHomeIfDifferent',
          fieldBootstrapColumn: 12,
          fieldName: environment.REGISTRATION_FULL_ADDRESS ? this.tranPipe.transform('edit_profile_form_address_home_title_full', 'Indirizzo Domicilio', []) : this.tranPipe.transform('register_form_home_city', 'Città Domicilio', []),
          fieldPlaceholder: environment.REGISTRATION_FULL_ADDRESS ? this.tranPipe.transform('register_form_address_home', 'Indirizzo Domicilio (richiesto per i fuori sede)', []) : this.tranPipe.transform('register_form_home_city_placeholder', 'Città Domicilio (Obbligatoria per i fuori sede)', []),
          fieldValue: undefined,
          fieldIsRequired: false,
          fieldIsDisabled: false,
          fieldPaddingLeft: false,
          fieldPaddingRight: false,
          fieldOptions: [
            { id: 'searchFunction', value: this.addressHomeSearchFunction },
            { id: 'searchByIdFunction', value: this.addressHomeSearchIdFunction },
          ]
        });
    }
    if (environment.COMPANY_ABB === 'un') {


      this.purpleFormFieldGroups[0].formFields.push(
        {
          fieldType: 'select-local',
          fieldControlName: 'customUniversityName',
          fieldBootstrapColumn: 12,
          fieldName: this.tranPipe.transform('register_form_custom_universityName', 'Università', []),
          fieldPlaceholder: this.tranPipe.transform('register_form_custom_universityName_placeholder', 'Nome Università', []),
          fieldValue: undefined,
          fieldIsRequired: true,
          fieldIsDisabled: false,
          fieldPaddingLeft: false,
          fieldPaddingRight: false,
          fieldOptions: [
            { id: 'isMultiple', value: false },
            { id: 'showArrow', value: true },
            { id: 'enableSearch', value: true },
            { id: 'type', value: 'tags' },         
            {
              id: 'options', value: []

            }
          ]
        });

      this.purpleFormFieldGroups[0].formFields.push(
        {
          fieldType: 'select-local',
          fieldControlName: 'customUniversityFaculty',
          fieldBootstrapColumn: 12,
          fieldName: this.tranPipe.transform('register_form_custom_universityFaculty', 'Facoltà', []),
          fieldPlaceholder: this.tranPipe.transform('register_form_custom_universityFaculty_placeholder', 'Nome Facoltà', []),
          fieldValue: undefined,
          fieldIsRequired: true,
          fieldIsDisabled: false,
          fieldPaddingLeft: false,
          fieldPaddingRight: false,
          fieldOptions: [
            { id: 'isMultiple', value: false },
            { id: 'showArrow', value: true },
            { id: 'enableSearch', value: true },
            { id: 'type', value: 'tags' },         
            {
              id: 'options', value: []
            }
          ]
        });
      this.purpleFormFieldGroups[0].formFields.push(
        {
          fieldType: 'select-local',
          fieldControlName: 'customUniversityCourse',
          fieldBootstrapColumn: 12,
          fieldName: this.tranPipe.transform('register_form_custom_universityCourse', 'Corso', []),
          fieldPlaceholder: this.tranPipe.transform('register_form_custom_universityCourse_placeholder', 'Corso di laurea', []),
          fieldValue: undefined,
          fieldIsRequired: false,
          fieldIsDisabled: false,
          fieldPaddingLeft: false,
          fieldPaddingRight: false,
          fieldOptions: [
            { id: 'isMultiple', value: false },
            { id: 'showArrow', value: true },
            { id: 'enableSearch', value: true },
            { id: 'type', value: 'tags' },         
            {
              id: 'options', value: []
            }
          ]
        });

    }
    if (environment.COMPANY_ABB === 'un') {
      this.universityService = new UniversityService();
      this.universityService.initializeUniversities().then(() => {
        this.purpleFormFieldGroups![0].formFields.find(f => f.fieldControlName === 'customUniversityName')!.fieldOptions!.find(f => f.id === 'options')!.value
          = this.universityService?.distinctUniversityNames.map(m => { return { label: m, value: m } });

        this.validateForm.controls['customUniversityName'].valueChanges.subscribe(uniSelectName => {
          if (uniSelectName) {
            console.log("🚀 ~ RegisterComponent ~ this.customUniversityName.valueChanges.subscribe ~ NEW value:", uniSelectName)
            
            const formField = this.purpleFormFieldGroups![0].formFields.find(f => f.fieldControlName === 'customUniversityFaculty')!;
            console.log("🚀 ~ RegisterComponent ~ this.customUniversityName.valueChanges.subscribe ~ formField:", formField, uniSelectName)
            //if value is an array take the first element
            let safeValue = uniSelectName;
            if (Array.isArray(uniSelectName)) {
              safeValue = uniSelectName[0];
            }
            formField.fieldOptions!.find(f => f.id === 'options')!.value
              = this.universityService?.getUniversityFaculties(safeValue).map(m => { return { label: m, value: m } });
            this.validateForm.controls['customUniversityFaculty'].setValue(undefined);
            this.validateForm.controls['customUniversityFaculty'].reset();
            this.validateForm.controls['customUniversityCourse'].setValue(undefined);
            this.validateForm.controls['customUniversityCourse'].reset();
          }
        });

        this.validateForm.controls['customUniversityFaculty'].valueChanges.subscribe(uniFacultyNameSelect => {
          if (uniFacultyNameSelect) {

            let universityFacultyName = uniFacultyNameSelect;
            if (Array.isArray(uniFacultyNameSelect)) {
              universityFacultyName = uniFacultyNameSelect[0];
            }

            let universityName = this.validateForm.controls['customUniversityName'].value
            if (Array.isArray(universityName)) {
              universityName = universityName[0];
            }

            console.log("🚀 ~ RegisterComponent ~ this.customUniversityFaculty.valueChanges.subscribe ~ value:", uniFacultyNameSelect)
            this.purpleFormFieldGroups![0].formFields.find(f => f.fieldControlName === 'customUniversityCourse')!.fieldOptions!.find(f => f.id === 'options')!.value
              = this.universityService?.getUniversityCourses(universityName, universityFacultyName).map(m => { return { label: m, value: m } });
            this.validateForm.controls['customUniversityCourse'].setValue(undefined);
            this.validateForm.controls['customUniversityCourse'].reset();
          }
        });
      });



    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  async register(): Promise<void> {
    for (const i in this.validateForm.controls) {
      if (i.startsWith('custom') && this.validateForm.controls[i].value) continue;
      console.log ("🚀 ~ RegisterComponent ~ register ~ i:", i, this.validateForm.controls[i])
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    if (this.validateForm.valid) {

      this.policies.forEach(policy => {
        this.registerBookingPolicies.push({
          policyId: policy.policyId,
          policyCheck: this.validateForm.controls[policy.policyId].value
        })
      });

      let customPayload = {};
      customPayload['returnUrl'] = this.seSvc.returnUrl;
      //check if this.validateForm.controls["addressHomeIfDifferent"] exists and has a value, if so add it to the customPayload
      if (environment.REGISTRATION_HOME_IF_DIFFERENT) {
        const dAddress = this.validateForm.controls["addressHomeIfDifferent"].value
        if (dAddress && dAddress.length > 0) {
          customPayload['addressHomeIfDifferent'] = dAddress;
        }
      }
      if (environment.COMPANY_ABB === 'un') {
        customPayload['custom_universityName'] = this.validateForm.controls["customUniversityName"].value;
        if (Array.isArray(customPayload['custom_universityName'])) {
          customPayload['custom_universityName'] = customPayload['custom_universityName'][0];
        }
        customPayload['custom_faculty'] = this.validateForm.controls["customUniversityFaculty"].value;
        if (Array.isArray(customPayload['custom_faculty'])) {
          customPayload['custom_faculty'] = customPayload['custom_faculty'][0];
        }
        customPayload['custom_course'] = this.validateForm.controls["customUniversityCourse"].value;
        if (Array.isArray(customPayload['custom_course'])) {
          customPayload['custom_course'] = customPayload['custom_course'][0];
        }
      }

      const customPayloadJson = JSON.stringify(customPayload);
      console.log("🚀 ~ RegisterComponent ~ register ~ customPayloadJson:", customPayloadJson)

      await this.authenticationService.register(
        (this.validateForm.controls["firstname"].value as string).trim(),
        (this.validateForm.controls["lastname"].value as string).trim(),
        (this.validateForm.controls["email"].value as string).trim(),
        this.validateForm.controls["gender"].value ?? 'O',
        (this.validateForm.controls["password"].value as string).trim(),
        this.validateForm.controls["phoneNumber"].value ?? undefined,
        this.validateForm.controls["phonePrefix"].value ?? undefined,
        this.validateForm.controls["address"].value,
        this.registerBookingPolicies,
        customPayloadJson,
        this.seSvc.currentSaleEvent.value?.saleEventId ?? undefined,
        window.location.hostname
      ).then(
        async (registerRes: registerResponse) => {
          if (registerRes.registerOk) {
            this.authenticationService.login((this.validateForm.controls["email"].value as string).trim(), (this.validateForm.controls["password"].value as string).trim()).then(
              async (authenticated: boolean) => {
                if (authenticated) {
                  await this.refSvc.reedemReferralCode();

                  if (this.seSvc.returnUrl != undefined) {
                    await this.router.navigateByUrl(this.seSvc.returnUrl);
                  } else {
                    await this.router.navigate([this.tsvc.currentLanguage.value, 'event']);
                  }
                }
              }
            )
          }
        }
      )
    }
  }

  //#region Address
  addressSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    if (environment.REGISTRATION_FULL_ADDRESS) {
      return this.bookSvc.getAddressByNameSelect(args.culture, args)
    } else {
      return this.bookSvc.getCityByNameSelect(args.culture, args)
    }
  }

  addressSearchIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    if (environment.REGISTRATION_FULL_ADDRESS) {
      return this.bookSvc.getAddressByNameSelect(args.culture, args)
    } else {
      return this.bookSvc.getCityByIdSelect(args.culture, args)
    }
  }
  //#endregion

  //#region Address
  addressHomeSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    if (environment.REGISTRATION_FULL_ADDRESS) {
      return this.bookSvc.getAddressByNameSelect(args.culture, args)
    } else {
      return this.bookSvc.getCityByNameSelect(args.culture, args)
    }
  }

  addressHomeSearchIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    if (environment.REGISTRATION_FULL_ADDRESS) {
      return this.bookSvc.getAddressByNameSelect(args.culture, args)
    } else {
      return this.bookSvc.getCityByIdSelect(args.culture, args)
    }
  }
  //#endregion




  validatePassword = (control: AbstractControl): ValidationErrors | null => {
     // Controlla che la password sia lunga almeno 8 caratteri
     const minLength = /^.{8,}$/;
    
     // Controlla che ci sia almeno una lettera minuscola
     const hasLowerCase = /[a-z]/;
     
     // Controlla che ci sia almeno un numero
     const hasNumber = /\d/;
     
     // Controlla che ci sia almeno un carattere speciale (non lettera e non numero)
     const hasSpecialChar = /[^a-zA-Z0-9]/;
 
     // Verifica che la password soddisfi tutte le condizioni
     const res = minLength.test(control.value) &&
            hasLowerCase.test(control.value) &&
            hasNumber.test(control.value) &&
            hasSpecialChar.test(control.value);
    
    if (!res) {
      return { format: true }
    }
    return {}
  }

  confirmationValidator = (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return { required: true };
    } else if (control.value !== this.validateForm.controls['password'].value) {
      return { match: true };
    }
    return {};
  };


}

interface University {
  city: string;
  course: string;
  faculty: string;
  logo: string;
  name: string;
}

class UniversityService {
  universities: University[] = [];
  distinctUniversityNames: string[] = [];

  constructor() {


  }

  initializeUniversities(): Promise<void> {
    if (environment.COMPANY_ABB === 'un') {
      const universitiesData = import('../../../assets/custom/un/university_names.json').then(data => {
        this.universities = data.default || universitiesData;
        this.distinctUniversityNames = this.extractDistinctUniversityNames();
        console.log("🚀 ~ UniversityService ~ constructor ~ this.distinctUniversityNames:", this.distinctUniversityNames)
      });
      return universitiesData;
    } else {
      this.universities = [];
      return Promise.resolve();
    }
  }

  // Funzione per estrarre nomi distinti di università
  extractDistinctUniversityNames(): string[] {
    const universityNames = this.universities.map(university => university.name);


    const res = Array.from(new Set(universityNames));  // Rimuove i duplicati
    //ordina ascendentemente secondo il nome dell'università
    res.sort((a, b) => a.localeCompare(b));

    return res;
  }

  getUniversityFaculties(universityName: string): string[] {

    console.log("🚀 ~ UniversityService ~ getUniversityFaculties:")
    console.log("🚀 ~ UniversityService ~ universityName:", universityName)
    // Raccoglie tutte le facoltà associate all'università selezionata
    let filteredFaculties = this.universities
      .filter(univ => univ.name === universityName)
      .map(univ => univ.faculty);

    // Rimuovi i duplicati utilizzando Set e ordina le facoltà alfabeticamente
    filteredFaculties = Array.from(new Set(filteredFaculties)).sort((a, b) => a.localeCompare(b));
    return filteredFaculties;
  };

  // Nuova funzione per ottenere i corsi filtrati per università e facoltà
  getUniversityCourses(universityName: string, facultyName: string): string[] {
    console.log("🚀 ~ UniversityService ~ getUniversityCourses:");
    console.log("🚀 ~ UniversityService ~ universityName:", universityName);
    console.log("🚀 ~ UniversityService ~ facultyName:", facultyName);

    // Raccoglie tutti i corsi associati all'università e facoltà selezionata
    let filteredCourses = this.universities
      .filter(univ => univ.name === universityName && univ.faculty === facultyName)
      .map(univ => univ.course);

    // Rimuovi i duplicati utilizzando Set e ordina i corsi alfabeticamente
    filteredCourses = Array.from(new Set(filteredCourses)).sort((a, b) => a.localeCompare(b));
    return filteredCourses;
  }


}