<div
  class="account-need-verification-banner-container {{(bannerSvc.bannerVisible && !(authSvc.currentUser?.isVerified??false))?'':'no-show'}}">
  <div class="account-need-verification-banner-internal-container">
    <p class="account-need-verification-banner-text">
      {{ 'account_need_verification_banner' | 
      translation: 'Per prenotarti alle private sale è necessario effettuare la verifica dell\'email e del telefono': []}}
    </p>

    <app-purple-sales-button class="account-need-verification-banner-button" (click)="openVerify()" purpleEditableText
      tranKey='account_need_verification_banner_button'
      [buttonText]="'account_need_verification_banner_button' | translation: 'Verifica ora'">
    </app-purple-sales-button>
  </div>
</div>