<container-element [ngSwitch]="policy.policyTypeId">

  <ng-container *ngSwitchCase="'html'">
    <div [innerHtml]="policy.policyHtml! | safeHtml" class="show-policy-preview-container">
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'text'">
    <div class="show-policy-preview-container">
      {{policy.policyText}}
    </div>
  </ng-container>
</container-element>
