<div class="instructions-modal-container ps-d-flex ps-algin-items-start ps-w-100">
  <div class="ps-col-6 ps-d-flex ps-justify-content-end">
    <div class="ps-d-flex ps-flex-wrap ps-align-self-start">
      <div class="ps-w-100 ps-d-flex ps-justify-content-center">
        <img contentId="instruction-print" contentTypeId="instruction-image" purpleEditableImage
        class="instructions-image" />
      </div>
      <p purpleEditableText tranKey='instructions_modal_print' class="ps-w-100 ps-text-center ps-mb-0 instructions-label">
        {{ 'instructions_modal_print' | translation: 'Stampa il biglietto con il qr code della tua prenotazione': []}}
      </p>
    </div>
  </div>
  <div class="ps-col-6 ps-d-flex ps-justify-content-start">
    <div class="ps-d-flex ps-flex-wrap ps-align-self-start">
      <div class="ps-w-100 ps-d-flex ps-justify-content-center">
        <img contentId="instruction-qr" contentTypeId="instruction-image" purpleEditableImage
        class="instructions-image" />
      </div>
      <p purpleEditableText tranKey='instructions_modal_show_qr_code' class="ps-w-100 ps-text-center ps-mb-0 instructions-label">
        {{ 'instructions_modal_show_qr_code' | translation: 'Mostra il qr code all’ingresso della svendita': []}}
      </p>
    </div>
  </div>
</div>
