import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { AuthenticationService } from './authentication.service';
import { AppTranslationService } from '../localization/localization.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LayoutService } from 'src/app/layout/layout.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard {
  constructor(private router: Router, private authenticationService: AuthenticationService,
    private tsvc: AppTranslationService, private jwtSvc: JwtHelperService, private layoutSvc: LayoutService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const mustBeLogged: boolean = route.data['mustBeLogged'];
    const token = this.authenticationService.currentUser?.token ?? "";

    if (mustBeLogged == undefined || mustBeLogged == null) {
      return true;
    }

    try {
      if (!this.jwtSvc.isTokenExpired(token)) {
        this.authenticationService.checkUser(token);
        if (mustBeLogged) {
          this.layoutSvc.setCurrentActiveMenu(true)
          return true;
        } else {
          this.layoutSvc.setCurrentActiveMenu(true)
          return this.router.createUrlTree(['/' + this.tsvc.currentLanguage.value, 'event', 'private-sales'], { queryParams: { redirectUrl: state.url } });
        }
      } else {
        if (mustBeLogged) {
          this.layoutSvc.setCurrentActiveMenu(false)
          return this.router.navigate([this.tsvc.currentLanguage.value, 'login'], { queryParams: { redirectUrl: state.url } });
        } else {
          this.layoutSvc.setCurrentActiveMenu(false)
          return true;
        }
      }
    } catch (error) {
      if (mustBeLogged) {
        this.layoutSvc.setCurrentActiveMenu(false)
        return this.router.navigate([this.tsvc.currentLanguage.value, 'login'], { queryParams: { redirectUrl: state.url } });
      } else {
        this.layoutSvc.setCurrentActiveMenu(false)
        return true;
      }
    }
  }
}
