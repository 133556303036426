import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { PrivateRoutingModule } from './private-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { PrivateSaleEventsPreviewComponent } from './private-sale-events-preview/private-sale-events-preview.component';
import { PrivateSaleEventDetailComponent } from './private-sale-event-detail/private-sale-event-detail.component';
import { NzAnchorModule } from 'ng-zorro-antd/anchor';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { BookSlotModalComponent } from './modals/book-slot-modal/book-slot-modal.component';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { BookSlotDrawerComponent } from './drawers/book-slot-drawer/book-slot-drawer.component';
import { QrCodeModule } from 'ng-qrcode';
import { QrCodeDrawerComponent } from './drawers/qr-code-drawer/qr-code-drawer.component';
import { PrivateMyReservationsComponent } from './private-my-reservations/private-my-reservations.component';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import player from 'lottie-web';
import { InstructionsModalComponent } from './modals/instructions-modal/instructions-modal.component';
import { InstructionsDrawerComponent } from './drawers/instructions-drawer/instructions-drawer.component';
import { QrCodeModalComponent } from './modals/qr-code-modal/qr-code-modal.component';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { SendEmailInviteModalComponent } from './modals/send-email-invite-modal/send-email-invite-modal.component';
import { InviteFriendsDrawerComponent } from './drawers/invite-friends-drawer/invite-friends-drawer.component';
import { InviteFriendsModalComponent } from './modals/invite-friends-modal/invite-friends-modal.component';
import { BaseSaleEventDetailBannerComponent } from './private-sale-event-detail/base-sale-event-detail-banner/base-sale-event-detail-banner.component';
import { AdvancedSaleEventDetailBannerComponent } from './private-sale-event-detail/advanced-sale-event-detail-banner/advanced-sale-event-detail-banner.component';
import { PrivateUserProfileComponent } from './private-user-profile/private-user-profile.component';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { ShowPolicyPreviewModalComponent } from './modals/show-policy-preview-modal/show-policy-preview-modal.component';
import { ShowPolicyPreviewDrawerComponent } from './drawers/show-policy-preview-drawer/show-policy-preview-drawer.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { PrivateInviteFriendsDrawerComponent } from './drawers/private-invite-friends-drawer/private-invite-friends-drawer.component';
import { PrivateInviteFriendsModalComponent } from './modals/private-invite-friends-modal/private-invite-friends-modal.component';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { PurpleDatePipe, PurpleEditImageModule, PurpleEditTextModule, PurpleFormModule, PurpleGeneralDirectiveModule, PurplePipeModule, PurpleSelectModule } from 'purple-lib';
import { PurpleProjectWidgetsModule } from 'src/purple-widgets/purple-project-widgets.module';
import { PurpleSalesComponentsModule } from '../components/purple-sales-components.module';
import { NguCarousel, NguTileComponent, NguCarouselDefDirective, NguCarouselNextDirective, NguCarouselPrevDirective, NguItemComponent } from '@ngu/carousel';
import { VerifyAccountModalComponent } from './modals/verify-modals/verify-account-modal/verify-account-modal.component';
import { VerifyAccountEmailModalComponent } from './modals/verify-modals/verify-account-email-modal/verify-account-email-modal.component';
import { MembershipPaymentComponent } from './payments/membership-payment/membership-payment.component';
import { PrivateUserProfileTitleComponent } from './private-user-profile/private-user-profile-title/private-user-profile-title.component';
import { VerifyAccountPhoneModalComponent } from './modals/verify-modals/verify-account-phone-modal/verify-account-phone-modal.component';
import { PrivateMembershipComponent } from './private-membership/private-membership.component';
import { PrivateMembershipDetailComponent } from './private-membership-detail/private-membership-detail.component';
import { MembershipPaymentInfoCheckModalComponent } from './modals/membership-modals/membership-payment-info-check-modal/membership-payment-info-check-modal.component';
import { RegisterUserMemebershipModalComponent } from './modals/membership-modals/register-user-memebership-modal/register-user-memebership-modal.component';
import { PrivateMyReservationV2Component } from './private-my-reservation-v2/private-my-reservation-v2.component';
import { NzQRCodeModule } from 'ng-zorro-antd/qr-code';
import { LottieComponent, provideLottieOptions } from 'ngx-lottie';

@NgModule({
  declarations: [PrivateSaleEventsPreviewComponent, PrivateSaleEventDetailComponent, BookSlotModalComponent, SendEmailInviteModalComponent,InviteFriendsDrawerComponent, PrivateInviteFriendsModalComponent,
    BaseSaleEventDetailBannerComponent, AdvancedSaleEventDetailBannerComponent, BookSlotDrawerComponent, QrCodeDrawerComponent, PrivateMyReservationsComponent, InstructionsModalComponent,  ShowPolicyPreviewDrawerComponent,
    InstructionsDrawerComponent, QrCodeModalComponent, InviteFriendsModalComponent, PrivateUserProfileComponent,  ShowPolicyPreviewModalComponent, PrivateInviteFriendsDrawerComponent,
    VerifyAccountModalComponent, VerifyAccountEmailModalComponent, MembershipPaymentComponent, PrivateUserProfileTitleComponent, VerifyAccountPhoneModalComponent, PrivateMembershipComponent,  MembershipPaymentInfoCheckModalComponent,
    PrivateMembershipDetailComponent, RegisterUserMemebershipModalComponent, PrivateMyReservationV2Component],
  imports: [
    PrivateRoutingModule,
    CommonModule,
    FormsModule,
    NzButtonModule,
    NzCheckboxModule,
    NzFormModule,
    NzInputModule,
    NzSelectModule,
    ReactiveFormsModule,
    NzLayoutModule,
    PurplePipeModule,
    NzDropDownModule,
    PurpleGeneralDirectiveModule,
    PurpleEditImageModule,
    PurpleEditTextModule,
    NzButtonModule,
    NzAnchorModule,
    NzIconModule,
    NzCollapseModule,
    NzSwitchModule,
    QrCodeModule,
    NzTabsModule,
    NzPopoverModule,
    NzToolTipModule,
    NzBadgeModule,
    NzDatePickerModule,
    PurpleSelectModule,
    MatExpansionModule,
    NzTagModule,
    NzNotificationModule,
    PurplePipeModule,
    PurpleProjectWidgetsModule,
    NzAlertModule,
    NzQRCodeModule,
    PurpleSalesComponentsModule,
    NzStepsModule,
    NzSpinModule,
    PurpleFormModule,
    LottieComponent,

    NguCarousel,
    NguTileComponent,
    NguCarousel,
    NguCarouselDefDirective,
    NguCarouselNextDirective,
    NguCarouselPrevDirective,
    NguItemComponent,    
  ],
  providers: [
    DatePipe,
    PurpleDatePipe,
    CurrencyPipe,
    provideLottieOptions({
    player: () => player,
  })]
})
export class PrivateModule { }
