import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/core-modules/authentication/authentication.service';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { GetUserMembershipQrCodeResponse, MembershipManagerService } from 'src/core-modules/sdk/api';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { PurpleApiMakeRequestResponse, PurpleApiProxyService } from 'purple-lib';
import { LayoutService } from 'src/app/layout/layout.service';

@Component({
  selector: 'app-qr-code-drawer',
  templateUrl: './qr-code-drawer.component.html',
  styleUrls: ['./qr-code-drawer.component.scss']
})
export class QrCodeDrawerComponent implements OnInit {

  constructor(public authSvc:AuthenticationService, private drawerRef: NzDrawerRef, private memberSvc: MembershipManagerService, private tsvc: AppTranslationService, private apiProxySvc: PurpleApiProxyService, public layoutSvc: LayoutService) { }
  hexColor: string = "#fff";
  membershipName: string | undefined = undefined;
  membershipExpireDate: string | undefined = undefined;
  contentId: string = "";

  async ngOnInit() {
    await this.apiProxySvc.makeRequestErrorFunction<GetUserMembershipQrCodeResponse>(()=>this.memberSvc.getUserMembershipQrCode(this.tsvc.currentLanguage.value),true, "internal-loader",500,
    (res: PurpleApiMakeRequestResponse<GetUserMembershipQrCodeResponse>)=>{
      this.hexColor = "#fff";
      this.membershipName = undefined;
      this.contentId = "logo-qr-light"
    },(res: PurpleApiMakeRequestResponse<GetUserMembershipQrCodeResponse>)=>{
      this.hexColor = res.data!.hexColor;
      this.membershipName = res.data!.membershipName??undefined;
      this.membershipExpireDate = res.data!.membershipExpireDate??undefined;
      this.contentId = "logo-qr-"+ this.layoutSvc.lightOrDark(this.hexColor)
    })
  }

  closeDarwer(){
    this.drawerRef.close();
  }

  getName(){
   return (this.authSvc.currentUser?.user?.firstName??"").split(" ")[0]
  }
}
