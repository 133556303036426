import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[next-tab]',
})
export class NextTabDirective {

  constructor() {
  }

  @Input('next-tab') nextControl: any;

  @HostListener("keydown.enter", ["$event"])
  onEnter(event: KeyboardEvent): any {
    if (this.nextControl) {
      if (this.nextControl.focus) {
        this.nextControl.focus();
        this.nextControl.select();
        event.preventDefault();
        return false;
      }
    }
  }


}