import { Component, Inject, OnInit } from '@angular/core';
import { PurpleApiResponseStatus, PurpleTranslationPipe } from 'purple-lib';
import { Subject, interval, lastValueFrom, takeUntil } from 'rxjs';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { MembershipManagerService } from 'src/core-modules/sdk/api';
import { NzModalRef, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register-user-memebership-modal',
  templateUrl: './register-user-memebership-modal.component.html',
  styleUrls: ['./register-user-memebership-modal.component.scss']
})
export class RegisterUserMemebershipModalComponent implements OnInit {

  constructor(@Inject(NZ_MODAL_DATA) public checkoutId: string, private tranPipe: PurpleTranslationPipe, private tsvc: AppTranslationService, private membSvc: MembershipManagerService, 
  private modalRef: NzModalRef, private router: Router) { }
  isLoading: boolean = false;

  title: string = this.tranPipe.transform('register_user_membership_title', 'Stiamo registrando il tuo abbonamento', [])
  period = interval(2000);
  sub = new Subject<void>()

  ngOnInit() {
    this.isLoading = true;
    this.period.pipe(takeUntil(this.sub)).subscribe(async () => {
      console.log("Sto girando")

      var res = await lastValueFrom(this.membSvc.getCheckoutStatus(this.tsvc.currentLanguage.value, {
        checkoutId: this.checkoutId
      }));

      console.log("RES: ", res)
      if (res.status == PurpleApiResponseStatus.Success) {
        if (res.data!.isCheckoutPaid) {

          if(res.data!.internalTransactionStatus.isTransactionStatusSuccess){
            this.endLoop(this.tranPipe.transform('register_user_membership_ok_text', 'Congratulazioni la tua sottoscrizione è stata registrata con successo', []));
          }else{
            var res1 = await lastValueFrom(this.membSvc.syncUserCheckout(this.tsvc.currentLanguage.value, {
              checkoutId: this.checkoutId
            }));

            if(res1.status == PurpleApiResponseStatus.Success && res1.data){
              this.endLoop(this.tranPipe.transform('register_user_membership_ok_text', 'Congratulazioni la tua sottoscrizione è stata registrata con successo', []));
            }
          }
        }
      } else {
        this.endLoop(this.tranPipe.transform('register_user_membership_ko_text', 'Errore non è stato possibile effettuare la sottoscrizione', []));
      }
    });
  }

  endLoop(title: string) {
    this.title = title;
    this.isLoading = false;
    this.sub.next();
    this.sub.complete();
  }


  cloeseModal() {
    this.modalRef.close();
    this.router.navigate([this.tsvc.currentLanguage.value, 'event', 'membership']);
  }
}
