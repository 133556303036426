import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { AuthenticationService } from 'src/core-modules/authentication/authentication.service';

@Component({
  selector: 'app-user-menu-drawer',
  templateUrl: './user-menu-drawer.component.html',
  styleUrls: ['./user-menu-drawer.component.scss']
})
export class UserMenuDrawerComponent implements OnInit {

  constructor(public authSvc: AuthenticationService, public tsvc: AppTranslationService, private router: Router, private drawerRf: NzDrawerRef) { }

  @Input() logout!: ((isMobile: boolean) => any);
  
  ngOnInit() {
  }

  navigateToUserProfile(){
    this.drawerRf.close();
    this.router.navigate([this.tsvc.currentLanguage.value, 'event', 'user-profile']);
  }
  
}
