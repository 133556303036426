<div class="user-profile-external-container">
  <app-purple-sales-page-title [pageTitle]="pageTitle"></app-purple-sales-page-title>

  <div class="user-profile-container ps-d-flex ps-flex-wrap ps-align-items-start">

    <div class="user-profile-container">
      <div class="user-profile-section">
        <app-private-user-profile-title titleKey='user_profile_information_section_title'
          [title]="'user_profile_information_section_title' | translation: 'Informazioni'"
          subtitleKey="user_profile_information_section_subtitle" [subtitle]="'user_profile_information_section_subtitle' | translation: 
        'Questi dati ci servono per poterti invitare alle nostre private sales. Li custodiremo con cura!'">
        </app-private-user-profile-title>
        <div class="user-profile-section-content-container">

          <app-purple-form class="user-profile-base-info-form" *ngIf="purpleFormFieldGroups!= undefined"
            [disbledForm]="disableForm" [(formGroup)]="validateForm" [purpleFormFieldGroups]="purpleFormFieldGroups"
            [refreshFormFields]="refreshFields">
          </app-purple-form>

          <div class="ps-w-100 ps-d-flex ps-align-items-center">

            <app-purple-sales-button *ngIf="disableForm" (click)="enableForm()" class="edit-user-info-button"
              purpleEditableText tranKey='user_profile_base_info_button_edit'
              [buttonText]="'user_profile_base_info_button_edit' | translation: 'Modifica Profilo'">
            </app-purple-sales-button>

            <ng-container *ngIf="!disableForm">
              <app-purple-sales-button (click)="cancelEdit()" class="edit-user-info-button" purpleEditableText
                tranKey='user_profile_base_info_button_cancel'
                [buttonText]="'user_profile_base_info_button_cancel' | translation: 'Cancella'">
              </app-purple-sales-button>

              <app-purple-sales-button (click)="saveBaseInfoChanges()" class="edit-user-info-button save"
                purpleEditableText tranKey='user_profile_base_info_button_save'
                [buttonText]="'user_profile_base_info_button_save' | translation: 'Salva'" [buttonType]="'primary'">
              </app-purple-sales-button>
            </ng-container>
          </div>
        </div>

      </div>
      <div class="user-profile-section">
        <app-private-user-profile-title titleKey='user_profile_access_data_section_title'
          subtitleKey="user_profile_access_data_section_subtitle"
          [title]="'user_profile_access_data_section_title' | translation: 'Accesso e Sicurezza'" [subtitle]="'user_profile_access_data_section_subtitle' | translation: 
        'Puoi modificare i dati di accesso al profilo tramite i bottoni sottostanti'">
        </app-private-user-profile-title>
        <div class="user-profile-section-content-container">
          <div class="user-profile-reset-button-container">
            <div class="ps-col-md-6 ps-col-xl-4 ps-col-12 ps-mt-xl-0 ps-mt-3">
              <button nz-button class="user-profile-reset-button" [nzLoading]="isLoadingPassword"
                (click)="requestResetPassword()">
                {{ 'user_profile_reset_password_button' | translation: 'Reimposta password': [] | uppercase}}
              </button>
            </div>
            @if (allowChangeEmailAndPhone) {
            <div class="ps-col-md-6 ps-col-xl-4 ps-col-12 ps-mt-xl-0 ps-mt-3">
              <button nz-button class="user-profile-reset-button" (click)="openVerify('email', 'edit')">
                {{ 'user_profile_change_email_button' | translation: 'Modifica email': [] | uppercase}}
              </button>
            </div>
            <div class="ps-col-md-6 ps-col-xl-4 ps-col-12 ps-mt-xl-0 ps-mt-3">
              <button nz-button class="user-profile-reset-button" (click)="openVerify('phone', 'edit')">
                {{ 'user_profile_change_phone_button' | translation: 'Modifica numero telefono': [] | uppercase}}
              </button>
            </div>
          }
          </div>
        </div>
      </div>

      <div class="user-profile-section" *ngFor="let att of userAttributes">
        <app-private-user-profile-title [title]="att.userAttributeName" [subtitle]="att.userAttributeDescription">
        </app-private-user-profile-title>
        <div class="user-profile-section-content-container">
          <div class="user-profile-section-attributes">
            <container-element [ngSwitch]="att.angularTemplateType" class="ps-w-100">
              <ng-container *ngSwitchCase="'select-multiple'" class="ps-w-100">
                <div class="ps-w-100 ps-d-flex ps-flex-wrap">
                  <div class="ps-col-6 ps-col-md-4" *ngFor="let defVal of att.defaultValues">
                    <button nz-button (click)="addOrRemovePreference(att, defVal.userAttributeDefaultValueId)"
                      class="user-attribute-button {{isAttributeSelected(att.userAttributeValues, defVal.userAttributeDefaultValueId)?'selected':''}}">
                      {{defVal.userAttributeDefaultValueText | uppercase}}
                    </button>
                  </div>
                </div>

              </ng-container>
            </container-element>
          </div>
        </div>
      </div>

      <div class="user-profile-section">
        <app-private-user-profile-title titleKey='user_profile_bookings_section_title'
          [title]="'user_profile_bookings_section_title' | translation: 'Prenotazioni'"
          [subtitle]="'user_profile_bookings_section_subtitle' | translation: 'Ecco l\'elenco di tutti gli eventi a cui hai partecipato'"
          subtitleKey="user_profile_bookings_section_subtitle">
        </app-private-user-profile-title>

        <nz-spin class="ps-d-flex ps-flex-wrap ps-w-100 user-bookings-stats-spin" [nzSpinning]="isLoadingBookings">

          <div class="user-profile-section-content-container">
            <div class="ps-w-100 ps-d-flex user-bookings-container">

              <!-- BOOKING STATS -->
              <div class="user-bookings-stats">

                <div class="ps-col-xl-4 ps-col-12 user-bookings-stats-box">
                  <div class="user-bookings-stats-box-internal">
                    <p class="user-bookings-stats-box-name">
                      {{ 'user_profile_bookings_stats_subscriptions' | translation: 'Iscrizioni': []}}
                    </p>
                    <p class="user-bookings-stats-box-value">
                      {{userBookingsDetail?.bookingCounters?.bookings??"-"}}
                    </p>
                  </div>
                </div>

                <div class="ps-col-xl-4 ps-col-12 user-bookings-stats-box">
                  <div class="user-bookings-stats-box-internal">
                    <p class="user-bookings-stats-box-name">
                      {{ 'user_profile_bookings_stats_show' | translation: 'Partecipazioni': []}}
                    </p>
                    <p class="user-bookings-stats-box-value">
                      {{userBookingsDetail?.bookingCounters?.shows??"-"}}
                    </p>
                  </div>
                </div>

                <div class="ps-col-xl-4 ps-col-12 user-bookings-stats-box">
                  <div class="user-bookings-stats-box-internal">
                    <p class="user-bookings-stats-box-name">
                      {{ 'user_profile_bookings_stats_no_show' | translation: 'Assenze': []}}
                    </p>
                    <p class="user-bookings-stats-box-value">
                      {{userBookingsDetail?.bookingCounters?.noShows??"-"}}
                    </p>
                  </div>
                </div>
              </div>


              <!-- BOOKING HISTORY -->
              @for (book of userBookingsDetail?.bookings??[]; track $index) {
              @for (slot of book.slots; track $index) {

              <mat-expansion-panel [hideToggle]="book.transactions.length == 0"
                [disabled]="book.transactions.length == 0">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class="user-booking-item">
                      <div class="ps-col-sm-4 ps-col-12 user-booking-item-section">
                        <div class="ps-w-100 ps-d-flex ps-flex-wrap">
                          <p class="user-booking-item-section-title">
                            {{book.saleEventName}}
                          </p>
                          <p class="user-booking-item-section-subtitle">
                            {{book.address}}
                          </p>
                        </div>

                      </div>
                      <div class="ps-col-sm-4 ps-col-12 user-booking-item-section space">
                        <div class="ps-w-100 ps-d-flex ps-flex-wrap">
                          <p class="user-booking-item-section-title">
                            {{slot.slotStartDate | purpleDate: "EEEE dd MMMM YYYY" | titlecase}}
                          </p>
                          <p class="user-booking-item-section-subtitle">
                            {{slot.slotStartDate | purpleDate: "HH:mm"}} / {{slot.slotEndDate | purpleDate: "HH:mm"}}
                          </p>
                        </div>

                      </div>
                      <div
                        class="ps-col-sm-4 ps-col-12 user-booking-item-section space no-border ps-d-flex ps-justify-content-start ps-justify-content-xl-center">
                        <div class="check-in-check {{book.checkInOk?'ok':''}}">
                          {{book.checkInOk? ('checkin_ok' | translation: 'Hai partecipato': [] | uppercase) :
                          ('checkin_no_ok' | translation: 'Non hai partecipato': []) | uppercase}}
                        </div>
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                  <app-private-user-profile-title titleKey='user_profile_booking_purchases'
                    [title]="'user_profile_booking_purchases' | translation: 'I tuoi acquisti'">
                  </app-private-user-profile-title>
                  <table class="user-profile-purchases-table">
                    <tr>
                      <th>
                        {{ 'user_profile_bookings_purchases_items' | translation: 'Articolo': []}}
                      </th>
                      <th>
                        {{ 'user_profile_bookings_purchases_wowid' | translation: 'Wow ID': []}}
                      </th>
                      <th>
                        {{ 'user_profile_bookings_purchases_size' | translation: 'Size': []}}
                      </th>
                      <th>
                        {{ 'user_profile_bookings_purchases_price' | translation: 'Price': []}}
                      </th>
                    </tr>
                    @for (tran of getCurrentBookTransactions(slot.bookingId,book); track $index) {
                    <tr *ngFor="let item of tran.items">
                      <td class="td-desc">
                        {{item.itemDescription | titlecase}}
                      </td>
                      <td>
                        #{{item.itemWowId}}
                      </td>
                      <td>
                        {{item.itemSize}}
                      </td>
                      <td>
                        {{item.itemPrice | currency: 'EUR'}}
                      </td>
                    </tr>
                    <tr class="total-row">
                      <td colspan="2">
                      </td>
                      <td class="total-field">
                        {{ 'user_profile_purchase_total' | translation: 'Totale': []}}
                      </td>
                      <td>
                        {{getTransactionTotals(tran, book)}}
                      </td>
                    </tr>
                    }
                  </table>
                </div>
              </mat-expansion-panel>


              }

              }


              <div *ngIf="userBookingsDetail?.showMore??false" class="ps-w-100 ps-d-flex ps-justify-content-center">
                <p (click)="loadMoreBookings()" class="user-bookings-show-more-button">
                  {{ 'user_profile_show_more_bookings_button' | translation: 'Carica altro': []}}
                </p>
              </div>

            </div>
          </div>
        </nz-spin>
      </div>

      <div class="user-profile-section" *ngIf="saleEventPolicies.length > 0">
        <app-private-user-profile-title titleKey='user_profile_policies_section_title'
          [title]="'user_profile_policies_section_title' | translation: 'Termini e Condizioni'"
          subtitleKey="user_profile_policies_section_subtitle"
          [subtitle]="'user_profile_policies_section_subtitle' | translation: 'Gestisci il consenso alle nostre policy'">
        </app-private-user-profile-title>
        <div class="user-profile-section-content-container">
          <div class="user-profile-policies-section-container ps-d-flex ps-flex-wrap">

            <div class="sale-event-policy-box ps-w-100 ps-d-flex ps-flex-wrap"
              *ngFor="let sePolicy of saleEventPolicies">

              @if(!sePolicy.saleEventId.strEq(defaultSale)){

              <h2 class="sale-event-name">
                {{getSaleEventName(sePolicy.saleEventId, sePolicy.saleEventName) | uppercase}}
              </h2>
              }

              <div class="policy-items-container">

                <div class="policy-item-container ps-col-12 ps-col-md-6 ps-d-flex ps-flex-wrap"
                  *ngFor="let policy of sePolicy.policiesQueries">

                  <div class="policy-item ps-w-100 ps-d-flex ps-flex-wrap">
                    <div class="policy-text-container">
                      <h1 class="policy-title ps-mb-0 ps-w-100 purple-title">
                        {{policy.policyName}}
                      </h1>
                      <!-- <p class="ps-w-100 policy-description">
                        {{policy.policyDescription}}
                      </p> -->

                      <div class="ps-w-100 policy-link">

                        <span purpleEditableText tranKey='show_ploicy'>
                          {{ 'show_ploicy' | translation: 'Per visualizzare la policy': []}}
                        </span>

                        &nbsp;

                        <container-element [ngSwitch]="policy.policyTypeId">

                          <ng-container *ngSwitchCase="'link'">
                            <a class="ps-href-reset" [href]="policy.policyUrl" target="_blank">{{ 'click_here' |
                              translation:
                              'clicca qui': []}}</a>
                          </ng-container>
                          <ng-container *ngSwitchCase="'iubenda'">
                            <a class="ps-href-reset" [href]="policy.policyUrl" target="_blank">{{ 'click_here' |
                              translation:
                              'clicca qui': []}}</a>
                          </ng-container>
                          <ng-container *ngSwitchCase="'media'">
                            <a class="ps-href-reset" [href]='policySvc.getPolicyImageUrl(policy)' target="_blank">{{
                              'click_here' |
                              translation:
                              'clicca qui': []}}</a>
                          </ng-container>
                          <ng-container *ngSwitchCase="'html'">
                            <a class="ps-href-reset" (click)="showPolicyPreview(policy)">{{ 'click_here' |
                              translation:
                              'clicca qui': []}}</a>
                          </ng-container>
                          <ng-container *ngSwitchCase="'text'">
                            <a class="ps-href-reset" (click)="showPolicyPreview(policy)">{{ 'click_here' |
                              translation:
                              'clicca qui': []}}</a>
                          </ng-container>
                        </container-element>
                      </div>
                    </div>

                    <div class="policy-switch-container">
                      <nz-switch class="prefernce-switch" [ngModel]="policyAccepted(policy)"
                        [nzDisabled]="policyBlocked(policy)"
                        (ngModelChange)="updateSaleEventUserPolicyEndorsement($event, policy)">
                      </nz-switch>
                    </div>


                  </div>


                </div>


              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>