<div class="purple-sales-footer">
  <div class="purple-sales-footer-internal">
    <div class="purple-sales-footer-image-container">
      <div class="ps-w-100 ps-d-flex ps-flex-wrap">
        <img contentId="logo-footer" contentTypeId="logo-company" purpleEditableImage
          class="purple-sales-footer-image" />
        <div class="purple-sales-footer-socials">
          @if (env.data.SOCIAL_IG) {
          <a purpleEditableText tranKey='ig_link' class="social-btn" [href]="'ig_link' | translation: '#': []">
            <span class="social-btn-icon" nz-icon nzType="instagram" nzTheme="outline"></span>
          </a>
          }
          @if (env.data.SOCIAL_LK) {
          <a purpleEditableText tranKey='lk_link' class="social-btn" [href]="'lk_link' | translation: '#': []">
            <span class="social-btn-icon" nz-icon nzType="linkedin" nzTheme="outline"></span>
          </a>
          }
          @if (env.data.SOCIAL_FB) { <a purpleEditableText tranKey='fb_link' class="social-btn"
            [href]="'fb_link' | translation: '#': []">
            <span class="social-btn-icon" nz-icon nzType="facebook" nzTheme="outline"></span>
          </a>
          }
          @if (env.data.SOCIAL_WA) { <a purpleEditableText tranKey='wa_link' class="social-btn"
            [href]="'wa_link' | translation: '#': []">
            <span class="social-btn-icon" nz-icon nzType="whats-app" nzTheme="outline"></span>
          </a>
          }

        </div>
      </div>
    </div>
    <div class="purple-sales-footer-menu-container">
      <div class="purple-sales-footer-menu">
        <div *ngFor="let menuItem of layoutSvc.activeMenu" class="purple-sales-footer-menu-item">
          <a [routerLink]="menuItem.relativeLink" class="purple-sales-footer-menu-btn">
            {{menuItem.name | uppercase}}
          </a>
        </div>
      </div>
      <div class="purple-sales-footer-menu policies">
        <div *ngFor="let policy of policies" class="purple-sales-footer-menu-item">
          @if(policy.policyTypeId == 'iubenda' || policy.policyTypeId == 'link'){
          <a [href]="policy.policyUrl" target="_blank" [title]="policy.policyTitle"
            class="purple-sales-footer-menu-btn">
            {{policy.policyTitle | uppercase}}
          </a>
          }@else if (policy.policyTypeId == 'media') {
          <a [href]="basePath + '/' + tsvc.currentLanguage.value + '/medias/getmedia?ContentId=' + policy.policyContentId + '&ContentTypeId=' + policy.policyContentTypeId"
            class="purple-sales-footer-menu-btn">
            {{policy.policyTitle | uppercase}}
          </a>
          }
        </div>
      </div>
      <div class="purple-sales-footer-menu contact">

        <div *ngFor="let cinfo of contactInfo" class="purple-sales-footer-menu-item">
          <span class="purple-sales-footer-menu-contact-icon" nz-icon [nzType]="cinfo.infoIcon"
            nzTheme="outline"></span>
          <p class="purple-sales-footer-menu-contact-text">
            @if (cinfo.infoUrl) {
            <a [href]="cinfo.infoUrl" target="_blank" rel="noopener">
              {{cinfo.infoValue}}
            </a>
            }@else{
            <span>{{cinfo.infoValue}}</span>
            }
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="purple-sales-footer-secondary">
  Made with
  <span nzPopoverTrigger="click" nz-popover nzPopoverPlacement="top" [nzPopoverContent]="swVersion"
    class="ps-purple-color">♥</span> by&nbsp;
  <a class="ps-href-reset ps-purple-color" target="_blank" href="https://www.purplesoft.io"
    rel="noopener">Purplesoft</a>
</div>