<div class="purple-sales-title-container {{pageTitle.allRounded}}">
  <div class="ps-w-100 ps-d-flex ps-flex-wrap">

    <h1 [style.text-align]='pageTitle.textAlign??"center"' class="purple-sales-title title-font" purpleEditableText
      [tranKey]='pageTitle.titleTranslationKey'>
      {{pageTitle.title}}
    </h1>
    <h2 *ngIf="pageTitle.subtitle && pageTitle.subtitle.subtitle" class="purple-sales-subtitle" purpleEditableText
      [style.text-align]='pageTitle.textAlign??"center"' [tranKey]='pageTitle.subtitle.subtitleTranslationKey'>
      {{pageTitle.subtitle.subtitle | uppercase}}
    </h2>

    <!-- <div *ngIf="!pageTitle.subtitle" class="purple-sales-subtitle">
    </div> -->
  </div>
</div>