<div class="external-membership-container">
  <app-purple-sales-page-title [pageTitle]="pageTitle"></app-purple-sales-page-title>

  <div *ngFor="let member of memberships" class="ps-col-xl-4 ps-col-12">
    <div class="membership-container">
      <div class="ps-w-100 ps-d-flex ps-flex-wrap">


      <div [style.background]="member.hexColor" class="membership-title-container">
        <h2 class="membership-title {{layoutSvc.lightOrDark(member.hexColor)}}">
          {{member.membershipName | titlecase}}
        </h2>
      </div>
      <img [contentId]="member.membershipName!" contentTypeId="membership-image" purpleEditableImage class="membership-image" />
      <div class="membership-items-container">
        <p class="membership-items-title">
          {{ 'membership_what_includes' | translation: 'Cosa include?': [] | uppercase}}
        </p>
        <ng-container class="ps-w-100" *ngFor="let info of member.highlightedFeatures">
          <div *ngIf="info.isVisible" class="membership-item {{info.isActive?'':'disabled'}}">
            <span class="membership-item-dot" nz-icon [nzType]="info.isActive?'check':'close'" nzTheme="outline"></span>
            <p purpleEditableText [tranKey]='info.featureKey?.replace("booking_","")' class="membership-item-text">
              {{info.featureValue}}
            </p>
          </div>
        </ng-container>
      </div>
    </div>
      <div [style.background]="member.hexColor" class="membership-price-container">
        <div class="ps-w-100 ps-d-flex ps-flex-wrap">
          <p purpleEditableText [tranKey]='(member.highlightedPriceLabels??[])[0]' class="membership-price {{layoutSvc.lightOrDark(member.hexColor)}}">
            {{ (member.highlightedPriceLabels??[])[0] | translation: (member.highlightedPriceLabels??[])[0] : []}}
          </p>
          <p class="membership-alt-price {{layoutSvc.lightOrDark(member.hexColor)}}">
            <span purpleEditableText [tranKey]='variant.key' *ngFor="let variant of getAltPrices(member.highlightedPriceLabels)">
              {{variant.value}}
            </span>
          </p>
        </div>
        <app-purple-sales-button [isDisabled]="checkUserMembershipIsActive(member.membershipVariants).isFree" 
        class="membership-button {{checkUserMembershipIsActive(member.membershipVariants).isFree?'disabled':''}} {{layoutSvc.lightOrDark(member.hexColor)}}" 
          purpleEditableText [tranKey]='getBtnText(member.membershipName, member.membershipVariants).key' 
          [buttonText]="getBtnText(member.membershipName, member.membershipVariants).text"
          (click)="subscribeBtn(member.membershipName!, member.membershipVariants)"
          
          ></app-purple-sales-button>
      </div>
    </div>
  </div>
</div>