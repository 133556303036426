import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppTranslationService } from './localization.service';


function initTran(tsvc: AppTranslationService) {
  return () => tsvc.initialize(true);
  //return () => tsvc.initialize(true).then(() => tsvc.getLanguages());
}

@NgModule({
  declarations: [	
   ],
  imports: [
    CommonModule
  ],
  providers: [AppTranslationService, {
    provide: APP_INITIALIZER,
    useFactory: initTran,
    deps: [AppTranslationService],
    multi: true
  }]
})
export class LocalizationModule { }
