import { Component, Input, OnInit } from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BookingService, UserAttributeValuesItem, UserAttributeValueRequest, PRPVUserAttributeDefaultValueFull, UserAttributeValue } from 'src/core-modules/sdk/api';
import { AuthenticationService } from 'src/core-modules/authentication/authentication.service';
import { Guid, PurpleApiMakeRequestResponse, PurpleApiProxyService, PurpleTranslationPipe } from 'purple-lib';
import { PurpleSalesNotificationService } from 'src/app/components/purple-sales-notification.service';

@Component({
  selector: 'app-check-user-attributes-drawer',
  templateUrl: './check-user-attributes-drawer.component.html',
  styleUrls: ['./check-user-attributes-drawer.component.scss']
})
export class CheckUserAttributesDrawerComponent implements OnInit {

  constructor(private bookingSvc: BookingService, private tsvc: AppTranslationService, private authSvc: AuthenticationService, private drawerRef: NzDrawerRef, 
    private psNotSvc: PurpleSalesNotificationService, private tranPipe:PurpleTranslationPipe, private apiProxySvc: PurpleApiProxyService) { }

  @Input() userAttributes!: UserAttributeValuesItem[];
  preferences: Map<string, UserAttributeValue[]> = new Map<string, UserAttributeValue[]>();

  ngOnInit() {
    this.userAttributes.forEach(f => {
      if(f.userAttributeValues.length > 0){
        this.preferences.set(f.userAttributeId, f.userAttributeValues);
      }
      
    });

    //console.log(this.preferences)
  }

  addPreference(userAttributeId: string , userAttributeDefaultValue: PRPVUserAttributeDefaultValueFull) {
    var current: UserAttributeValue | undefined = this.userAttributes.find(f=> f.userAttributeId.strEq(userAttributeId))?.
    userAttributeValues.find(f=> f.userAttributeDefaultValueId.strEq(userAttributeDefaultValue.userAttributeDefaultValueId));

    var prefs = this.preferences.get(userAttributeId) ?? [];
    prefs.push({
      isDefaultValue: current?.isDefaultValue??true,
      userAttributeDefaultValueId: current?.userAttributeDefaultValueId??userAttributeDefaultValue.userAttributeDefaultValueId,
      userAttributeId: current?.userAttributeId??userAttributeId,
      userAttributeValueId: current?.userAttributeValueId??Guid.newGuid(),
      userAttributeValueText: current?.userAttributeValueText??userAttributeDefaultValue.userAttributeDefaultValueText,
      userId: current?.userId??this.authSvc.currentUser!.user!.userId,
      isActive: current?.isDefaultValue??true,
      timeStamp: current?.timeStamp??undefined
    });
    this.preferences.set(userAttributeId, prefs);

    //console.log("Preferenze:", this.preferences)
  }

  selectedPreference(attributeId: string, userAttributeDefaultValueId: string): boolean {
    var prefs = this.preferences.get(attributeId) ?? [];
    return prefs.findIndex(f => f.userAttributeDefaultValueId.strEq(userAttributeDefaultValueId)) > -1;
  }

  removePreference(attributeId: string, userAttributeDefaultValueId: string) {
    var prefs = this.preferences.get(attributeId) ?? [];
    const idx = prefs.findIndex(f => f.userAttributeDefaultValueId.strEq(userAttributeDefaultValueId));
    prefs.splice(idx, 1)

    if (prefs.length == 0) {
      this.preferences.delete(attributeId)
    } else {
      this.preferences.set(attributeId, prefs);
    }

    //console.log("Preferenze:", this.preferences)
  }


  async savePreference() {
    var toUpd: UserAttributeValueRequest[] = [];

    this.preferences.forEach((value, key) => {
      toUpd.push({
        userAttributeId: key,
        userAttributeValues: value
      })
    });

    await this.apiProxySvc.makeRequestErrorFunction<boolean>(()=>this.bookingSvc.addOrRemoveUserAttributeValue(this.tsvc.currentLanguage.value, {
    userAttributeValues: toUpd
    }),false, "internal-loader",500,(res: PurpleApiMakeRequestResponse<boolean>)=>{
      this.psNotSvc.showMotSimpleNotification(
        this.tranPipe.transform('error_message_default_title', 'Qualcosa è andato storto', []),
        res.message,
        this.tranPipe.transform('error_message_default_button', 'Ok', []),
        undefined, 'modal', true, undefined, undefined, false, false, false, false)
    },(res: PurpleApiMakeRequestResponse<boolean>)=>{
      this.drawerRef.close();
    })
  }

}
