import { Component, Input, OnInit } from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { NzModalService } from 'ng-zorro-antd/modal';
import { lastValueFrom } from 'rxjs';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BookingService, PurpleApiResponseStatus, StringPurpleApiResponse, UserExtendedReferral, UserNextSaleEventBookingItem } from 'src/core-modules/sdk/api';
import { AuthenticationService } from 'src/core-modules/authentication/authentication.service';
import { EffectType } from '../../modals/book-slot-modal/book-slot-modal.component';
import { SendEmailInviteModalComponent } from '../../modals/send-email-invite-modal/send-email-invite-modal.component';
import { PurpleTranslationPipe } from 'purple-lib';
import { PurpleSalesNotificationService } from 'src/app/components/purple-sales-notification.service';

@Component({
  selector: 'app-invite-friends-drawer',
  templateUrl: './invite-friends-drawer.component.html',
  styleUrls: ['./invite-friends-drawer.component.scss']
})
export class InviteFriendsDrawerComponent implements OnInit {

  constructor(public authSvc: AuthenticationService, private drawerRef: NzDrawerRef,
    private bookSvc: BookingService, public tsvc: AppTranslationService, private psNotSvc: PurpleSalesNotificationService,
    private tranPipe: PurpleTranslationPipe, private modal: NzModalService) { }

  @Input() book: UserNextSaleEventBookingItem | undefined;
  @Input() saleEventId!: string;
  @Input() saleEventName!: string;


  freeRefCodeNr: number = 0;
  referralFriends: UserExtendedReferral[] | undefined = undefined;
  loadEnd: boolean = false;

  async ngOnInit() {
    const resp = await lastValueFrom(
      this.bookSvc.getUserFreeSaleEventReferral(this.tsvc.currentLanguage.value, {
        saleEventId: this.book?.saleEventId??this.saleEventId,
        roleId: this.book?.slot.roleId??undefined
      }));

    if (resp.status == PurpleApiResponseStatus.Success) {
      this.freeRefCodeNr = resp.data?.freeReferralsNr ?? 0;
      this.referralFriends = resp.data?.redeemReferralFriends ?? [];
    } else {
      this.freeRefCodeNr = 0;
      this.referralFriends = [];
    }

    this.setLoad(EffectType.up)
  }

  closeDrawer() {
    this.drawerRef.close();
  }

  async checkUserreferral(): Promise<StringPurpleApiResponse> {
    const resp = await lastValueFrom(
      this.bookSvc.addSaleEventUserRoleReferral(this.tsvc.currentLanguage.value, {
        roleId: this.book?.slot.roleId??undefined,
        saleEventId: this.book?.saleEventId??this.saleEventId
      }));

    return resp
  }

  async sendEmail() {
    var referralId = await this.checkUserreferral();
    if (referralId.data != undefined) {
      this.modal.create<SendEmailInviteModalComponent, { roleId?: string | undefined, referralId: string, slotStartDate?: string | undefined, isMobile: boolean, saleEventId?: string | undefined }>({
        nzTitle: this.tranPipe.transform("modal_send_user_email_invite_title", "Invita Amico", []),
        nzContent: SendEmailInviteModalComponent,
        nzData: {
          roleId: this.book?.slot.roleId??undefined,
          referralId: referralId.data,
          slotStartDate: this.book?.slot.slotStartDate??undefined,
          isMobile: true,
          saleEventId: this.book?.saleEventId??this.saleEventId
        },
        nzWidth: '90%',
        nzClassName: 'ps-modal',
        nzFooter: null,
        nzCentered: true,
        nzMaskClosable: false
      });
    } else {
      this.psNotSvc.showMotSimpleNotification(
        this.tranPipe.transform('error_message_default_title', 'Qualcosa è andato storto', []),
        referralId.message,
        this.tranPipe.transform('error_message_default_button', 'Ok', []),
        undefined, 'modal', true, undefined, undefined, false, false, false, false)    
    }
  }

  async genericShare() {
    var referralId = await this.checkUserreferral();

    if(referralId.data != undefined){
      const shareData = {
        title: this.tranPipe.transform('send_user_whatsapp_invite_title', 'Invito alla private sale \"{0}\"', [this.book?.saleEventName ?? this.saleEventName]),
        text: this.tranPipe.transform('send_user_whatsapp_invite_text', '{0} ti ha inviato alla private sale \"{1}\", clicca sul seguente link per prenotarti:{2}',
          [this.authSvc.currentUser!.user!.firstName + " " + this.authSvc.currentUser!.user!.lastName, this.book?.saleEventName ?? this.saleEventName,
          " https://" + window.location.hostname + "/" + this.tsvc.currentLanguage.value + "/referral?id=" + referralId.data]),
        url: "https://" + window.location.hostname + "/" + this.tsvc.currentLanguage.value + "/referral?id=" + referralId.data
      }
  
      await navigator.share(shareData)
    }else{
      this.psNotSvc.showMotSimpleNotification(
        this.tranPipe.transform('error_message_default_title', 'Qualcosa è andato storto', []),
        referralId.message,
        this.tranPipe.transform('error_message_default_button', 'Ok', []),
        undefined, 'modal', true, undefined, undefined, false, false, false, false)    
    }
  }

  


  setLoad(type: EffectType) {
    if (type == EffectType.up) {
      this.loadEnd = false;
      setTimeout(() => {
        this.loadEnd = true;
      }, 200);
    }

    if (type == EffectType.down) {
      this.loadEnd = false;
    }

  }
}
