import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { fadeAnimation } from 'src/core-modules/animations/fade-animation';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { AuthenticationService } from 'src/core-modules/authentication/authentication.service';
import { environment } from 'src/environments/default/environment';
import { LayoutService } from './layout/layout.service';
import * as Sentry from '@sentry/angular-ivy';
import { PolicyAndUserAttributesService } from './policy-and-user-attributes-service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { PurpleApiError, PurpleApiErrorPage, PurpleApiErrorService, PurpleEditService, PurpleErrorService, PurpleLoaderService, PurpleStorageService } from 'purple-lib';
import { BreakpointsService } from 'src/core-modules/breakpoints/breakpoints.service';
import { Subject, takeUntil, throttleTime } from 'rxjs';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeAnimation]
})
export class AppComponent implements OnInit, OnDestroy {

  constructor(public loaderSvc: PurpleLoaderService, public tsvc: AppTranslationService, public authService: AuthenticationService, private storageSvc: PurpleStorageService,
    private breakpointObserver: BreakpointObserver, public layoutSvc: LayoutService, public editSvc: PurpleEditService, private router: Router, 
    private authenticationService: AuthenticationService, private policySvc: PolicyAndUserAttributesService, private breakpointSvc: BreakpointsService,
    private errorSvc: PurpleErrorService, private errorInterceptorSvc: PurpleApiErrorService, private modalSvc: NzModalService) { }

  sub: Subject<void> = new Subject();
  isOnRefresh = false;
  fullContent = true;
  loaderPath: string = '/assets/loaders/' + environment.COMPANY_ABB + '/loader.json';
  loaderId: string = "general";
  isLanguageInit: boolean = false;
  loaderWidth: string = "500px";

  private throttleLogout = new Subject<any>();
  errorInfo: PurpleApiError | undefined = undefined;

  enableErrorPages: PurpleApiErrorPage[] = [
    {
      errorStatus: 0,
      errorTitle: "OPS SOMETHING WENT WRONG...",
      errorDescription: "It appears there was an error connecting to the server. Try reloading the page again."
    }
  ]

  async ngOnInit(): Promise<void> {
    this.breakpointObserver.observe([
      '(max-width: 649.99px)',
      '(min-width: 650px) and (max-width: 1199.99px)',
      '(min-width: 1200px)'
    ]).subscribe((state: BreakpointState) => {
      if (state.breakpoints['(max-width: 649.99px)']) {
        console.log("Cambio break: mobile")
        this.loaderWidth = "300px";
        this.breakpointSvc.setCurrentBreakPointDevice("mobile");
      } else if (state.breakpoints['(min-width: 650px) and (max-width: 1199.99px)']) {
        console.log("Cambio break: tablet")
        this.loaderWidth = "620px";
        this.breakpointSvc.setCurrentBreakPointDevice("tablet")
      } else if ('(min-width: 1200px)') {
        this.loaderWidth = "800px";
        console.log("Cambio break: desktop")
        this.breakpointSvc.setCurrentBreakPointDevice("desktop")
      }
    });
    this.breakpointSvc.setDeviceHeight(window.innerHeight);

    this.tsvc.isInitialized.pipe(takeUntil(this.sub)).subscribe((init: boolean) => {
      this.isLanguageInit = init;
    });

    this.router.events.pipe(takeUntil(this.sub)).subscribe((e: any) => {
      this.navigationInterceptor(e);
    });


    Sentry.setTag("Customer", environment.COMPANY_NAME);
    Sentry.setTag("AngularType", "Booking");
    Sentry.setUser({
      email: this.authenticationService.currentUser?.user?.email ?? "Not logged user",
      id: this.authenticationService.currentUser?.user?.userId ?? "Not logged user",
    })

    const editMode = this.storageSvc.get<boolean>("editModeActive") ?? false;
    this.editSvc.editModeOn.next(editMode)

    this.throttleLogout.pipe(takeUntil(this.sub), throttleTime(5000)).subscribe(async url => {
      this.modalSvc.closeAll();
      this.authService.logout();
      await this.router.navigate([this.tsvc.currentLanguage.value, 'login']);
    });

    this.errorInterceptorSvc.errorState.pipe(takeUntil(this.sub))
    .subscribe((error: PurpleApiError | undefined) => {
      console.log("Errore: ", error)
      this.errorInfo = error;

      if (error != undefined) {
        switch (error.errorStatus) {
          case 0:
            this.modalSvc.closeAll();
            this.loaderSvc.removeAllRequest(this.loaderId);
            break;

          case 401:
            this.throttleLogout.next(1);
            break;

          default:
            break;
        }
      }else{
        this.errorInfo = undefined;
      }
    });

    //await this.tsvc.initialize();
    await this.policySvc.getPublicPolicies();
    if (this.authService.isLoggedIn) {
      await this.policySvc.checkUserAttributesAndPolicies(this.breakpointSvc.breakPointDevice$.value == 'mobile')
    }
  }

  navigationInterceptor(event: RouterEvent): void {
    this.layoutSvc.isUserMenuOpen = false;

    if (event instanceof NavigationStart) {
      this.loaderSvc.addRequest(this.loaderId);
      this.errorSvc.removeAllRequest("internal-error");
    }
    if (event instanceof NavigationEnd) {
      this.setAppHeight();
      this.loaderSvc.removeRequest(this.loaderId, environment.LOADER_DELAY);
    }

    if (event instanceof NavigationCancel) {
      this.loaderSvc.removeRequest(this.loaderId, environment.LOADER_DELAY);
    }
    if (event instanceof NavigationError) {
      this.loaderSvc.removeRequest(this.loaderId, environment.LOADER_DELAY);
    }
  }

  setAppHeight() {
    console.log("SetAppHeight")

    const doc = document.documentElement;
    doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    doc.style.setProperty('--location-card-height', `${window.innerHeight/2}px`)
    this.breakpointSvc.setDeviceHeight(window.innerHeight);
  }

  onResize(){
    this.setAppHeight();
  }

  ngOnDestroy(): void {
    this.sub.next();
    this.sub.complete();
  }
}
