import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/core-modules/authentication/authentication.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { VerifyAccountModalComponent } from '../../modals/verify-modals/verify-account-modal/verify-account-modal.component';
import { PurpleApiResponseStatus, PurpleTranslationPipe } from 'purple-lib';
import { AccountNeedVerificationService } from './account-need-verification.service';
import { lastValueFrom } from 'rxjs';
import { UsersService } from 'src/core-modules/sdk/api';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { PurpleSalesNotificationService } from 'src/app/components/purple-sales-notification.service';

@Component({
  selector: 'app-account-need-verification-banner',
  templateUrl: './account-need-verification-banner.component.html',
  styleUrls: ['./account-need-verification-banner.component.scss']
})
export class AccountNeedVerificationBannerComponent implements OnInit {

  constructor(public authSvc: AuthenticationService, private modal: NzModalService, private tranPipe: PurpleTranslationPipe, public bannerSvc: AccountNeedVerificationService, 
    private userSvc: UsersService, private tsvc: AppTranslationService, private psNotSvc: PurpleSalesNotificationService) { }

  async ngOnInit() {
    await this.authSvc.checkVerifyAccount();
  }

 
  async openVerify(){
    await this.authSvc.openVerifyAccountModal(true);    
  }

}
