<div class="auth-external-container ps-d-flex ps-flex-wrap">

  <div class="auth-form-mobile-banner ps-d-lg-none ps-d-flex ps-align-items-center" contentId="{{seId}}-authentication"
    contentTypeId="authentication-image-mobile" purpleEditableImage>
  </div>

  <div
    class="auth-form-left-container ps-d-flex ps-w-100 ps-flex-wrap ps-col-12 ps-col-lg-6 ps-align-items-center ps-justify-content-end">
    <div class="ps-d-flex ps-w-100 ps-flex-wrap">

      <app-purple-sales-page-title *ngIf="pageTitle.title!='#NS#'"
        [pageTitle]="pageTitle"></app-purple-sales-page-title>

      <div class="ps-col-12 ps-px-0 ps-d-flex ps-flex-wrap ps-align-items-start">
        <app-purple-form [disbledForm]="isLoading" class="ps-w-100" *ngIf="purpleFormFieldGroups!= undefined"
          [(formGroup)]="validateForm" [purpleFormFieldGroups]="purpleFormFieldGroups" (purpleFormSubmit)="register()">
        </app-purple-form>

        <div class="register-form-policies-container">
          <form [formGroup]="validateForm">
            <ng-container *ngFor="let policy of policies">
              <container-element [ngSwitch]="policy.policyTypeId">

                <ng-container *ngSwitchCase="'defualt'">
                  <nz-form-item class="register-area">
                    <nz-form-control
                      [nzErrorTip]="'form_policy_accept_required' | translation: 'Accettazione obbligatoria!': []">
                      <label nz-checkbox [formControlName]="policy.policyId">
                      </label>
                      <p class="ps-mm-0 ps-pb-0 ps-pl-3">
                        {{policy.policyTitle}}
                      </p>
                      <span *ngIf="policy.isMandatoryPolicy">
                        <sup>*</sup>
                      </span>
                    </nz-form-control>
                  </nz-form-item>
                </ng-container>


                <ng-container *ngSwitchCase="'iubenda'">
                  <nz-form-item class="register-area">
                    <nz-form-control
                      [nzErrorTip]="'form_policy_accept_required' | translation: 'Accettazione obbligatoria!': []">
                      <div class="form-policy-item-container">
                        <label nz-checkbox [formControlName]="policy.policyId">
                        </label>
                        <span purpleEditableText class="ps-mb-0 ps-pb-0 ps-pl-3">
                          {{ 'accept' | translation: 'Accetto l\'': []}}<a [href]='policy.policyUrl'
                            class='iubenda iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe'>{{policy.policyTitle}}</a>
                        </span>
                        <span *ngIf="policy.isMandatoryPolicy">
                          <sup>*</sup>
                        </span>
                      </div>


                    </nz-form-control>
                  </nz-form-item>
                </ng-container>

                <ng-container *ngSwitchCase="'text'">
                  <nz-form-item class="register-area">
                    <nz-form-control
                      [nzErrorTip]="'form_policy_accept_required' | translation: 'Accettazione obbligatoria!': []">
                      <div class="form-policy-item-container">
                        <label nz-checkbox [formControlName]="policy.policyId">
                        </label>
                        <p class="ps-mb-0 ps-pb-0 ps-pl-3">
                          {{policy.policyText}}
                          <span *ngIf="policy.isMandatoryPolicy">
                            <sup>*</sup>
                          </span>
                        </p>

                      </div>
                    </nz-form-control>
                  </nz-form-item>
                </ng-container>

                <ng-container *ngSwitchCase="'media'">
                  <nz-form-item class="register-area">
                    <nz-form-control
                      [nzErrorTip]="'form_policy_accept_required' | translation: 'Accettazione obbligatoria!': []">
                      <div class="form-policy-item-container">
                        <label nz-checkbox [formControlName]="policy.policyId">
                        </label>
                        <span purpleEditableText class="ps-mb-0 ps-pb-0 ps-pl-3">
                          {{ 'accept' | translation: 'Accetto l\'': []}}<a [href]='policySvc.getPolicyImageUrl(policy)'
                            class='iubenda iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe'>{{policy.policyTitle}}</a>
                        </span>
                        <span *ngIf="policy.isMandatoryPolicy">
                          <sup>*</sup>
                        </span>
                      </div>
                    </nz-form-control>
                  </nz-form-item>
                </ng-container>
                <ng-container *ngSwitchCase="'link'">
                  <nz-form-item class="register-area">
                    <nz-form-control
                      [nzErrorTip]="'form_policy_accept_required' | translation: 'Accettazione obbligatoria!': []">
                      <div class="form-policy-item-container">
                        <label nz-checkbox [formControlName]="policy.policyId">
                        </label>
                        <span purpleEditableText class="ps-mb-0 ps-pb-0 ps-pl-3">
                          {{ 'accept' | translation: 'Accetto l\'': []}}<a [href]='policy.policyUrl??"#"'
                            class='iubenda iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe'>{{policy.policyTitle}}</a>
                        </span>
                        <span *ngIf="policy.isMandatoryPolicy">
                          <sup>*</sup>
                        </span>
                      </div>
                    </nz-form-control>
                  </nz-form-item>
                </ng-container>
                <ng-container *ngSwitchCase="'html'">
                  <nz-form-item class="register-area">
                    <nz-form-control
                      [nzErrorTip]="'form_policy_accept_required' | translation: 'Accettazione obbligatoria!': []">
                      <div class="form-policy-item-container">
                        <label nz-checkbox [formControlName]="policy.policyId">
                        </label>
                        <span class="ps-mb-0 ps-pb-0 ps-pl-3" [innerHtml]="policy.policyHtml??'' | safeHtml">
                        </span>
                        <span *ngIf="policy.isMandatoryPolicy">
                          <sup>*</sup>
                        </span>
                      </div>
                    </nz-form-control>
                  </nz-form-item>
                </ng-container>

              </container-element>
            </ng-container>
          </form>
        </div>

        <app-purple-sales-button (click)="register()" class="auth-main-button" purpleEditableText
          tranKey='register_register_button' [buttonText]="'register_register_button' | translation: 'Registrati'"
          [buttonType]="'primary'"></app-purple-sales-button>

        <div class="ps-mt-3 ps-w-100 ps-d-flex ps-align-items-center ps-mb-4">
          <p purpleEditableText tranKey='register_have_you_an_account' class="ps-mb-0 ps-pr-2 fsp-16-0">{{
            'register_have_you_an_account' | translation: 'Hai già un account?': []}}
          </p>

          <app-purple-sales-button class="auth-secondary-button"
            [routerLink]="['/',tsvc.currentLanguage.value, 'login']" purpleEditableText tranKey='register_login'
            [buttonText]="'register_login' | translation: 'Accedi'"></app-purple-sales-button>

        </div>
      </div>
    </div>
  </div>

  <div class="auth-form-right-container ps-col-6 ps-d-none ps-d-lg-flex 
    ps-justify-content-center ps-align-items-end ps-justify-content-start" contentId="{{seId}}-authentication"
    contentTypeId="authentication-image" purpleEditableImage>
  </div>

</div>