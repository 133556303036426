<div class="auth-external-container ps-w-100 ps-d-flex ps-flex-wrap">

  <div class="auth-form-mobile-banner ps-d-lg-none ps-d-flex ps-align-items-center" contentId="{{seId}}-authentication"
    contentTypeId="authentication-image-mobile" purpleEditableImage>
  </div>

  <div
    class="auth-form-left-container ps-d-flex ps-w-100 ps-flex-wrap ps-col-12 ps-col-lg-6 ps-align-items-center ps-justify-content-end">
    <div class="ps-d-flex ps-w-100 ps-flex-wrap">
      <app-purple-sales-page-title *ngIf="pageTitle.title!='#NS#'"
        [pageTitle]="pageTitle"></app-purple-sales-page-title>

      <div class="ps-col-12 ps-px-0 ps-d-flex ps-flex-wrap ps-align-items-start">
        <app-purple-form [disbledForm]="isLoading" class="ps-w-100" *ngIf="purpleFormFieldGroups!= undefined"
          [(formGroup)]="validateForm" [purpleFormFieldGroups]="purpleFormFieldGroups" (purpleFormSubmit)="login()">
        </app-purple-form>
        <div class="ps-d-flex ps-flex-wrap ps-w-100 ps-align-items-center">
          <div class="ps-col-12 ps-col-lg-6 ps-px-0">
            <label nz-checkbox [ngModel]="rememberCheck">
              <span purpleEditableText tranKey='login_remember'>{{ 'login_remember' | translation: 'Remember me':
                []}}</span>
            </label>
          </div>
          <div class="ps-col-12 ps-col-lg-6 ps-d-flex ps-justify-content-start ps-justify-content-lg-end ps-px-0">
            <a purpleEditableText tranKey='login_forgot_password'
              [routerLink]="['/', tsvc.currentLanguage.value, 'forgot-password']"
              class="login-form-forgot ps-mt-lg-0 ps-mt-3">{{
              'login_forgot_password' | translation: 'Hai dimenticato la password?': []}}</a>
          </div>
        </div>
        <app-purple-sales-button class="auth-main-button" purpleEditableText tranKey='login_login'
          [buttonText]="'login_login' | translation: 'Accedi'" [buttonType]="'primary'"
          (click)="login()"></app-purple-sales-button>


        <div class="ps-mt-3 ps-w-100 ps-d-flex ps-align-items-center ps-mb-4">
          <p purpleEditableText tranKey='login_have_an_account' class="ps-mb-0 ps-pr-2 fsp-16-0">{{
            'login_have_an_account' | translation: 'Non hai un account?':
            []}}</p>

          <app-purple-sales-button class="auth-secondary-button"
            [routerLink]="['/',tsvc.currentLanguage.value, 'register']" purpleEditableText
            tranKey='login_register_button'
            [buttonText]="'login_register_button' | translation: 'Registrati'"></app-purple-sales-button>

        </div>
      </div>
    </div>
  </div>

  <div class="auth-form-right-container ps-col-6 ps-d-none ps-d-lg-flex 
      ps-justify-content-center ps-align-items-end ps-justify-content-start" contentId="{{seId}}-authentication"
    contentTypeId="authentication-image" purpleEditableImage>
  </div>

</div>