import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { LayoutService } from 'src/app/layout/layout.service';

@Injectable({
  providedIn: 'root'
})
export class SetMenuGuard {
  constructor(private layoutSvc: LayoutService) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      var menu: string = route.data['menu'];

      this.layoutSvc.setCurrentActiveMenuItem(menu);
      
      return true;
  }
}
