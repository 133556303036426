<div class="external-membership-detail-container">
  <div class="ps-w-100 ps-d-flex ps-flex-wrap">

    <app-purple-sales-page-title [pageTitle]="pageTitle"></app-purple-sales-page-title>
    <div class="membership-detail-container" *ngIf="membership">
      <div class="ps-col-12 ps-col-md-6 ps-px-0 ps-d-flex ps-flex-wrap ps-justify-content-center ps-justify-content-md-start">
        
        <div class="ps-d-flex ps-flex-wrap ps-justify-content-end membership-detail-left-container">
          <div [style.background]="membership.hexColor" class="membership-detail-box">
            <h2 purpleEditableText tranKey='membership_detail_box_title' 
            class="membership-detail-box-title {{layoutSvc.lightOrDark(membership.hexColor)}}">
              {{ 'membership_detail_box_title' | translation: '{0} member': [membership.membershipName??""] | titlecase}}
            </h2>
            <p purpleEditableText [tranKey]="membership.membershipName + '_membership_detail_box_subtitle'" 
            class="membership-detail-box-subtitle {{layoutSvc.lightOrDark(membership.hexColor)}}">
              {{ membership.membershipName + '_membership_detail_box_subtitle'
              | translation: 'ABBONAMENTO VALIDO PER 6 MESI O 1 ANNO': [] | uppercase}}
            </p>
            <div class="ps-w-100 ps-d-flex ps-flex-wrap">
              <div class="ps-col-5 ps-col-lg-4 ps-d-flex ps-align-items-end">
                <p class="membership-detail-box-price {{layoutSvc.lightOrDark(membership.hexColor)}}">
                  {{getPricesText(membership)}}
                </p>
              </div>
              <div
                class="membership-detail-box-image-container ps-col-7 ps-col-lg-8 ps-d-flex ps-align-items-end ps-justify-content-end">
                <img [contentId]="getContentId()" contentTypeId="membership-detail-image" purpleEditableImage
                  class="membership-detail-box-image" />
              </div>
            </div>
          </div>
          <div class="membership-detail-prices-container ps-d-none ps-d-md-flex">
            <app-purple-sales-button *ngFor="let variant of (membership.membershipVariants??[])"
              class="membership-detail-prices-container-new {{((variant.variantPrice??0)==0)?'disabled':''}}"
              purpleEditableText [tranKey]='"membership_detail_expire_label_new_" + membership.membershipName + "_" + variant.variantName'
              [buttonText]="'membership_detail_expire_label_new_' + membership.membershipName + '_' + variant.variantName | translation: 'Abbonamento mensile'"
              (click)="goToPayment(variant.variantId, (variant.variantPrice??0))">
            </app-purple-sales-button>
          </div>
        </div>
        

      </div>
      <div class="ps-col-12 ps-col-md-6 membership-detail-info-container">
        <div class="ps-w-100 ps-d-flex ps-flex-wrap">
          <p class="membership-detail-title">
            {{ 'membership_detail_what_includes' | translation: 'Cosa include?': []}}
          </p>
          @for (info of membership.highlightedFeatures; track $index) {
            <div *ngIf="info.isVisible" class="membership-item {{info.isActive?'':'disabled'}}">
              <span class="membership-item-dot" nz-icon [nzType]="info.isActive?'check':'close'" nzTheme="outline"></span>
              <p purpleEditableText [tranKey]='info.featureKey?.replace("booking_","")' class="membership-item-text">
                {{info.featureValue}}
              </p>
            </div>
          }          
        </div>
      </div>
      <div class="membership-detail-prices-container ps-d-flex ps-d-md-none">
        <app-purple-sales-button *ngFor="let variant of (membership.membershipVariants??[])"
          class="membership-detail-prices-container-new {{((variant.variantPrice??0)==0)?'disabled':''}}"
          purpleEditableText [tranKey]='"membership_detail_expire_label_new_" + variant.variantName'
          [buttonText]="'membership_detail_expire_label_new_' + variant.variantName | translation: 'Abbonamento mensile'"
          (click)="goToPayment(variant.variantId, (variant.variantPrice??0))">
        </app-purple-sales-button>
      </div>
      
      <div class="membership-arrow-container">
        <app-purple-sales-button class="membership-back-arrow"
          purpleEditableText icon="arrow-left" (click)="navigateToMember()">
        </app-purple-sales-button>
      </div>
    </div>
  </div>
</div>