<div class="ps-w-100 ps-d-flex ps-flex-wrap check-user-attributes-modal-external-container">
  <div class="ps-w-100 ps-d-flex check-user-attributes-modal-internal-container">
    <div *ngFor="let att of userAttributes" class="check-user-attributes-modal-item-container ps-d-flex 
    ps-flex-wrap ps-w-100">
      <h2 class="attribute-title ps-w-100">
        {{att.userAttributeName}}
      </h2>
      <container-element [ngSwitch]="att.angularTemplateType">
        <ng-container *ngSwitchCase="'select-multiple'">
          <div class="choises-container ps-w-100 ps-d-flex ps-flex-wrap">
            <div *ngFor="let defVal of att.defaultValues"
              class="choises-item ps-w-100 ps-d-flex ps-align-items-center ps-justify-content-between">
              <p class="choise-text-value">
                {{defVal.userAttributeDefaultValueText}}
              </p>
              <container-element
                [ngSwitch]="selectedPreference(att.userAttributeId, defVal.userAttributeDefaultValueId)">
                <ng-container *ngSwitchCase="false">
                  <button nz-button class="choise-button"
                    (click)="addPreference(att.userAttributeId, defVal)">
                    <i nz-icon nzType="plus" nzTheme="outline"></i>
                    {{ 'add_user_attribute_prefernce' | translation: 'Seleziona': []}}
                  </button>
                </ng-container>
                <ng-container *ngSwitchCase="true">
                  <button nz-button class="choise-button" nzType="primary"
                    (click)="removePreference(att.userAttributeId, defVal.userAttributeDefaultValueId)">
                    <i nz-icon nzType="minus" nzTheme="outline"></i>
                    {{ 'add_user_attribute_prefernce_added' | translation: 'Rimuovi': []}}
                  </button>
                </ng-container>
              </container-element>
            </div>
          </div>
        </ng-container>
      </container-element>
    </div>
  </div>
</div>
<div class="ps-w-100 ps-d-flex save-button-container ps-justify-content-center">
  <button nz-button class="save-button" [disabled]="preferences.size <= 0"
    (click)="savePreference()" nzType="primary">
    {{ 'save_preferences' | translation: 'Salva': []}}
  </button>
</div>