<div
  class="my-reservations-v2-main-container ps-d-flex ps-flex-wrap ps-w-100 ps-justify-content-center ps-align-items-start">
  <app-purple-sales-page-title [pageTitle]="pageTitle"></app-purple-sales-page-title>

  <div class="my-reservations-tab-container ps-w-100 ps-d-flex ps-flex-wrap">
    <div class="my-reservations-tab ps-w-100 ps-flex-wrap">
      <ng-container *ngIf="nextBookings.length > 0; else noNextBooking">
        <div class="ps-w-100 ps-d-flex user-bookings-container">
          <div *ngFor="let book of nextBookings" class="user-booking-item">
            <div class="ps-col-md-4 ps-col-12 user-booking-item-section">
              <div class="ps-w-100 ps-d-flex ps-flex-wrap">
                <p class="user-booking-item-section-title">
                  {{book.saleEventName}}
                </p>
                <p class="user-booking-item-section-subtitle">
                  {{book.address}}
                </p>
              </div>

            </div>
            <div class="ps-col-md-4 ps-col-12 user-booking-item-section">
              <div class="ps-w-100 ps-d-flex ps-flex-wrap">
                <p class="user-booking-item-section-title">
                  {{book.slot.slotStartDate | purpleDate: "EEEE dd MMMM" | titlecase}}
                </p>
                <p class="user-booking-item-section-subtitle">
                  {{book.slot.slotStartDate | purpleDate: "HH:mm"}} / {{book.slot.slotEndDate | purpleDate: "HH:mm"}}
                </p>
              </div>

            </div>
            <div class="ps-col-md-4 ps-col-12 user-booking-item-section no-border ps-d-flex ps-justify-content-center">
              <div class="my-reservations-tab-action-container" *ngIf="showDeleteButton(book)">
                  <app-purple-sales-button class="action-button ps-ml-0" 
                    purpleEditableText
                    tranKey='private_my_reservation_edit_button'
                    [showOnlyIcon]="showOnlyIcon" [icon]="showOnlyIcon?'edit':undefined"
                    [buttonText]="'private_my_reservation_edit_button' | translation: 'Modifica': []"
                    [routerLink]="['/', tsvc.currentLanguage.value, 'event', 'detail', book.saleEventCode]">
                  </app-purple-sales-button>

                  <app-purple-sales-button class="action-button ps-mr-0" purpleEditableText
                    tranKey='private_my_reservation_unbook_button' *ngIf="book.userCanDelete"
                    [showOnlyIcon]="showOnlyIcon" [icon]="showOnlyIcon?'delete':undefined"
                    [buttonText]="'private_my_reservation_unbook_button' | translation: 'Cancella': []"
                    (click)="unbook(book, false)">
                  </app-purple-sales-button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>




</div>


<ng-template #noNextBooking>
  <div *ngIf="(breakSvc.breakPointDevice$ | async) != 'mobile'"
    class="ps-w-100 ps-d-flex ps-justify-content-center no-booking-box-container">
    <div class="ps-d-flex ps-flex-wrap ps-justify-content-center no-booking-box">
      <div class="ps-w-100 ps-d-flex ps-justify-content-center animation-container">
        <ng-lottie [options]="options" width="300px" height="auto"></ng-lottie>
      </div>
      <div class="ps-w-100 ps-d-flex ps-flex-wrap ps-mb-2">
        <h1 purpleEditableText tranKey='my_reservations_no_next_book_title' class="error-title ps-w-100">{{
          'my_reservations_no_next_book_title' | translation: 'Nessuna prenotazione trovata!': []}}</h1>
        <div purpleEditableText tranKey='my_reservations_no_next_book_subtile_link' class="error-back-home ps-w-100">{{
          'my_reservations_no_next_book_subtile_link' | translation: 'Vieni a':
          []}} <a purpleEditableText tranKey='my_reservations_no_next_book_discover_now'
            [routerLink]="['/', tsvc.currentLanguage.value, 'login']">{{ 'my_reservations_no_next_book_discover_now' |
            translation: 'scoprirle ora':
            []}}</a></div>
      </div>
    </div>
  </div>


  <div *ngIf="(breakSvc.breakPointDevice$ | async) == 'mobile'"
    class="ps-w-100 ps-d-flex ps-justify-content-center no-booking-box-container">
    <div class="ps-d-flex ps-flex-wrap ps-justify-content-center no-booking-box">
      <div class="ps-w-100 ps-d-flex ps-justify-content-center animation-container">
        <ng-lottie [options]="options" width="210px" height="auto"></ng-lottie>
      </div>
      <div class="ps-w-100 ps-d-flex ps-flex-wrap ps-mb-2">
        <h1 purpleEditableText tranKey='my_reservations_no_next_book_title' class="error-title ps-w-100">{{
          'my_reservations_no_next_book_title' | translation: 'Nessuna prenotazione trovata!': []}}</h1>
        <div purpleEditableText tranKey='my_reservations_no_next_book_subtile_link' class="error-back-home ps-w-100">{{
          'my_reservations_no_next_book_subtile_link' | translation: 'Vieni a':
          []}}<a purpleEditableText tranKey='my_reservations_no_next_book_discover_now'
            [routerLink]="['/', tsvc.currentLanguage.value, 'login']">{{ 'my_reservations_no_next_book_discover_now' |
            translation: 'scoprirle ora':
            []}}</a></div>
      </div>
    </div>
  </div>
</ng-template>