import { Injectable } from '@angular/core';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzModalService } from 'ng-zorro-antd/modal';
import { BehaviorSubject, Subscription, lastValueFrom } from 'rxjs';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BookingService, PagePolicies, PolicyItem, PRPVPolicyFull, PurpleApiResponseStatus, UserAttributeValuesItem } from 'src/core-modules/sdk/api';
import { CheckUserAttributesDrawerComponent } from './private/drawers/check-user-attributes-drawer/check-user-attributes-drawer.component';
import { EditUserAttributesModalComponent } from './private/modals/edit-user-attributes-modal/edit-user-attributes-modal.component';
import { PurpleLoaderService, PurpleTranslationPipe } from 'purple-lib';

@Injectable({
  providedIn: 'root'
})
export class PolicyAndUserAttributesService {
  constructor(private bookSvc: BookingService, private tsvc: AppTranslationService, private loaderSvc: PurpleLoaderService, private bookingSvc: BookingService, 
    private tranPipe: PurpleTranslationPipe, private modal: NzModalService, private drawerSvc: NzDrawerService) { }
  publicPolicies: BehaviorSubject<PagePolicies[]> = new BehaviorSubject<PagePolicies[]>([]); 

  subs: Subscription = new Subscription();
  basePath: string = localStorage.getItem("BasePath")!;

  async getPublicPolicies(){
    this.loaderSvc.addRequest("general")
    var res = await lastValueFrom(this.bookSvc.getPublicPolicies(this.tsvc.currentLanguage.value))
    if(res.status == PurpleApiResponseStatus.Success){
      this.publicPolicies.next(res.data??[]);
    }
    this.loaderSvc.removeRequest("general")
  }

  async checkUserAttributesAndPolicies(isMobile: boolean) {
    var res = await lastValueFrom(this.bookingSvc.checkUserAttributesAndPolicies(this.tsvc.currentLanguage.value));

    if(res.status == PurpleApiResponseStatus.Success && res.data != undefined && res.data.inError){
      if(isMobile){
        if(res.data.isUserAttributeInError){
          
          this.drawerSvc.create<CheckUserAttributesDrawerComponent, { value: string }, string>({
            nzTitle: this.tranPipe.transform("drawer_check_user_attribute_policy_error_title", "Esprimi le tue preferenze", []),
            nzContent: CheckUserAttributesDrawerComponent,
            nzClosable: true,
            nzWrapClassName: "check-user-attributes",
            nzFooter: undefined,
            nzPlacement: 'bottom',
            nzContentParams:{
              userAttributes: res.data!.userAttribuitesInError
            }
          }).afterClose.subscribe(() => {
            if(res.data!.isPolicyInError){              
              this.showPolicyModal(res.data!.policiesInError);
            }
          })
        }else{
          if(res.data!.isPolicyInError){              
            this.showPolicyModal(res.data!.policiesInError);
          }
        }
      }else{
        if(res.data.isUserAttributeInError){
          this.modal.create<EditUserAttributesModalComponent, UserAttributeValuesItem[] >({
            nzTitle: this.tranPipe.transform("modal_check_user_attribute_policy_error_title", "Esprimi le tue preferenze", []),
            nzContent: EditUserAttributesModalComponent,
            nzData: res.data!.userAttribuitesInError,
            nzWidth: '800px',
            nzClassName: 'ps-modal',
            nzFooter: null,
            nzCentered: true,
            nzMaskClosable: false
          }).afterClose.subscribe(s=>{
            if(res.data!.isPolicyInError){              
              this.showPolicyModal(res.data!.policiesInError);
            }
          });
        }else{
          if(res.data!.isPolicyInError){              
            this.showPolicyModal(res.data!.policiesInError);
          }
        }        
      }
    }
  }

  async showPolicyModal(policies: PRPVPolicyFull[]){
    //TODO METTO MODALE PER GESTIRE POLICY
    await lastValueFrom(this.bookingSvc.acceptRequiredUserPolicy(this.tsvc.currentLanguage.value, {
      policiesInError: policies
    }));
  }

  getPolicyImageUrl(policy: PRPVPolicyFull | PolicyItem){
    return `${this.basePath}/${this.tsvc.currentLanguage.value}/medias/getmedia?ContentId=${policy.policyContentId}&ContentTypeId=${policy.policyContentTypeId}`
  }
}
