import { Component, Input, OnInit } from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';

@Component({
  selector: 'app-purple-sales-simple-drawer',
  templateUrl: './purple-sales-simple-drawer.component.html',
  styleUrls: ['./purple-sales-simple-drawer.component.scss']
})
export class PurpleSalesSimpleDrawerComponent implements OnInit {

  constructor(private drawerRef: NzDrawerRef) { }
  @Input() message!: string;
  @Input() buttonText!: string;
  @Input() autoClose!: boolean;
  @Input() buttonFunction!: () => Promise<any> | undefined;


  toExecFunction!: () => any;

  ngOnInit() {


    if (this.buttonFunction == undefined) {
      this.toExecFunction = () => {
        this.drawerRef.close()
      }
    } else {

      this.toExecFunction = async () =>{
        await this.buttonFunction()

        if (this.autoClose ?? true) {
          this.drawerRef.close()
        }
      }
      
    }
  }
}
